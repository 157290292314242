import React, { useState } from "react";
import { Link ,useNavigate } from "react-router-dom";
import Socialmedia from "./Socialmedia";
import Cookies from "js-cookie";
import API from "../APIURL";
import "./Style.css";
import Loading from "../Loading/Loading";
import LOGO from "../../Assets/LOGO.png";
import Header from "./Header";
import Footer from ".././Footer/Footer";
const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [username,setUsername] = useState('');
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error,setError] = useState("");
  const [passerr,setPasserr] = useState("");
  const [conpasserr,setConpasserr] = useState("");
  const [loading ,setLoading] = useState(false);
  

  let navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let data = {
              Email:email,
              Password:password,
              Username :username 
              }
   let options = {
                method:"POST",
                headers:{"Content-Type":"application/json"},
                body:JSON.stringify(data)
                 };

 if(passerr == "" && conpasserr == ""){ 
  
  let output = fetch(`${API}/api/auth/signup`,options);
      output.then(res=>res.json())
            .then(response=>{
                // console.log("reaponse",response);
                setLoading(false);
                if(response.signup){
                   navigate('/signupsuccess');
                   setError("");
                }
                else{
                  setError(response.message);
                }
                   })
    // handle signup logic here
                  }
  };

  const passwordhandler = (event)=>{
                  setPassword(event.target.value);
                  if(event.target.value.length < 8){
                    setPasserr("password must be 8 chars")
                  }
                  else{setPasserr('')}
  }

  const confrimpasswordhandler = (event)=>{
    setConfirmPassword(event.target.value);
    if(event.target.value  != password){
      setConpasserr("password not match")
    }
    else{
      setConpasserr('')
  }
}


  const socailsignuphandler =(value)=>{
        setLoading(true);
    let data = {
               Email:value.Email,
               Password:value.Password,
               Username:value.Username,
               isAdmin:value.isAdmin 
              };
    let options = {
                   method:"POST",
                   headers:{"Content-Type":"application/json"},
                   body:JSON.stringify(data)
                  }
                  let output = fetch(`${API}/api/auth/socialauth`,options);
                  output.then(res=>res.json())
                        .then(response=>{
                          // console.log(response);
                          if(response.login){
                            
                            // authenticate user and generate JWT token
                             const token = response.token;
                            const expires = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
                              // const expires = new Date(new Date().getTime() + 10 * 1000);
                            // set a cookie with the token and send response
                              Cookies.set('mycookie', token, {expires , sameSite: 'none', secure: true });
                              // const mytoken = Cookies.get('mycookie');
                              // console.log("torken",mytoken);
                              setLoading(false);
                             if(token){
                                // const responsePayload = jwt_decode(mytoken)
                                // console.log("responsePayload",responsePayload);
                                setError("");
                                setLoading(false);
                                window.location.reload();
                              }
                          }
                          else{
                              setLoading(false);
                              setError(response.message);
                          }
                        })
  }
  return (
    <>
    <Header/>
    <main style={{marginTop:"0px",paddingTop:"0px",paddingBottom:"0px"}}>
    {
      loading ? <Loading/>
       :
       <div className="signup-container">
      <form onSubmit={handleSubmit} className="signup-form">
      <p><img src={LOGO} alt="logo" style={{height:"20px"}} /></p>
        <h1>Signup</h1>
        {error === "" ? <span className="error">{error}</span> :<span className="error">{error}</span> }
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          className="input-field"
          required
        />
        <label>Username:</label>
        <input
          type="text"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          className="input-field"
          required
        />
        <label>Password:</label>
        <input
          type="password"
          value={password}
          onChange={(event) => {passwordhandler(event)}}
          className={passerr != "" ? "errorindicator" : "input-field"}
          required
        />
        <label>Confirm Password:</label>
        <input
          type="password"
          value={confirmPassword}
          onChange={(event) => {confrimpasswordhandler(event)}}
          className={conpasserr != "" ? "errorindicator" : "input-field"}
          required
        />
        <button type="submit" className="signup-btn">
          Signup
        </button>
         <div className="text-center">
            <p>OR</p>
            <div>
            <Socialmedia socialmedialoginhandler={socailsignuphandler} />
            </div>
           
            </div>
        <div className="login-link">
          Already have an account? <Link to="/login">Login here</Link>
        </div>
      </form>
    </div>
    
    }
    </main>
    <Footer/>
    </>
  );
};

export default SignupPage;

