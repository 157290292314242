import React, { useState ,Fragment} from "react";
import "./Changepassword.css";
import { Link } from "react-router-dom";
import API from "../APIURL";
import Header from "./Header";
import Footer from "../Footer/Footer";

function Changepassword() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const [isValidOtp, setIsValidOtp] = useState(false);
//   const [isPasswordchange,setIsPasswordchange] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "otp":
        setOtp(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    // TODO: Validate email address
    // If email address is valid, proceed to the next step
    let data = { Email:email } ;
    let options = {
                  method:"POST",
                  headers:{"Content-Type":"application/json"},
                  body:JSON.stringify(data)
                  } ;
    let output = fetch(`${API}/api/auth/sendOTP`,options);
        output.then(res=>res.json())
              .then(response=>{
                   if(response.verify){
                        setStep(2);
                   }
                   else{
                    //    error display part
                   }
              })   
    
  };

  const handleOtpSubmit = (event) => {
    event.preventDefault();
    // TODO: Validate OTP
    // For demo purposes, assume OTP is "123456"
    let data = { Email:email , VerificationCode :otp } ;
    let options = {
                  method:"POST",
                  headers:{"Content-Type":"application/json"},
                  body:JSON.stringify(data)
                  } ;
    let output = fetch(`${API}/api/auth/verifyOTP`,options);
        output.then(res=>res.json())
              .then(response=>{
                 if(response.verify){
                    setIsValidOtp(true);
                    setStep(3);
                 }
                 else{
                    alert("Invalid OTP");
                    setOtp("");
                 }
              }) 
    // if (otp === "123456") {
    //  
    // } else {
    //   alert("Invalid OTP");
    //   setOtp("");
    // }
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    // TODO: Validate new password and confirm password
    // If passwords match, submit the form

    let data = { Email:email , Password :password } ;
    let options = {
                  method:"POST",
                  headers:{"Content-Type":"application/json"},
                  body:JSON.stringify(data)
                  } ;
   

    if (password === confirmPassword) {
    //   alert("Password changed successfully");
      // Reset form state
      let output = fetch(`${API}/api/auth/updateNewpassword`,options);
      output.then(res=>res.json())
            .then(response=>{
                if(response.create){
                    setEmail("");
                    setOtp("");
                    setPassword("");
                    setConfirmPassword("");
                    setStep(4);
                    setIsValidOtp(false);
                }
                else{
                    alert(' Sorry , Password not created');
                }
            }) 
      
    } else {
      alert("Passwords do not match");
    }
  };

  const handleGoBack = () => {
    setStep(step - 1);
  };

  return (
    <Fragment>
      <Header/>
    <div className="App" style={{minHeight:"80vh",marginTop:"80px"}}>
      <div className="card">
        <div className="card-header" style={{fontSize:"15px",fontWeight:"bolder"}}>Step {step} </div>
        <div className="card-body">
          {step === 1 && (
            <form onSubmit={handleEmailSubmit}>
              <div className="form-group">
                <label style={{fontSize:"14px",fontWeight:"bolder"}}>Email address</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                />
              </div>
              <button type="submit" className="btn btn-dark">
                Next
              </button>
            </form>
          )}
          {step === 2 && (
            <form onSubmit={handleOtpSubmit}>
              <div className="form-group">
                <label>OTP</label>
                <input
                  type="text"
                  name="otp"
                  value={otp}
                  onChange={handleInputChange}
                  className="form-control"
                  maxLength="6"
                  required
                />
              </div>
              <button type="submit" className="btn btn-dark">
                Verify OTP
              </button>
              <button type="button" onClick={handleGoBack} className="btn btn-          secondary">
            Go back
          </button>
        </form>
      )}
      {step === 3 && (
        <form onSubmit={handleChangePassword}>
          <div className="form-group">
            <label>New password</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={handleInputChange}
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <label>Confirm password</label>
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleInputChange}
              className="form-control"
              required
            />
          </div>
          <button type="submit" className="btn btn-dark">
            Change password
          </button>
         
        </form>
      )}
      {step === 4 && (
      <div>
        <p>New password change successfully , Please continue  Login with your New Key</p>
       <div className="text-center my-3"><Link to="/login"><button  className="btn btn-dark mx-auto">Login</button></Link> </div> 
      </div>)}
    </div>
  </div>
    </div>
    <Footer/>
    </Fragment>
);
}
export default Changepassword;
