import React, { useState ,useEffect } from 'react';
// Import your CSS file for styling
import API from "../APIURL";
// import ReactLoading from "react-loading";

function ProductCard({ product }) {
  const [isFavorited, setIsFavorited] = useState(false);
  const [products,SetProducts] = useState([]);
  const [loading,setLoading] = useState(false);

  useEffect(()=>{GetTopRatedProductHandler()},[]);

  const GetTopRatedProductHandler = ()=>{
    //  console.log("products!!!")
    setLoading(true);
     let output = fetch(`${API}/api/product/get-products/homepage`,{method:"GET"})
         output.then(res=>res.json())
               .then(response=>{
                // console.log(response.data);
                if(response.data){
                 SetProducts(response.data);                
                 setLoading(false)
                }
                else{
                  // CheckCookieAndReload();
                }
                //  console.log("response",response.data);
               })
  }

  const toggleFavorite = () => {
    setIsFavorited(!isFavorited);
  };

  return (<section className="products" id="homepage-products" style={{position:"relative"}}>
  <h2 className='text-center'>Our Products</h2>
  {  loading ?
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '30vh' }}>
     {/* <ReactLoading type="spokes" color="green" height={40} width={40} /> */}
     </div>
     :
     <ul>
   {

   products.length > 0 ?products.map(items=> <li>
     <div className="product-card" style={{position:"relative"}}>
<img src={items.ProductImages[0]} alt={items.ProductName} />

<div className="product-details">
<h2>{items.ProductName}</h2>
<h2  >{ items.ProductDescription.length > 30 ? items.ProductDescription.substring(0, 30) + '...' :  items.ProductDescription }</h2>
<p className="price" >${items.ProductPrice}</p>
<div className="rating">Rating: {items.ProductRating}</div>

<div className={`favorite ${isFavorited ? 'favorited' : ''}`} onClick={toggleFavorite}  >
 { 
 isFavorited ?
  <i className={`bi bi-heart-fill`} style={{fontSize:"18px"}} />
  :
  <i className={`bi bi-heart`} style={{fontSize:"18px"}} />
 }
</div>

<button className="add-to-cart" onClick={() => alert(`Added ${product.title} to cart!`)}>
  Add to Cart
</button>
</div>
</div>

   </li>
    // <li>
    //   <Link to={`/productdetailspage/${items._id}`} style={{textDecorationLine:"none"}}>
    //   <img src={items.ProductImages[0]} alt="Product 1" />
    //   <h3>{items.ProductName}</h3>
    //   <p>{ items.ProductDescription.length > 30 ? items.ProductDescription.substring(0, 30) + '...' :  items.ProductDescription }</p>
    //   </Link>
    // </li>
    
  
   )
   :
   null
  }
  </ul>
  }
</section>
  );
}

export default ProductCard;
