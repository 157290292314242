import React ,{Fragment} from 'react';
import Header from "../Header/Header";
import Footer from "../../Components/Footer/Footer";

export default function Products() {
  return (
    <Fragment>
         <Header/>
         <main>
             <h1>Products</h1>
             <div className='row container mx-auto'>
                <div className='col-12'>
                 <div className='card'>Product</div>
                </div>

             </div>
         </main>
         <Footer/>
    </Fragment>
  )
}
