import React ,{Fragment} from 'react';
import Header from "../Header/Header";
import Footer from "../../Components/Footer/Footer";

export default function EditProduct() {
  return (
    <Fragment>
         <Header/>
         <main>
             <h1>Edit Products</h1>
         </main>
         <Footer/>
    </Fragment>
  )
}
