import React , {Fragment ,useState,useEffect} from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SubHeader from '../Aside/SubHeader';
import ProductDetailsShown from "../Landingpage/ProductDetailsShown";
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode" ;
import API from '../APIURL';
import { useParams } from 'react-router-dom';
import { CheckCookieAndReload} from "../CookieChecker";



export default function Productpage() {
     // get user data from cookies
  const token = Cookies.get('mycookie');
  let responsePayload ;
  if(token != undefined){
    responsePayload = jwt_decode(token);
  }

  const { id } = useParams();

 const [user,setUser] = useState(responsePayload ||'');
 const [viewData,setViewData] = useState('');

 useEffect(()=>{
       fetch(`${API}/api/product/GetProductFromId/${id}`,{method:"GET"})
       .then(res=>res.json())
       .then(result=>{
        if(result){
        // console.log("result",result);
        setViewData(result);
        }
        else{
          CheckCookieAndReload();
        }
    })
 },[]);
 const popuphandler = ()=>{
 }
 
    
  return (
  <Fragment>
    <Header/>
    <SubHeader/>
      <main>
        {
            viewData &&   <ProductDetailsShown isbtndisplay={false} popuphandler={popuphandler} items={viewData} currentuser={user.id}  />
        }
      </main>
    <Footer/>
  </Fragment>
  )
}
