import React from 'react';
import LOGO from "../../Assets/LOGO.png";
import {Link} from "react-router-dom";

export default function Header() {
  return (
    <header className='row header container-fluid mx-auto py-0' style={{height:"60px"}}>
      <div className='col-1 col-sm-1 col-md-12 col-lg-12 col-xl-12 text-end px-0 ' ></div>
      <div className='col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 text-start px-0 ' style={{height:"30px"}}><Link to="/"><img src={LOGO} style={{height:"30px",cursor:"pointer"}}   alt="Sairun"  /></Link></div>
      <div className='col-3 col-sm-3 col-md-2 col-lg-4 col-xl-4'></div>
      <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' style={{height:"30px"}}></div>
      <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' style={{height:"30px"}}></div>
      <div className='col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1' style={{height:"30px"}}></div>
      <div className='col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1 px-0' style={{height:"30px",width:'30px'}}></div>
      <div className='col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1'></div>
    </header>
  )
}
