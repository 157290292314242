import React ,{useEffect,useState,Fragment} from 'react';
import Header from '../Header/Header';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode" ;
import API from '../APIURL';
import ProductImages from "../../Assets/blueberry.png"
import SubHeader from '../Aside/SubHeader';
import "./MyOrders.css";
import { CheckCookieAndReload} from "../CookieChecker";
import Footer from '../Footer/Footer';
import Loading from '../Loading/Loading';
import ReactLoading from 'react-loading';
import Table from './Tabel';




export default function MyOrders() {
    // get user data from cookies
  const token = Cookies.get('mycookie');
  let responsePayload ;
  if(token != undefined){
    responsePayload = jwt_decode(token);
  }

 const [user,setUser] = useState(responsePayload ||'');
 const [myorders,setMyorders] = useState([]);
 const [cancelledOrders, setCancelledOrders] = useState([]);
  const [returnedOrders, setReturnedOrders] = useState([]);
  const [loading,setLoading] = useState(false);

  const handleCancelOrder = (orderId) => {
    // Logic to cancel the order and update the state
    setCancelledOrders([...cancelledOrders, orderId]);
  };

  const handleReturnOrder = (orderId) => {
    // Logic to initiate a return for the order and update the state
    setReturnedOrders([...returnedOrders, orderId]);
  };

 useEffect(()=>{
  GetOrdersForCurrentuser();
 },[])

 const GetOrdersForCurrentuser = ()=>{
  setLoading(true);
  fetch(`${API}/api/order/getordersbyuserid/${user.id}`,{method:"GET",credentials:"include"})
  .then(res=>res.json())
  .then(response=>{
    // console.log("response",response);
    if(response.data)
    setMyorders(response.data);
  else
   CheckCookieAndReload();
  setLoading(false);
});
 }


 


  return (
    <Fragment>
      <Header/>
      <SubHeader/>
      <main>
      <h1 className='my-3 py-3' >MyOrders</h1>
      {
       loading ? 
       <Loading/>
       :
       <Table myorders={myorders} userID={user.id} GetOrdersForCurrentuser={GetOrdersForCurrentuser} />
      
//       <div className="orders-table-container container card ">
//       <table className="orders-table" >
//         <thead>
//           <tr>
//             <th>Image</th>
//             <th>Name</th>
//             <th>Amount</th>
//             <th>Quantity</th>
//             <th>Transaction ID</th>
//             <th>Payment</th>
//             <th>Status</th>
//             <th>Action</th>
//           </tr>
//         </thead>
//         <>
//         {myorders && myorders.length > 0 ? (
//   myorders.map((order) =>(  
//     <tbody className='mx-auto my-3 tbody-3d-card'>
      
//     <tr key={order.id} style={{ fontSize: "25px", fontWeight: "bolder" }} className='mt-3' >
//              <td className="mx-auto text-danger text-start " colSpan="8">
//               <div className='row'>
//                  <div className="col-2 col-md-2 col-lg-1 col-xl-1 my-auto"><img src={order.sellerDetails.Logo} alt="photo.." style={{width:"20px",height:"20px",borderRadius:"50%"}} /></div>
//                  <div className="col-10 col-md-10 col-lg-11 col-xl-11 my-auto">{order.sellerDetails.CompanyName}</div>
//               </div>
                
//              </td>
//             </tr>
//     {
//     order.products.map((items) =>
//         <>
//           {items.productDetails ? (
//             <tr key={items.id} style={{ fontSize: "25px", fontWeight: "bolder" }}>
//               <td className="order-image">
//                 <img src={items.productDetails.ProductImages[0]} alt={items.productDetails.ProductName} />
//               </td>
//               <td>{items.productDetails.ProductName}</td>
//               <td style={{ color: "green" }}>&#8377; {items.productTotalPrice}</td>
//               <td>{items.quantity}</td>
//               <td>{order.paymentDetails.TransactionID}</td>
//               <td>
//                 {order.isPaid ? <div style={{ color: "green" }}>Paid</div> : <div style={{ color: "red" }}>Not Paid</div>}
//               </td>
//               <td>
//                 {items.Status === "Not processed" && (
//                   <div style={{ ...statusStyle, ...statusColors.notProcessed }}>Not Processed</div>
//                 )}
//                 {items.Status === "Processing" && (
//                   <div style={{ ...statusStyle, ...statusColors.processing }}>Processing</div>
//                 )}
//                 {items.Status === "Shipped" && (
//                   <div style={{ ...statusStyle, ...statusColors.shipped }}>Shipped</div>
//                 )}
//                 {items.Status === "Delivered" && (
//                   <div style={{ ...statusStyle, ...statusColors.delivered }}>Delivered</div>
//                 )}
//                 {items.Status === "Cancelled" && (
//                   <div style={{ ...statusStyle, ...statusColors.cancelled }}>Cancelled</div>
//                 )}
//                 {items.Status === "Returned" && (
//                   <div style={{ ...statusStyle, ...statusColors.returned }}>Returned</div>
//                 )}
//               </td>
//               <td>
//                 <div className="action-buttons">
//                   {items.Status === "Not processed" ||
//                   items.Status === "Processing" ||
//                   items.Status === "Shipped" ||
//                   items.Status === "Cancelled" ? (
//                     <>
//                       {items.Status === "Cancelled" ? (
//                         <button className="btn btn-close" disabled={true} style={{ cursor: "not-allowed" }}>
                          
//                         </button>
//                       ) : (
//                         <button className="btn btn-close" onClick={() => handleCancelOrder(order.id)}>
                          
//                         </button>
//                       )}
//                     </>
//                   ) : (
//                     <button className="return-button" onClick={() => handleReturnOrder(order.id)}>
//                       Return
//                     </button>
//                   )}
//                 </div>
//               </td>
//             </tr>
//           ) : (
//             ""
//             // <tr key={items.id} style={{ fontSize: "25px", fontWeight: "bolder" }}>
//             //   <td className="mx-auto text-danger text-center " colSpan="8">
//             //     Product Not Found 404
                
//             //   </td>
//             // </tr>
//           )}
//         </>
//       )
//       }
      
//       </tbody>
//   ))
//     )
//  : (
//   <tr>
//     <td colSpan="8" className="mx-auto  text-center" style={{ fontSize: "25px", fontWeight: "bolder",color:"red" }}>
//       No Orders
//     </td>
//   </tr>
// )}


//         </>
//       </table>
//       </div>
      }

      </main>      
      <Footer/>
    </Fragment>
  )
}
