import React ,{useState} from 'react';
// import "./Subheading.css";
import { Link ,NavLink } from "react-router-dom";
import close from "../../Assets/close.png";
import logout from "../../Assets/logout.png";
import home from "../../Assets/home.png";
import profileimg from "../../Assets/profileimage.jpg";
import LOGO from "../../Assets/LOGO.png";
import settings from "../../Assets/settings.png";
import Landing from "../../Assets/Landingicon.png";
import Bussiness from '../../Assets/Bussiness.png';
import MyorderImages from "../../Assets/MyOrders.png";
import ShopImage from "../../Assets/Shop.png";
import Cookies from "js-cookie";
import Orders from "../../Assets/MyOrders.png";
import Avator from "../../Assets/Avator.png";

// This file all style contain in Header.css 

export default function Aside(props) {
  const navButtons = [
    { id: 0, label: 'Home', path:"/",icon:home },
    { id: 1, label: 'Landing', path:"/landingpage" ,icon:Landing },
    { id: 2, label: 'Account', path:"/profile" ,icon:profileimg },
    { id: 3, label: 'Settings', path:"/settings" ,icon:settings },
    { id: 4, label: 'Bussiness', path:"/bussiness" ,icon:Bussiness },
    { id: 5, label: 'Logout', path:"/logout" ,icon:logout  },
    
    
  ];
 const AsideOnOff = ()=>{
  props.AsideOnOff();
 }

 const handleLogout = () => {
      // remove cookie
     Cookies.remove('mycookie');
     window.location.reload();
  };

      
  return (
    <> 
    <div className={`asideoverlay ${props.asidetoggle ? 'open' : 'close'}`} onClick={() => AsideOnOff()}></div>
    <aside className={`boxStyle ${props.asidetoggle ? 'open' : 'close'}`}  >
    <div className='text-center px-0 mb-5 ' style={{height:"30px"}}><Link to="/"><img src={LOGO} style={{height:"30px",cursor:"pointer"}}   alt="Sairun"  /></Link></div>
      <div className='my-3 '>
         <img src={close} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer',float:'right',position:"relative",top:'10px'}} onClick={()=>AsideOnOff()}   alt="close" title='close aside'/>
      </div>
    <div className="aside">
           <div className='row my-3' >
                 <div className='col-4 text-end my-auto'><Link to="/Mybusiness">{props.Logo === "Logo" ?<img src={Avator} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer',borderRadius:"50%"}}   alt="account"/> : <img src={props.Logo} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer',borderRadius:"50%"}}   alt="Business"/>}</Link></div>
                 <div className='col-8 text-start my-3' style={{cursor:"pointer"}}><NavLink activeClassName='active' className='card' to="/Mybusiness" style={{textDecoration:"none",padding:'10px'}}> My Business </NavLink> </div>
           </div> 
            <hr/>
            <div className='row my-3 '>
                 <div className='col-4 text-end my-auto '><Link to="/dashboard"><i className="fa fa-dashboard" style={{padding:"0px",cursor: 'pointer',fontSize:"25px",color:"brown",fontWeight:"bolder"}}></i> </Link></div>
                 <div className='col-8 text-start  ' style={{cursor:"pointer"}}><NavLink activeClassName='active' className="card" to="/dashboard" style={{textDecoration:"none",padding:'10px'}}> Dashboard </NavLink> </div>
           </div>           
           <div className='row my-3 '>
                 <div className='col-4 text-end my-auto '><Link to="/business/orders"><img src={Orders} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer'}}   alt="Orders"/> </Link></div>
                 <div className='col-8 text-start  ' style={{cursor:"pointer"}}><NavLink activeClassName='active' className="card" to="/business/orders" style={{textDecoration:"none",padding:'10px'}}> Orders </NavLink> </div>
           </div>          
           <div className='row my-3'>
                 <div className='col-4 text-end my-auto'><Link activeClassName='active' to="/landingpage"><img src={ShopImage} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer'}}   alt="home"/> </Link></div>
                 <div className='col-8 text-start ' style={{cursor:"pointer"}}><NavLink activeClassName='active ' className="card" to="/landingpage" style={{textDecoration:"none",padding:'10px'}}> Shop </NavLink> </div>
           </div>  
            <div className='row my-3'>
                 <div className='col-4 text-end  my-auto'><Link activeClassName='active' to="/returnordersrequest"><i className="bi bi-arrow-return-left" style={{padding:"0px",cursor: 'pointer',fontSize:"25px",color:"black",fontWeight:"bolder"}}></i> </Link></div>
                 <div className='col-8 text-start' style={{cursor:"pointer"}}><NavLink activeClassName='active' className="card" to="/returnordersrequest" style={{textDecoration:"none",padding:'10px',fontWeight:"bolder"}}>Return Orders Request  </NavLink> </div>
           </div> 
          {/* <div className='row my-3'>
                 <div className='col-4 text-end my-auto '><Link activeClassName='active' to="/myorders"><img src={MyorderImages} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer'}}   alt="home"/> </Link></div>
                 <div className='col-8 text-start ' style={{cursor:"pointer"}}><NavLink activeClassName='active' className="card" to="/myorders" style={{textDecoration:"none",padding:'10px'}}> MY Orders </NavLink> </div>
           </div>    */}
           {/* <div className='row my-3'>
                 <div className='col-4 text-end my-auto'><NavLink activeClassName='active' to="/settings"><img src={settings} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer'}}   alt="settings"/> </NavLink></div>
                 <div className='col-8 text-start' style={{cursor:"pointer"}}><NavLink activeClassName='active' className="card" to="/settings" style={{textDecoration:"none",padding:'10px'}}> Settings </NavLink> </div>
           </div>   */}
           {/* <div className='row my-3'>
                 <div className='col-4 text-end my-auto'><div ><img src={logout} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer'}}   alt="logout" onClick={()=>{handleLogout()}}/> </div></div>
                 <div className='col-8 text-start' style={{cursor:"pointer"}}><div className="card"   style={{textDecoration:"none",paddingLeft:"10px"}} onClick={()=>{handleLogout()}}> Logout </div> </div>
           </div>          */}
           
    </div>
    </aside>
     </>
  )
}