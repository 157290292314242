import React ,{useEffect, useState}  from 'react';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode" ;
import API from "../APIURL";
import Header from "../Header/Header";
import profileimage from "../../Assets/girlone.png";
import "./Profilepage.css";
import CompanyCard from './CompanyCard';
import Footer from '../Footer/Footer';
import Avator from "../../Assets/Avator.png";
import { Modal } from 'react-bootstrap';
import SubHeader from '../Aside/SubHeader';
import Loading from '../Loading/Loading';
import ModalLoading from '../Loading/ModalLoading';
import { CheckCookieAndReload } from "../CookieChecker";
import ReactLoading from "react-loading";
import  Cropper  from "react-easy-crop";
import getCroppedImg from "./getCroppedImg"; 
import Dropzone from 'react-dropzone';

// import AvatarEditor from 'react-avatar-editor';
// import { useUser } from '../Context/UserContext';


export default function Profilepage() {
  // get user data from cookies
  const token = Cookies.get('mycookie');
  let responsePayload ;
  if(token != undefined){
    responsePayload = jwt_decode(token);
  }

  // Declare State
   const [user,setUser] = useState(responsePayload ||'');
   const [currentUser , setCurrentUser] = useState('');
   const [showModal1, setShowModal1] = useState(false);
   const [showModal2, setShowModal2] = useState(false);
   const [email,setEmail] = useState('');
   const [mobileNo,setMobileNo]  = useState('');
   const [username,setUsername] = useState('');
   const [Photo,setPhoto] = useState('');
   const [selectedPhoto, setSelectedPhoto] = useState(null);
   const [loading,setLoading] = useState(false);
   const [modalloading,setModalLoading] = useState(false);
   const [error,setError] = useState('');
   const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);
  const [IsCroppingModalOpen,setIsCroppingModalOpen] = useState(false);
  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState(1);

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedPhoto(URL.createObjectURL(file));
    setIsCroppingModalOpen(true);
    setShowModal2(false);
  };



  
     
  //  Get Current User data from Backend
   useEffect(()=>{
    setLoading(true);
    let output = fetch(`${API}/api/auth/getuser/${user.id}`,{method:"GET",credentials: 'include'});
        output.then(res=>res.json())
               .then(result=>{
                //  console.log("result",result);
                if(result.data){
                 setCurrentUser(result.data);
                 setEmail(result.data.Email);
                 setUsername(result.data.Username);
                 setMobileNo(result.data.MobileNo);
                 setPhoto(result.data.Photo);  
                 setLoading(false); 
                }
                else{
                  CheckCookieAndReload();
                }              
               })
   },[])

  //  Update user Data Handler
    const userupdatehander =()=>{
      setModalLoading(true);
           let data = {
            Username: username,
            Photo:Photo,
            Email:email,
            MobileNo:mobileNo
           };
           let options = {
             method:"PUT",
             headers:{"Content-Type":"application/json"},
             body:JSON.stringify(data),
             credentials: 'include'
           };
           let output = fetch(`${API}/api/auth/updateuser/${user.id}`,options);
               output.then(res=>res.json())
                     .then(response=>{
                      // console.log("response",response);
                      if(response.update){
                       CurrentUserHandler();
                       setModalLoading(false);
                       handleModal1Close();
                       handleModal2Close();
                       setError("");
                       setIsCroppingModalOpen(false);
                      }
                      else{
                           setError(response.message);
                           setTimeout(()=>{setError('')},3000)
                           setModalLoading(false);
                      }
                    });
    }

    // Collect Current User Data
    const CurrentUserHandler = () =>{
      let output = fetch(`${API}/api/auth/getuser/${user.id}`,{method:"GET",credentials: 'include'});
                        output.then(res=>res.json())
                               .then(result=>{
                                //  console.log("result",result);
                                if(result.data)
                                 setCurrentUser(result.data);   
                                else
                                 CheckCookieAndReload();                        
                               });
    }

// User Data Close Modal
    const handleModal1Close = () => {
      setShowModal1(false);
      setError('');
      setEmail('');
      setEmail(currentUser.Email);
      setMobileNo('');
      setMobileNo(currentUser.MobileNo);   
      setIsCroppingModalOpen(false);   
    };
  
  //user Photo Edit Modal
  const handleModal2Close = () => {
    setShowModal2(false);
    setIsCroppingModalOpen(false);
    setError('');
    setPhoto('');
    setPhoto(currentUser.Photo);
  }; 

  // Photo Onchange Handler
  const handlePhotoChange = (e) => {
    setIsCroppingModalOpen(true);
    setSelectedPhoto(URL.createObjectURL(e.target.files[0]));
    setShowModal2(false);
   
  };

  const onCropComplete = async (_, croppedAreaPixels) => {
    const croppedImg = await getCroppedImg(selectedPhoto, croppedAreaPixels);
    // console.log('cropped image',croppedImg);
    setPhoto(croppedImg);
  };

  
  const handleOkClick = async () => {
    setIsCroppingModalOpen(false);
   // Reset the file input value 
    // handler(croppedImage);  
    setShowModal2(true); 
  };

  const handleCancelClick = () => {
    setIsCroppingModalOpen(false);
    // setCroppedImage(null);
    setPhoto(currentUser.Photo);
    setShowModal2(true);
    // setIsCroppingModalOpen(false); // Close the cropping modal
  };

  return (
    <>
      <Header/>
      <SubHeader/>
     
      <main>  
        {
          loading ? 
          <Loading/>
          
          :
        <>
        {
           IsCroppingModalOpen ?
        <>
         {IsCroppingModalOpen &&  (
        <div className="crop-container">
          <Cropper
            image={selectedPhoto}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            style={{ zIndex:"2" }}
           
          />
           <div className="buttons-container">
            <button onClick={handleOkClick} className="ok-button">
              OK
            </button>
            <button onClick={handleCancelClick} className="cancel-button">
              Cancel
            </button>
          </div>

        </div>
        
      )}
      </>
   
      :
      <div className='row mx-auto container-fluid my-3 ' >
      <div className="profile-card col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-3  py-5 mx-auto">
      <div className="profile-photo-container">
        {currentUser && currentUser.Photo === "Avator" ? <img className="profile-photo" src={Avator} alt="Profile Photo" /> :<img className="profile-photo" src={currentUser.Photo} alt="Profile Photo" /> }
        <i className="bi bi-pencil-square edit-icon" onClick={()=>{setShowModal2(true);setPhoto(currentUser.Photo);}} ></i>
      </div>
      
      <h3 className="profile-name">{currentUser.Username}</h3>
      <p className="profile-contact">{currentUser.Email}</p>
      <p className="profile-contact">{currentUser.MobileNo  ? currentUser.MobileNo : "Please add Mobile No"}</p>
      <i className="bi bi-pencil-square btn btn-block pt-2 mx-auto " style={{color:"white",backgroundColor:'black'}} onClick={()=>{setShowModal1(true);}}>&nbsp;Edit Data</i>
    </div>
   <CompanyCard/>

      </div>
}
      </>
}
</main>
      <Footer/>


    {/* Edit User Photo Modal */}
    <Modal show={showModal2} onHide={handleModal2Close} centered>
    <Modal.Header closeButton>
    <Modal.Title>Edit User Photo</Modal.Title>
    
    </Modal.Header>
    {modalloading ? <ModalLoading/>
     :
    <>
   <Modal.Body>
     <div className="text-center">
     <div style={{color:'red',fontWeight:"bolder"}} className='my-3'>{error}</div>
     <label htmlFor='photo' style={{cursor:"pointer"}} >
     <Dropzone onDrop={handleDrop} accept="image/*" multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            {/* <p>Drag & drop an image here or click to select one</p> */}
            <div>
        {
          Photo === 'Avator' ? <img
          src={Avator} // Replace userPhoto with the URL or source of the uploaded image
          alt="Avator"
          className="rounded-circle border border-dark"
          style={{ width: '150px', height: '150px', objectFit: 'cover' }}
        />
        :
        <img
        src={Photo} // Replace userPhoto with the URL or source of the uploaded image
        alt="User Photo"
        className="rounded-circle border border-dark"
        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
      />
        }
       
      </div>
          </div>
        )}
        
      </Dropzone>
      
      </label>
    </div>

  </Modal.Body>
  <Modal.Footer>
    <button className="btn btn-dark" onClick={()=>{userupdatehander()}}>Save</button>
  </Modal.Footer>
  </>
  }
</Modal>

    
      
      {/* Edit User Data Modal */}
      <Modal show={showModal1} onHide={handleModal1Close} centered>
  <Modal.Header closeButton>
    <Modal.Title>Edit User Data</Modal.Title>
  </Modal.Header>
  {
    modalloading ? <ModalLoading/>
    :
  <>
  <Modal.Body>
     <div style={{color:'red',fontWeight:"bolder"}}>{error}</div>
    <div className="form-group">
      <label htmlFor="userName">User Name</label>
      <input type="text" className="form-control" id="userName" value={username} onChange={(e)=>{setUsername(e.target.value)}} />
    </div>
    <div className="form-group">
      <label htmlFor="email">Email</label>
      <input
        type="email"
        className="form-control"
        id="email"
        value={email} // Set a fixed value for email
        readOnly // Make the email field non-editable
      />
    </div>
    <div className="form-group">
      <label htmlFor="mobileNo">Mobile No</label>
      <input type="text" className="form-control" id="mobileNo" value={mobileNo} onChange={(e)=>{setMobileNo(e.target.value)}} />
    </div>
  </Modal.Body>

  <Modal.Footer>
    <button className="btn btn-dark" onClick={()=>{userupdatehander()}}>Save</button>
  </Modal.Footer>
  </>
}
</Modal>

       
      </>
  )
}
