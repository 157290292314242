import React from 'react';
import ReactLoading from "react-loading";
import "./Loading.css";

export default function Loading() {
  return (
    <div className='text-center'>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '30vh' }}>
            <ReactLoading type="spokes" color="green" height={40} width={40} />
    </div>
          
         <h1>Loading...</h1>
   </div>
  )
}
