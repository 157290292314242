import React,{Fragment,useEffect,useState} from 'react';
import Modal from "react-modal";
import ReactLoading from 'react-loading';
import  {useNavigate} from "react-router-dom"
const customStyle ={
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
      zIndex: '4',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    
    content: {
      top: '40%',
      left: '0%',
      right: 'auto',
      bottom: 'auto',
      width: '100%',
      position: 'fixed',
      backgroundColor: 'white', // You can set the desired background color here
    },
  };
  
  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#root');

export default function Message() {
  let navigate =useNavigate();
  const [loading,setLoading] = useState(true);
  // const [ , setTime] = useState(4);
 

  const [secondsRemaining, setSecondsRemaining] = useState(4);

useEffect(()=>{
   setTimeout(()=>setLoading(false),2000)
},[]) 

useEffect(() => {
  if(loading === false){
      timerhandler();
  }   
}, [secondsRemaining,loading]);

const timerhandler = ()=>{
  if (secondsRemaining > 0) {
      const timer = setInterval(() => {
        setSecondsRemaining(prevSeconds => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      navigate('/myorders');
    }
}
  return (
    <Fragment>
         <div className='blur-background'></div>
          <div className=" overlay mx-auto my-auto card" style={{width:"320px",height:"35vh"}}>
          <div className='card p-3 py-5 my-auto'>
           <h3 className='text-success' style={{fontWeight:"bolder"}}>Please Wait , It take time to Place your order</h3>
           {
             loading ?
             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '10vh' }}>
             <ReactLoading type="spin" color="#000" height={40} width={40} />
             </div>
           :
           <div className='card p-3 my-3'>
           <p  style={{fontWeight:"bolder",color:"orange"}} className='my-3'>Your Order Placed Sucessfully</p>
            <div>Redirecting in {secondsRemaining} seconds... </div>
           </div>
           }
           </div>
          </div>
      
    {/* <Modal isOpen={true} style={customStyle}>
     
    
           <div className='card p-3'>
           <h3 className='text-success' style={{fontWeight:"bolder"}}>Please Wait , It take time to Place your order</h3>
           {
             loading ?
             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '10vh' }}>
             <ReactLoading type="spin" color="#000" height={40} width={40} />
             </div>
           :
           <div className='card p-3'>
           <p  style={{fontWeight:"bolder",color:"orange"}}>Your Order Placed Sucessfully</p>
            <div>Redirecting in {secondsRemaining} seconds... </div>
           </div>
           }
           </div>
         
     
     
    </Modal> */}
 </Fragment>
  )
}
