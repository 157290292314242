import React ,{Fragment,useState,useEffect} from 'react';
import OTPVerification from '../Landingpage/OTPVerified';
import Header from '../Header/Header';
import Footer from "../Footer/Footer";
import "./CheckoutPage.css";
import photo from "../../Assets/mongo.png";
import { useLocation ,useParams } from 'react-router-dom';
import API from "../APIURL";
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode" ;
import CartModal from '../Landingpage/CartModal';
import ReactLoading from 'react-loading';
import PaymentTransaction from './PaymentTransaction';
import Message from './Message';
import {  CheckCookieAndReload} from "../CookieChecker";
import Loading from '../Loading/Loading';

let array = [1,3,4,5,6];

export default function CheckoutPage({items,currentuser,isopenhandler }) {
  
  // get user data from cookies
  const token = Cookies.get('mycookie');
  let responsePayload ;
  if(token != undefined){
    responsePayload = jwt_decode(token);
  }
  // Declaring States
    const [user,setUser] = useState(responsePayload ||'');
    const [cartquantity,setCartQuantity] = useState(1);
    // const [Cartprice,setCartPrice] = useState(items.ProductPrice);
    const [Cartprice,setCartPrice] = useState(12);
    const [CurrentStep,setCurrentStep] = useState(0);
    const [name,setName] = useState('');
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [address, setAddress] = useState("");
    const [pincode,setPincode] = useState("");
    const [city,setCity] = useState('');
    const [isbuttonloading,setIsButtonLoading] = useState(false);
    const [ispaymentselect,setIsPaymentSelect] = useState('');
    const [error,setError]= useState({step1err:"",step2err:"",step3err:""});
    const [isOtpVerified , setIsOtpVerified] = useState(false);
    const [products,SetProducts] =useState([]);
    const [totalPrice,setTotalprice] = useState(0);
    const [isOpenCartModal,setISOpencartmodal] = useState(false);
    const [datum,setDatum] = useState('');
    const [loading, setLoading] = useState(true);
    const [totalQuantity,setTotalQuantity] = useState(0);
    const [ismessage,setIsMessage] = useState(false);

// Get values from React-router params
let { pathid ,productid } = useParams();

// Choose Ordernow checkout or cart checkout
useEffect(()=>{
           if(productid){
                    if(productid === "C8a2r5t6P9"){
                      GetCartProductHandler();
                    }
                    else{
                     setLoading(true);
                     setTimeout(() => {
                      GetOrdernowProductHandler();
                     }, 3000); 
                    }
          }
    },[]);

// If email change verify email set
useEffect(()=>{setIsOtpVerified(false)},[email]);  

    // Get product data for Order now Handler
const GetCartProductHandler = ()=>{
  setLoading(true);
 let output = fetch(`${API}/api/product/get-cart-data/${user.id}`,{method:"GET",credentials: 'include'});
     output.then(res=>res.json())
           .then(response=>{
            if(response.cart){
             
              SetProducts(response.cart);
              // console.log("cart response",response.cart);
              setTotalprice(response.totalPrice);
              setTotalQuantity(response.totalQuantity);                   
               
            }
            else{
              SetProducts([]);
              CheckCookieAndReload();
            }
            setLoading(false);
           })
}

// Get product data for Order now Handler
const GetOrdernowProductHandler = ()=>{
  // setIsLoading(true);
 let output = fetch(`${API}/api/product/get-product-data-ordernow/${productid}/${user.id}`,{method:"GET",credentials: 'include'});
     output.then(res=>res.json())
           .then(response=>{
            if(response.cart){
              SetProducts(response.cart);
              // console.log("cart response",response);
              setTotalprice(response.totalPrice);      
              setTotalQuantity(response.totalQuantity);               
            }
            else{
              SetProducts([]);
              CheckCookieAndReload();
            }
            setLoading(false);
             
           })
}

// Step Forward Handler
const currentStepForward = ()=>{
        if(validation()){
              setCurrentStep(CurrentStep + 1 );
            }  
        
        }
       
// validation handler , Check given steps values are collect or not
const validation = ()=>{
          if(CurrentStep === 0){
            // if(cartquantity !== 0 && Cartprice !== 0 && cartquantity <= items.ProductQuantity){
              if(products.length > 0 ){
                 setError((prevError) => ({
                   ...prevError,
                   step1err: "",
                 }));
                 return true ;
            }
            else{
             setError((prevError) => ({
               ...prevError,
               step1err: "Min select 1 quantity and max available quantity", 
             }));
             return false;
              
            }
        }
        
        if(CurrentStep === 1){
        function isValidMobileNumber(mobileNumber) {
         // Regular expression to validate a mobile number (assuming it should start with 7, 8, or 9 and have exactly 10 digits)
         const mobileNumberRegex = /^\d{10}$/;
         return mobileNumberRegex.test(mobileNumber);
        }
        
        function isValidEmail(email) {
         // Regular expression to validate an email address
         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
         return emailRegex.test(email);
        }
        
        function isValidPincode(pincode) {
         // Regular expression to validate a pin code (assuming it should be 6 digits long)
         const pincodeRegex = /^\d{6}$/;
         return pincodeRegex.test(pincode);
        }
        
        function isValidAddress(address) {
         // You can add your custom validation for the address here
         // For example, check if the address is not empty or has a minimum length, etc.
         return address.trim().length > 3;
        }
        
        function isValidCity(city) {
         // You can add your custom validation for the city here
         // For example, check if the city is not empty or has a minimum length, etc.
         return city.trim().length > 3;
        }

        function isValidName(name){
          return name.trim().length > 3;
        }
        
        const isMobileValid = isValidMobileNumber(mobile);
        const isEmailValid = isValidEmail(email);
        const isPincodeValid = isValidPincode(pincode);
        const isAddressValid = isValidAddress(address);
        const isCityValid = isValidCity(city);
        const isNameValid = isValidName(name);
        
        if(isMobileValid && isEmailValid && isPincodeValid && isAddressValid && isCityValid && isNameValid){
         setError((prevError) => ({
           ...prevError,
           step2err: "",
         }));
         return true ;
        }
        else{
         setError((prevError) => ({
           ...prevError,
           step2err: "Please fill all required fields correctly !!!",
         }));
         return false ;
        }
        
        }
        }
        
// Values change call Validation handler
useEffect(()=>{validation()},[email,pincode,mobile,address,city,cartquantity,Cartprice,name,products])
        
        // Order Now modal close handler 
        const OrderNowCloseHandler =()=>{
            isopenhandler(false);
            setCartPrice(0);
            setCartQuantity(0);
            setAddress('');
            setCity('');
            setEmail('');
            setMobile('');
            setName('');
            setError((prevError) => ({
              ...prevError,
              step1err: "",
              step2err:"",
              step3err:""
            }));
           setPincode('');
           setCurrentStep(0);
           setIsPaymentSelect('');
        }

// Set data handler for product details page
const setdatahandler =(value)=>{
          let data = {
              _id: value.productDetails._id,
              ProductPrice: value.productDetails.ProductPrice,
              ProductQuantity: value.productDetails.ProductQuantity,
              Quantity: value.quantity
          }
          // console.log(value);
          setDatum(data);
          setISOpencartmodal(true);
}  

// product list  handler
const productDisplaylist = products && products.length > 0 && products.map(items=> <div className='card'>
          <div className='row'>
             <div className='col-4 col-md-4 col-lg-3 col-xl-3'><img src={items.productDetails.ProductImages[0]} alt="photo" style={{width:'60px',height:"60px"}} /></div>
             <div className='col-8 col-md-8 col-lg-9 col-xl-9'>
               <div style={{fontSize:"14px",fontWeight:"bolder",color:'gold'}}>{items.productDetails.ProductName}</div>
               <div style={{fontSize:"12px",fontWeight:"bolder",color:'black'}}>{items.productDetails.ProductDescription.substring(0, 50)}....</div>
               <div style={{fontSize:"12px",fontWeight:"bolder",color:'green'}}>&#8377; {items.productDetails.ProductPrice}</div>
               <div style={{fontSize:"12px",fontWeight:"bolder",color:'green'}}>Available Stock : {items.productDetails.ProductQuantity} </div>
              
             </div>
          </div>
           <div className='row mx-auto'>
           <div style={{fontSize:"14px"}} className='my-5 '>
            
           <span style={{display: "inline-flex"}} className='my-auto' onClick={()=>setdatahandler(items)}>
           <button style={{padding: "5px",fontWeight:"bolder",fontSize:"10px",color:'white',backgroundColor:"black",height:"30px"}} className='btn btn-block'
           
           
           >-</button>&nbsp;
           <input type="text" style={{fontSize:"16px",fontWeight:"bolder",width:"62px",height:"30px"}} value={items.quantity} 
           /> &nbsp;&nbsp;&nbsp;&nbsp;
           <button style={{padding:"5px",fontWeight:"bolder",fontSize:"10px",color:'white',backgroundColor:"black",height:"30px"}} className='btn btn-block'
           >+</button>
           </span>
         </div>
           </div>
         </div>
  ) 
 
// Create Order Handler
const CreateOrderhandler =(Transaction_id ,PaymentMethod,isPaid)=>{

   // Prepare data for creating orders and sub-orders
const sellerSubOrders = {}; // Group sub-orders by seller

products.forEach(item => {
  const sellerId = item.productDetails.CompanyID;

  if (!sellerSubOrders[sellerId]) {
    sellerSubOrders[sellerId] = {
      sellerId,
      products: [],
      isPaid:isPaid,
      PaymentMethod:PaymentMethod,
      buyerId:user.id
    };
  }

  sellerSubOrders[sellerId].products.push({
    productId: item.productId,
    quantity: item.quantity,
    productPrice: item.productDetails.ProductPrice, 
  });
});

// Now sellerSubOrders contains sub-orders grouped by seller
const mainOrder = {
  buyerId: user.id, // Current user id
  subOrders: Object.values(sellerSubOrders) ,// Convert to array
  Quantity:totalQuantity,
  TotalPrice:totalPrice,
  TransactionID:Transaction_id,
  Name:name,
  Email:email,
  Address:address,
  PhoneNo:mobile,
  isPaid:isPaid,
  PaymentMethod:PaymentMethod  
};

// console.log(mainOrder); // This is the data to send to the backend

  let options = {
                 method:'POST',
                 headers:{"Content-Type":"application/json"},
                 body:JSON.stringify(mainOrder),
                 credentials: 'include'
                };
   let output = fetch(`${API}/api/order/createorder/${user.id}`,options);
       output.then(res=>res.json())
             .then(response=>{
                //  console.log("response",response);
              
              if(response.create){
                setIsMessage(true);
              }
              else{
                CheckCookieAndReload();
              }

            });              
}

const transactionHandler = (transactionId, paymentMethod, isPaid) => {
  // Perform actions with the transaction ID
  // This function will receive the transaction ID from PaymentTransaction component
  // and can be used to save the transaction or perform other relevant tasks
  // console.log("Received transaction ID:", transactionId);
  // console.log("Payment method:", paymentMethod);
  // console.log("Is paid:", isPaid);

  // You can also call the function that completes the order process
  // For example, you can call a function like `CreateOrderhandler`
  // to create the order using the transaction ID and other information.
  CreateOrderhandler(transactionId, paymentMethod, isPaid);
};

        
  return (
   <Fragment>
    <Header/>
         {
            isOpenCartModal ? <CartModal currentuser={user.id} items={datum} iscartmodalhandler={(data)=>{setISOpencartmodal(data);
              if(productid){
                if(productid === "C8a2r5t6P9"){
                  GetCartProductHandler();
                }
                else{
                 setLoading(true);
                 setTimeout(() => {
                  GetOrdernowProductHandler();
                 }, 3000); 
                }
      }
            }}  /> 
            :
            ""
          }
     <main style={{marginTop:"40px",height:"85.5vh"}}>
      {/* <div className='blur-background'></div> */}
     
        { ismessage && <Message /> }
          
        
      <div className="" style={{padding:"20px",top:"5%",bottom:'auto'}}>
         <h1>Checkout</h1>
        
         <hr style={{margin:"10px",color:"gray",height:"1px"}} />
         <h3 className='text-center'>Make secure payment</h3>
         {
          products && products.length >0  ?
         <>
                  
        {
          CurrentStep === 0 ?
        
           <>
          <div className=''>
             <div style={{color:"green",fontSize:"20px",fontWeight:"bolder"}} className='text-center my-3'> Your Selected Items</div>
             {/* <div className='card'>Note : Only edit the quantity or size. If you want to remove items, go to the cart and proceed. </div> */}
             <div className={`vibrate ${error.step1err ? 'error' : ''} my-3`}>{error.step1err}</div>
             <div className='cart-product-display container'>
              {
                loading ?
                <Loading/>
              :
              productDisplaylist
              }
               
             </div>

             <div className='mt-3 container px-3' ><b className='mx-5 ' style={{fontSize:"17px",fontWeight:"bolder", color:"black"}}>Total Price :</b> &nbsp;&nbsp; <span style={{fontSize:"16px",color:"green",fontWeight:"bolder"}}> &#8377; {totalPrice} </span> </div>
         </div>
         <hr style={{margin:"10px",color:"white",height:"3px"}} />
         <div className='row'>
           <div className='col-6 text-end  px-0'>
          
           </div>
           <div className='col-6 text-start px-0'>
           <button className="btn" style={{
        backgroundColor: error.step1err ? 'red' : 'gray',
        color: error.step1err ? 'white' : 'white', // You can change the text color here if needed
      }}  onClick={()=>currentStepForward()} >Next</button>
           </div>  
       </div>
       </>
          
              
          :
          ""
        }
        {
          CurrentStep === 1 ?
          <>
          <div className='p-3  container'>
           <h2 style={{color:"green"}} className="text-center">Delivery Address</h2>
           <div className={`vibrate ${error.step2err ? 'error' : ''} my-3`}>{error.step2err}</div>
           <label>Name <sup>*</sup></label>
            <input type="text" value={name} onChange={e=>{setName(e.target.value);}} />

           <label>Email <sup>*</sup></label>
            <input type="text" value={email} onChange={e=>{setEmail(e.target.value);}} />
          
           <label>Mobile Number <sup>*</sup></label>
            <input type="text" value={mobile} onChange={e=>{setMobile(e.target.value);}} />
           
            <label>Address <sup>*</sup></label>
            <input type='text' value={address} onChange={e=>{setAddress(e.target.value);}} />

            <label>Pincode <sup>*</sup></label>
            <input type='text' value={pincode} onChange={e=>{setPincode(e.target.value);}} />

            <label>City <sup>*</sup></label>
            <input type='text' value={city} onChange={e=>{setCity(e.target.value);}} />
            
           
          </div>
          <hr style={{margin:"10px",color:"white",height:"3px"}} />
         <div className='row mx-3'>
           <div className='col-6 text-end  px-0'>
           <button className='btn' style={{backgroundColor:'black',color:"white"}} onClick={()=>{setCurrentStep(CurrentStep - 1)}}>Back</button>
           </div>
           <div className='col-6 text-start px-0'>
           <button className='btn' style={{ backgroundColor: error.step2err ? 'red' : 'gray', color: error.step2err ? 'white' : 'white', }} onClick={()=>currentStepForward()} >Next</button>
           </div>  
       </div>
          </>
          :
          ""
        }
        {
          CurrentStep === 2 ?
          <>
          <div style={{ }}>
          <div className='container'  >
           <h2 style={{color:"green"}} className='text-center'>Preview Details</h2>
           <div className={`vibrate ${error.step3err ? 'error' : ''} my-3`}>{error.step3err}</div>
           <div className='cart-product-display container'>
             {/* <div style={{fontSize:"16px"}} className='my-5 '>Quantity : 200 </div> */}
             {
                loading ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ReactLoading type="spin" color="#000" height={40} width={40} />
                </div>
              :
              productDisplaylist
              }
            
            
            
             </div>
             <div className='mt-3 container' ><b style={{fontSize:"17px",fontWeight:"bolder", color:"black"}}>Total Price :</b> &nbsp;&nbsp; <span style={{fontSize:"20px",color:"green",fontWeight:"bolder"}}> &#8377; {totalPrice} </span> </div>
            <hr style={{color:"white",height:"1px"}} className='my-3' />
            <div>
             <h3 className='my-3 '>Billing Address</h3>
              <div className='my-3 card' style={{fontSize:"14px"}}><span style={{fontSize:"14px",backgroundColor:'white',color:"black",borderRadius:"7px"}} className='px-3'> {address} ,{city} , {pincode} </span> </div>
            </div>
          </div>
          <div className='container'>
          <hr style={{color:"white",height:"1px"}} className='my-3' />
          <OTPVerification emailpara={email} otpverified={isOtpVerified} isOpenPaymentChoosehandler={(data)=>{setIsOtpVerified(data)}}/>
          <hr style={{color:"white",height:"1px"}} className='my-3' />
          {
            isOtpVerified ?<>
          
          <h2 style={{color:"gold"}}>Choose Payment Method</h2>
          <div className='mx-5 my-3' >
            <input type="radio" name="pay" checked={ispaymentselect ==="cash on delivery"} onChange={(e)=>{setIsPaymentSelect("cash on delivery")}}  /> <span style={{fontSize:"14px"}}>Cash On Delivery</span> 
            </div>
            <div className='mx-5 my-3' >
            <input type="radio"  name="pay" checked={ispaymentselect === "pay  Now"} onChange={(e)=>{setIsPaymentSelect("pay  Now")}}  /> <span style={{fontSize:"14px"}}>Pay Online Now</span> 
            </div>
            <div className='text-center px-5'>
            
            {ispaymentselect === "cash on delivery" || ispaymentselect === 'pay  Now' ? <>
            {
              ispaymentselect === 'pay  Now' ?  
              <button
              className="btn"
              style={{
                backgroundColor: 'green',
                color: 'white',
                cursor: isbuttonloading ? 'progress' : 'pointer',
                opacity: isbuttonloading ? 0.6 : 1,
              }}
              onClick={!isbuttonloading ? ()=>{ setIsButtonLoading(true);
                setTimeout(() => {
                  setIsButtonLoading(false);
                }, 2000);} : undefined}
            >
              {isbuttonloading ? 'Loading...' : <PaymentTransaction totalPrice={totalPrice} user={user} messagehandler={(data)=>setIsMessage(data)} transactionhandler={transactionHandler} />}
            </button>
              :
              <button
              className="btn"
              style={{
                backgroundColor: 'green',
                color: 'white',
                cursor: isbuttonloading ? 'progress' : 'pointer',
                opacity: isbuttonloading ? 0.6 : 1,
              }}
              onClick={!isbuttonloading ? ()=>{ setIsButtonLoading(true);
                setTimeout(() => {
                  setIsButtonLoading(false);
                }, 2000);} : undefined}
            >
              {isbuttonloading ? 'Loading...' : <div onClick={()=>{transactionHandler('COD','COD',false);setIsMessage(true);}}>Order Now</div>}
            </button>
              // <button className='btn' style={{backgroundColor:'green',color:"white"}}  ></button>
            }
            </>
            :
            ""
}
           </div> 
           </>
           :
           ""
          }
          </div>
          

          <hr style={{margin:"10px",color:"white",height:"3px"}} />
         <div className='row mx-3 container  '>
           <div className='col-12 text-start'>
           <button className='btn' style={{backgroundColor:'black',color:"white"}} onClick={()=>{setCurrentStep(CurrentStep - 1)}}>Back</button>
           </div>
          
         
       </div>
       </div>
          </>
          :
          ""
        }
        </>
        :
        <>
         {
                loading ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '10vh' }}>
                <ReactLoading type="spin" color="#000" height={40} width={40} />
                </div>
              :
             <div>
                 <h1 className='text-center my-3' style={{color:"red"}}>Please Select Items for Proceed checkout </h1>
             </div>
              }
          </>
}
       
         
      </div>
      
      
        
      
      </main>

      <Footer/>
   </Fragment>
  )
}
