import React from 'react' ;

export default function StepIndicator({ currentStep }) {
    const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];
  return (
    <div className="step-indicator py-3">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`step ${currentStep === index + 1 ? 'active' : ''}`}
        >
          {step}
        </div>
      ))}
    </div>
  )
}
