import React from 'react';
import "./Footer.css";

export default function Footer() {
  return (
    <div className='footer'>
    <p>Copyright © 2023
      <a href="#">Privacy Policy</a> |
      <a href="#">Terms of Use</a>
    </p>
  </div>
  )
}
