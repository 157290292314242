import React,{Fragment, useState} from 'react';
import ReactLoading from "react-loading";
import API from '../../Components/APIURL';

const statusColors = {
    notProcessed: { backgroundColor: '#0000FF', color: '#FFFFFF' },
    processing: { backgroundColor: '#FFA500', color: '#000000' },
    shipped: { backgroundColor: '#008000', color: '#FFFFFF' },
    delivered: { backgroundColor: '#800080', color: '#FFFFFF' },
    cancelled: { backgroundColor: '#FF0000', color: '#FFFFFF' },
    returned: { backgroundColor: '#FFD700', color: '#000000' },
  };
  
  const statusStyle = {
    display: 'inline-block',
    padding: '5px 10px',
    margin: '5px',
    fontWeight: 'bold',
    borderRadius: '5px',
  };

function Table({myorders,userID,GetReturnOrderRequestsBySellerID}) {

  const [isRejectmodal, setIsRejectmodal] = useState(false);
  const [isAcceptModal, setIsAcceptModal] = useState(false);
  const [issbtnloading,setIsbtnloading] = useState(false);
  const [isViewReason,setIsViewReason] = useState(false);
  const [reason,setReason] = useState('');

  const [returnOrderRequestID,setReturnOrderRequestID] = useState('');

  const handler = (e,newStatus) => {
    setIsbtnloading(true);
    e.preventDefault()
    let data = {
               newStatus:newStatus,
               requestId:returnOrderRequestID
               };
    let options = {
                   method:"post",
                   headers:{"Content-Type":"application/json"},
                   body:JSON.stringify(data),
                   credentials:"include" 
                  };
             fetch(`${API}/api/returnrequest/Update-status/${userID}`,options)
             .then(res=>res.json())
             .then(result=>{
              // console.log("result",result);
              setIsbtnloading(false);
              setIsAcceptModal(false);
              setIsRejectmodal(false);
              GetReturnOrderRequestsBySellerID();
             })
  };

  return (
    <Fragment>
      {
         isRejectmodal ?
        <Fragment>
        <div className='blur-background'></div>
        <div className="overlay mx-auto my-auto" style={{width:"320px",height:"250px",backgroundColor:"white",borderRadius:"7px"}}>
        <div className='row container my-3 p-0' >
          <div className='col-12 text-end' style={{fontWeight:"bolder",fontSize:"15px",cursor:'pointer'}} onClick={()=>setIsRejectmodal(false)}><i className='bi bi-x' style={{fontSize:"25px",fontWeight:"bolder"}}></i></div>
        </div >
            <h1 > Reject Return Orders  </h1>
            <form className='row container  mx-auto' onSubmit={(e)=>handler(e,"Rejected")}>

            <div className='col-12'>
           <p >Do you want to Reject the Order ? </p>
           </div>
           {/* <textarea name="" id="" cols="30" rows="10" required placeholder='Give a Reason for Cancel Order'></textarea> */}
           <div className='col-12'>
            <div className="row">
           <input type="checkbox" name="" id="" style={{width:"30px",height:"30px"}} className='col-4 text-start my-3' required /> <span className='my-auto col-8' style={{fontWeight:"bolder",fontSize:"14px"}}>Select Box</span>
           </div>
           </div>
            
           <button type="submit" className='btn btn-danger btn-block my-3' style={{backgroundColor:"red",color:"white"}}>
            {
            issbtnloading ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <ReactLoading type="spin" color="white" height={30} width={30} />
            </div>
            :
            "Reject" 
            }
            </button>
           </form>
        </div>
        </Fragment>
        :
        null
}

{
         isAcceptModal ?
        <Fragment>
        <div className='blur-background'></div>
        <div className="overlay mx-auto my-auto" style={{width:"320px",height:"250px",backgroundColor:"white",borderRadius:"7px"}}>
        <div className='row container my-3 p-0' >
          <div className='col-12 text-end' style={{fontWeight:"bolder",fontSize:"15px",cursor:'pointer'}} onClick={()=>setIsAcceptModal(false)}><i className='bi bi-x' style={{fontSize:"25px",fontWeight:"bolder"}}></i></div>
        </div >
            <h1 > Accept Return Orders  </h1>
            <form className='row container  mx-auto' onSubmit={(e)=>handler(e,"Accepted")}>

            <div className='col-12'>
           <p >Do you want to Accept the Order ? </p>
           </div>
           {/* <textarea name="" id="" cols="30" rows="10" required placeholder='Give a Reason for Cancel Order'></textarea> */}
           <div className='col-12'>
            <div className="row">
           <input type="checkbox" name="" id="" style={{width:"30px",height:"30px"}} className='col-4 text-start my-3' required /> <span className='my-auto col-8' style={{fontWeight:"bolder",fontSize:"14px"}}>Select Box</span>
           </div>
           </div>
            
           <button type="submit" className='btn btn-success btn-block my-3'  style={{backgroundColor:"Green",color:"white"}}>
            {
            issbtnloading ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <ReactLoading type="spin" color="white" height={30} width={30} />
            </div>
            :
            "Accept" 
            }
            </button>
           </form>
        </div>
        </Fragment>
        :
        null
}

{
         isViewReason ?
        <Fragment>
        <div className='blur-background'></div>
        <div className="overlay mx-auto my-auto" style={{width:"320px",height:"350px",backgroundColor:"white",borderRadius:"7px"}}>
        <div className='row container my-3 p-0' >
          <div className='col-12 text-end' style={{fontWeight:"bolder",fontSize:"15px",cursor:'pointer'}} onClick={()=>setIsViewReason(false)}><i className='bi bi-x' style={{fontSize:"25px",fontWeight:"bolder"}}></i></div>
        </div >
        <div className='row container my-3 p-0 mx-auto' >
          <h1>Reason</h1>
           <div className='mx-3 text-center'>{reason}</div>
        </div >
           
        </div>
        </Fragment>
        :
        null
}


    <div className="orders-table-container container card ">
      <table className="orders-table" >
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Amount</th>
            <th>Quantity</th>
            <th>Reason</th>
            {/* <th>Transaction ID</th> */}
            <th>Payment</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <>
       
    
      
    {/* <tr key={order.id} style={{ fontSize: "25px", fontWeight: "bolder" }} title="Product ordered User" className='mt-3' >
             <td className="mx-auto text-danger text-start " colSpan="8">
              <div className='row'>
                 <div className="col-2 col-md-2 col-lg-1 col-xl-1 my-auto"><img src={order.buyerDetails.Photo} alt="photo.." style={{width:"20px",height:"20px",borderRadius:"50%"}} /></div>
                 <div className="col-10 col-md-10 col-lg-11 col-xl-11 my-auto">{order.buyerDetails.Username}</div>
              </div>
                
             </td>
            </tr>
            <tr  style={{ fontSize: "25px", fontWeight: "bolder" }}>
            <td className="mx-auto text-danger text-start " colSpan="8">
               <div>Delivery Address</div>
            </td>
            </tr> */}
    {
     myorders && myorders.length > 0 ? myorders.map((items) =>
        <tbody className='mx-auto my-3 tbody-3d-card'>
          {items.productDetails ? (
            <>
            <tr key={items.id} style={{ fontSize: "25px", fontWeight: "bolder" }} title="Product ordered User" className='mt-3' >
             <td className="mx-auto text-danger text-start " colSpan="8">
              <div className='row'>
                 <div className="col-2 col-md-2 col-lg-1 col-xl-1 my-auto"><img src={items.buyerDetails.Photo} alt="photo.." style={{width:"20px",height:"20px",borderRadius:"50%"}} /></div>
                 <div className="col-10 col-md-10 col-lg-11 col-xl-11 my-auto">{items.buyerDetails.Username}</div>
              </div>
                
             </td>
            </tr>
            <tr  style={{ fontSize: "25px", fontWeight: "bolder" }}>
            <td className="mx-auto text-danger text-start " colSpan="8">
               <div>Delivery Address</div>
            </td>
            </tr> 
            <tr key={items.id} style={{ fontSize: "25px", fontWeight: "bolder" }}>
              <td className="order-image">
                <img src={items.productDetails.ProductImages[0]} alt={items.productDetails.ProductName} />
              </td>
              <td>{items.productDetails.ProductName}</td>
              <td style={{ color: "green" }}>&#8377; {items.OrderPrice}</td>
              <td>{items.quantity}</td>
              <td><i className='bi bi-eye-fill' style={{color:'green',cursor:"pointer"}} onClick={()=>{setIsViewReason(true);setReason(items.Reason)}}></i></td>
              {/* <td>{items.paymentDetails.TransactionID}</td> */}
              <td>
                {items.paymentDetails.isPaid ? <div style={{ color: "green" }}>Paid</div> : <div style={{ color: "red" }}>Not Paid</div>}
              </td>
              <td>
                {items.Status === "Not processed" && (
                  <div style={{ ...statusStyle, ...statusColors.notProcessed }}>Not Processed</div>
                )}
                {items.Status === "Processing" && (
                  <div style={{ ...statusStyle, ...statusColors.processing }}>Processing</div>
                )}
                {items.Status === "Shipped" && (
                  <div style={{ ...statusStyle, ...statusColors.shipped }}>Shipped</div>
                )}
                {items.Status === "Delivered" && (
                  <div style={{ ...statusStyle, ...statusColors.delivered }}>Delivered</div>
                )}
                {items.Status === "Cancelled" && (
                  <div style={{ ...statusStyle, ...statusColors.cancelled }}>Cancelled</div>
                )}
                {items.Status === "Returned" && (
                  <div style={{ ...statusStyle, ...statusColors.returned }}>Returned</div>
                )}
              </td>
              <td>
                <div className="action-buttons">
                <div className='row' >
                  <div className='col-6'>

                  <i className="bi bi-x mx-2" style={{cursor:"pointer",fontSize:"30px",fontWeight:"bolder",color:"red"}} onClick={()=>{setIsRejectmodal(true);setReturnOrderRequestID(items._id)}}></i>
                   
                  </div>
                  
                  <div className='col-6'>
             
                  <i className="bi bi-check" style={{cursor:"pointer",fontSize:"30px",fontWeight:"bolder",color:"green"}} onClick={()=>{setIsAcceptModal(true);setReturnOrderRequestID(items._id)}} ></i>
                  </div>
              </div>
                </div>
              </td>
            </tr>
            </>
          ) : (
          
            <tr key={items.id} style={{ fontSize: "25px", fontWeight: "bolder" }}>
            <td className="mx-auto text-danger text-center " colSpan="8">
             Seller Delete this product from Angadi
              
            </td>
          </tr>
          )}
        </tbody>
      )
      : (
        <tr>
          <td colSpan="8" className="mx-auto  text-center" style={{ fontSize: "25px", fontWeight: "bolder",color:"red" }}>
            No Orders
          </td>
        </tr>
      )
      }
      
      
 


        </>
      </table>
      </div>
   </Fragment>
  );
}

export default Table;
