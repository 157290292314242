import React, { useEffect } from 'react';

export function CheckCookieAndReload() {
  // Specify the name of the cookie you want to check
  const cookieName = 'mycookie';

  // Function to retrieve the value of a specific cookie by name
  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return decodeURIComponent(cookie.substring(name.length + 1));
      }
    }
    return null;
  }

  
    // Check if the cookie exists
    const cookieValue = getCookie(cookieName);

    if (cookieValue === null) {
      // The cookie does not exist; reload the page
      window.location.reload();
    } else {
      // The cookie exists; you can perform any other actions here
      // console.log(`Cookie value: ${cookieValue}`);
    }


  return <div></div>;
}

// export default checkCookieAndReload;
