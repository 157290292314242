import React ,{ Fragment } from 'react';
import  { Link } from "react-router-dom";

export default function SignupSuccess() {
  return (
    <Fragment>
    <div className="container">
      <h1 className="text-center my-4">Account Confirmation</h1>
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="card border-success mb-3">
            <h3 className="card-header bg-success text-white text-center py-3" >Thank you for creating an account!</h3>
            <div className="card-body">
              <h5 className="card-title" style={{fontSize:"20px",fontWeight:"bolder"}}>Confirmation Email Sent</h5>
              <p className="card-text" style={{fontSize:"18px",textAlign:"start",padding:"20px"}}>We have sent you an email with instructions on how to confirm your account. Please follow the instructions in the email to complete the account confirmation process.</p>
              <div className="text-center">
             <Link to="/" className="btn btn-warning mt-3" style={{paddingTop:"8px"}}>Back to Home</Link>
             </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
    </Fragment>
  )
}
