import React ,{ useState , useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode" ;
import API from "../APIURL";
import {CheckCookieAndReload} from "../CookieChecker";

export default function CompanyCard() {
  let navigate = useNavigate();

  const token = Cookies.get('mycookie');
  let responsePayload ;
  if(token != undefined){
    responsePayload = jwt_decode(token);
    // console.log("tokens",responsePayload);
  }
   const [user,setUser] = useState(responsePayload ||'');
   const [currentUser , setCurrentUser] = useState('')
     
   useEffect(()=>{
    let output = fetch(`${API}/api/auth/getuser/${user.id}`,{method:"GET",credentials: 'include'});
        output.then(res=>res.json())
               .then(result=>{
                //  console.log("result",result);
                if(result.data)
                 setCurrentUser(result.data);
                else
                CheckCookieAndReload();
               })
   },[])
 
  return (
    
        <div className="profile-card col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-3 p-1 mx-auto" >
          {
            currentUser && currentUser.CompanyName ?
            <div className='row my-auto'>
               <h1>Your Bussiness</h1>
               <p>Welcome to Angadi </p>
                 <button className='btn btn-block my-3 mx-auto ' style={{width:"100px",backgroundColor:"green",color:'white'}} onClick={()=>{ navigate('/Mybusiness')}}>Go</button>
            </div>
      
            :
            <div className='row my-auto'>
               <h1>Your Bussiness</h1>
               <p>Please Create Your Dream Business </p>
                 <button className='btn btn-block my-3 mx-auto ' style={{width:"100px",backgroundColor:"black",color:'white'}} onClick={()=>{ navigate('/Mybusiness')}}>Create</button>
            </div>
          }
           </div>
    
  )
}
