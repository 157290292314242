import React ,{useState ,useEffect, Fragment} from 'react';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode" ;
import API from '../../Components/APIURL';
import { CheckCookieAndReload} from "../../Components/CookieChecker";
import Header from "../Header/Header";
import Footer from "../../Components/Footer/Footer";
import photo from "../../Assets/Bussiness.png";
import Table from './Table';
import Loading from '../../Components/Loading/Loading';
import ReactLoading from "react-loading";
// import "./style.css";

const statusColors = {
  notProcessed: { backgroundColor: '#0000FF', color: '#FFFFFF' },
  processing: { backgroundColor: '#FFA500', color: '#000000' },
  shipped: { backgroundColor: '#008000', color: '#FFFFFF' },
  delivered: { backgroundColor: '#800080', color: '#FFFFFF' },
  cancelled: { backgroundColor: '#FF0000', color: '#FFFFFF' },
  returned: { backgroundColor: '#FFD700', color: '#000000' },
};

const statusStyle = {
  display: 'inline-block',
  padding: '5px 10px',
  margin: '5px',
  fontWeight: 'bold',
  borderRadius: '5px',
};

const tabs = [
  'Not Processed',
  'Processing',
  'Shipped',
  'Delivered',
  'Cancelled',
  'Returned',
];

export default function Orders() {
     // get user data from cookies
  const token = Cookies.get('mycookie');
  let responsePayload ;
  if(token != undefined){
    responsePayload = jwt_decode(token);
  }

 const [user,setUser] = useState(responsePayload ||'');
 const [orders,setOrders] = useState([]);
 const [cancelledOrders, setCancelledOrders] = useState([]);
 const [returnedOrders, setReturnedOrders] = useState([]);
 const [isOrderUpdate,setIsOrderUpdate] = useState(false);
 const [updatedata,setUpdatedata] = useState('');
 const [isloading,setIsloading] = useState(false);
 const [editStatus,setEditStatus] = useState('');
 const [subOrderId,setSuborderid] = useState('');
 const [btnloading,setBtnloading] = useState(false);
 const [activeTab, setActiveTab] = useState('Not Processed');
 const [Status,setStatus] = useState("Under Review");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

    useEffect(()=>{
      GetOrderHandler();
   },[Status])
   const GetOrderHandler = ()=>{
    
    setIsloading(true);
    fetch(`${API}/api/returnrequest/get-return-order-request/${user.id}/${Status}`,{ method:"GET", credentials:"include" })
    .then(res=>res.json())
    .then(response=>{
      // console.log("response",response);
       if(response.data){
        //  console.log("response data",response.subOrders);
         setOrders(response.data);
       }
       else{
         CheckCookieAndReload();
       }
       setIsloading(false)
       
   })
   }

   const handleCancelOrder = (orderId) => {
    // Logic to cancel the order and update the state
    setCancelledOrders([...cancelledOrders, orderId]);
  };

  const handleReturnOrder = (orderId) => {
    // Logic to initiate a return for the order and update the state
    setReturnedOrders([...returnedOrders, orderId]);
  };

  const SaveHandler = ()=>{
    //  console.log("Status",updatedata);
     setBtnloading(true);
     let data ={
               status:editStatus,
               subOrderId:subOrderId,
               productId:updatedata.productDetails._id
     }
     let options = {
                method:"POST",
                headers:{"Content-Type":"application/json"},
                body:JSON.stringify(data),
                credentials:"include"
     };

    //  console.log("Status",data);
      fetch(`${API}/api/order/sub-orders/${user.id}/products`,options)
      .then(res=>res.json())
      .then(response=>{
        GetOrderHandler();
        setBtnloading(false);
        setIsOrderUpdate(false);
      });
    
  }
  // console.log("EDIT data",updatedata);
  return (
    <Fragment>
    <Header/>
    <main style={{minHeight:"85vh",marginTop: '70px'}}>
    <h1>Request for My Returned Order</h1>
    
    {/* <div className="tabs-container">
      <div className="tabs-row">
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`tab ${activeTab === tab ? 'active-tab' : ''}`}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </div>
        ))}
      </div>
      <div className="tab-content">
        
        {activeTab === 'Not Processed' && <div>Content for Not Processed Tab</div>}
        {activeTab === 'Processing' && <div>Content for Processing Tab</div>}
        {activeTab === 'Shipped' && <div>Content for Shipped Tab</div>}
        {activeTab === 'Delivered' && <div>Content for Delivered Tab</div>}
        {activeTab === 'Cancelled' && <div>Content for Cancelled Tab</div>}
        {activeTab === 'Returned' && <div>Content for Returned Tab</div>}
      </div>
    </div> */}

    {isloading ? 
    <Loading/>
    :

    <>
    <div className='row my-3 container'>
      <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 text-end px-2 my-auto">Select Catogaery</div>
      <div className='col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 text-start px-0'>
      <select value={Status} onChange={(e)=>setStatus(e.target.value)}>
          <option value="Under Reivew">Under Reivew</option>
          <option value="Accepted"> Accepted </option>
          <option value="Rejected"> Rejected </option>
        </select>
      </div>
      <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4'></div>
    </div>
    <Table myorders={orders} userID={user.id} GetReturnOrderRequestsBySellerID={GetOrderHandler} />   


{
  isOrderUpdate ?
  <>
  <div className='blur-background'></div>
        <div className="create-product overlay ">
           <h1>Update Order</h1>
           <div className='card'>
            <div className="row text-dark container">
              <div className="col-12 my-2"><i className="bi bi-person-fill"></i>  &nbsp; <span> Jeevanantham S </span></div>
              <div className="col-12 my-2"><i className="bi bi-pin-fill"> </i>&nbsp; <span>1/ 67, periyapallipalyam , trichy - 621207</span> </div>
              <div className="col-12 my-2"><i className="bi bi-phone-fill"></i>&nbsp; <span>6385229170</span></div>
            </div>
           </div>
           <div className="card">
                <div className="row ">
                    <div className='col-4 text-center my-auto'>
                    {
                    updatedata.productDetails.ProductImages && updatedata.productDetails.ProductImages.length > 0 && <img src={updatedata.productDetails.ProductImages[0]} alt="product img..." style={{width:"100%",height:"auto",borderRadius:"7px"}} />
                    }
                    </div>
                    <div className='col-8 text-dark'>
                       <div  style={{color:"black",fontWeight:"bolder",fontSize:"14px"}} >{updatedata.productDetails.ProductName}</div>
                       <div  style={{color:"black",fontWeight:"bolder",fontSize:"14px"}} >{updatedata.productDetails.ProductDescription.slice(0,50)} ...</div>
                       <div  style={{color:"black",fontWeight:"bolder",fontSize:"14px"}} >
                  Quantity : <span style={{color:"green",fontWeight:"bolder",fontSize:"14px"}}>&nbsp;  3 </span>
               </div>
               <div  style={{color:"black",fontWeight:"bolder",fontSize:"14px"}} >
                  Amount : <span style={{color:"green",fontWeight:"bolder",fontSize:"14px"}}>&nbsp;  $ 100</span>
               </div>
                       <div  style={{color:"black",fontWeight:"bolder",fontSize:"14px"}} >
                  Payment : <span style={{color:"green",fontWeight:"bolder",fontSize:"14px"}}>&nbsp;  COD</span>
               </div>
                
                    </div>
                </div>
           </div>
            
            
          
           <div className='dashboard'>
           <label htmlFor="status" className='my-3' style={{fontSize:"14px"}}>&nbsp; Status</label>
           <select id="status" style={{cursor:"pointer"}} value={editStatus}  onChange={(e)=>setEditStatus(e.target.value)}>

                <option style={{ ...statusStyle, ...statusColors.notProcessed }} className='card' value="Not Processed">Not Processed</option>
              
             
                <option style={{ ...statusStyle, ...statusColors.processing }} className='card' value="Processing">Processing</option>
              
            
                <option style={{ ...statusStyle, ...statusColors.shipped }} className='card' value="Shipped">Shipped</option>
              
             
                <option style={{ ...statusStyle, ...statusColors.delivered }} className='card' value="Delivered">Delivered</option>
              
             
                <option style={{ ...statusStyle, ...statusColors.cancelled }} className='card' value="Cancelled">Cancelled</option>
              
             
                <option style={{ ...statusStyle, ...statusColors.returned }} className='card' value="Returned">Returned</option>
              
           </select>
          

           </div>
          
           <div style={{position:"absolute",top:'90%',left : '30%',alignItems:"center"}}>
            <div className="row text-center" >
              <div className="col-6 text-end">
                <button className='btn btn-dark' onClick={()=>setIsOrderUpdate(false)}  disabled={btnloading} style={{cursor:btnloading ?"not-allowed" :'pointer'}} > Close </button>
              </div>
              <div className="col-6 text-start">
              <button className='btn btn-success'onClick={()=>SaveHandler()} disabled={btnloading} style={{cursor:btnloading ?"not-allowed" :'pointer'}} >{ btnloading ? <ReactLoading type="spin" color="white" height={30} width={30} /> :"Save"} </button>
              </div>
            </div>
            </div>
        </div>
        </>
        :
        ""
}
</>
}

    </main> 
    <Footer/>
    </Fragment>
  )
}
