import React ,{ Fragment ,useEffect,useState } from 'react';
import StepIndicator from './StepIndicator';
import LocationImg from "../../Assets/Location.png";
import API from "../APIURL";
import ModalLoading from "../Loading/ModalLoading";
import { CheckCookieAndReload} from "../CookieChecker";

export default function CreateProduct({currentUser , triggergetproducthandler , updateproduct , items }) {

  const [updateProduct,setUpdateProduct] = useState(updateproduct !== undefined ? updateproduct : false);
  const [createStepForm,setCreateStepForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productImage, setProductImage] = useState('');
  const [productCategory,setProductCategory] = useState('');
  const [productSubCategory,setProductSubCategory] = useState('');
  const [quantity,setQuantity] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [quantityType,setQuantityType] = useState('');
  const [stepforward,setStepForward] = useState(true);
  const [modalLoading,setModalLoading] = useState(false);

// console.log("currentuser",currentUser);
useEffect(()=>{
         if(updateproduct){
            setProductName(items.ProductName);
            setProductDescription(items.ProductDescription);
            setProductCategory(items.ProductCategory);
            setProductSubCategory(items.ProductSubCategory);
            
            setQuantity(items.ProductQuantity);
            setQuantityType(items.ProductQuantityType);
             setProductPrice(items.ProductPrice);
             setPreviewImages(items.ProductImages);
             setVideoPreview(items.ProductVideo);
             setAddress(items.Address);
             setCountry(items.Country);    
         }
        },[])
        // console.log("currrent user",currentUser);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
    StepForwardHandler(currentStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
    StepForwardHandler(currentStep - 1);
  };


  const handleImageUpload = (e) => {
    const files = e.target.files;

    // Validate file count
    if (previewImages.length + files.length > 5) {
      alert('Please select a maximum of 5 images.');
      return;
    }

    // Iterate through each selected file
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Validate file size
      const maxAllowedSize = 500 * 1024; // 500kb
      if (file.size > maxAllowedSize) {
        alert(`The file ${file.name} exceeds the maximum size of 500kb.`);
        return;
      }

      // Create a FileReader to read the image file
      const reader = new FileReader();

      // Read the image file and perform necessary actions
      reader.onload = () => {
        const imageDataUrl = reader.result;

        // Update the state with the new image preview
        setPreviewImages((prevImages) => [...prevImages, imageDataUrl]);
      };

      // Read the image file as data URL
      reader.readAsDataURL(file);
    }
  };

  // console.log("preview images",previewImages);
  const handleDeleteImage = (index) => {
    setPreviewImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  // Video Upload Handler
  const handleVideoUpload = (e) => {
    const file = e.target.files[0];

    // Validate file size
    const maxAllowedSize = 5 * 1024 * 1024; // 5MB
    if (file && file.size > maxAllowedSize) {
      alert('The selected video exceeds the maximum size of 5MB.');
      return;
    }

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        setVideoPreview(event.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const StepForwardHandler = (step)=>{
    // console.log("step",step);
    if(step === 1 ){
      // console.log("execute step 1");
      if(productName.length < 5 || productDescription.length < 50 || productName.length > 50 || productDescription.length > 1000){
        setStepForward(true);
      }
      else{
        setStepForward(false);
      }
    }
    if(step === 2 ){
      // console.log("execute step 2");

      if(productCategory.length < 3 || productSubCategory.length < 3 || productCategory.length > 50 || productSubCategory.length > 50  || !quantity || isNaN(quantity) ||isNaN(productPrice) || !quantityType || !productPrice  ){
        setStepForward(true);
      }
      else{
        setStepForward(false);
      }
    }
    if(step === 3 ){
      // console.log("execute step 2");

      if(previewImages.length <3){
        setStepForward(true);
      }
      else{
        setStepForward(false);
      }
    }
  }
  useEffect(()=>{
    StepForwardHandler(currentStep)
  },[productName.length ,productDescription.length,productCategory,productSubCategory,quantity,quantityType,productPrice,previewImages])


  // Common data for create and updatehandler
  let data = {
    ProductName:productName,
    ProductDescription:productDescription,
    ProductCategory:productCategory,
    ProductSubCategory:productSubCategory,
    ProductQuantity:quantity,
    ProductQuantityType:quantityType,
    ProductPrice:productPrice,
    ProductImages:previewImages,
    ProductVideo:videoPreview,
    Address:currentUser.Address,
    Pincode:currentUser.Pincode,
    MobileNo:currentUser.MobileNo,
    State: currentUser.State,
    Country:currentUser.Country,
    CompanyID:currentUser._id

}

// console.log("current user",currentUser);
// console.log("data",data);

let options = {
           method:"POST",
           headers:{"Content-Type" : "application/json"},
           body:JSON.stringify(data),
           credentials: 'include'
          }

   // Create Product
  const handleSubmit = (e) => {
    e.preventDefault();
    setModalLoading(true);
    // Add your logic to handle form submission here
   
    let output = fetch(`${API}/api/product/Create-Product/${currentUser._id}`,options);
        output.then(res=>res.json())
              .then(response=>{
                    if(response.create){
                       // Reset form fields 
                      setProductName('');
                      setProductDescription('');
                      setProductPrice();
                      setPreviewImages([]);
                      setVideoPreview('');
                      setProductCategory('');
                      setProductSubCategory('');
                      setQuantity();
                      setProductSubCategory('');
                      setQuantityType('');
                      setCurrentStep(1);
                      setCreateStepForm(false);
                      setStepForward(true);
                      triggergetproducthandler();
                    }
                    else{
                    
                    }
                    setModalLoading(false);
              })

  };

  const handleupdatesubmit =(e)=>{
    e.preventDefault();
    setModalLoading(true);
    // Add your logic to handle form submission here
    //  console.log("currentuser",currentUser);
    let output = fetch(`${API}/api/product/Update-Product/${items._id}/${currentUser._id}`,options);
        output.then(res=>res.json())
              .then(response=>{
                // console.log("response",response);
                    if(response.update){
                       // Reset form fields 
                      setProductName('');
                      setProductDescription('');
                      setProductPrice();
                      setPreviewImages([]);
                      setVideoPreview('');
                      setProductCategory('');
                      setProductSubCategory('');
                      setQuantity();
                      setProductSubCategory('');
                      setQuantityType('');
                      setCurrentStep(1);
                      setCreateStepForm(false);
                      setStepForward(true);
                      triggergetproducthandler();
                    }
                    else{
                      CheckCookieAndReload();
                    }
                    setModalLoading(false);
              })

  }

  // Create Product Form Close Handler 
  const CloseHandler = ()=>{
    setCreateStepForm(false);// Reset form fields
    setProductName('');
    setProductDescription('');
    setProductPrice();
    setPreviewImages([]);
    setVideoPreview('');
    setProductCategory('');
    setProductSubCategory('');
    setQuantity();
    setProductSubCategory('');
    setQuantityType('');
    setCurrentStep(1);
    setStepForward(true);
  }

  return (
    <Fragment>
      {
        createStepForm ? 
        <>
        <div className='blur-background'></div>
        <div className="create-product overlay">
       <button className='btn btn-block btn-dark  text-white' style={{color:'white',float:'right'}} onClick={()=>{CloseHandler()}}>X</button>
       {
        updateProduct ?
        <h3>Update Product </h3>
        :
       <h3>Create Product </h3>
       }
       
        
        <form onSubmit={updateProduct ? handleupdatesubmit : handleSubmit}>
        <StepIndicator currentStep={currentStep} />
        {
          modalLoading ?
          <ModalLoading/>

          :
        
        <>
          {currentStep === 1 && (
            <div className="form-step">
              <div className="form-group">
                <label htmlFor="product-name">Product Name : ( {productName.length} / 50 letters)<sup style={{fontSize:"12px",color:"red"}}> * </sup> minimum 5 letters </label>
                <input
                  type="text"
                  id="product-name"
                  value={productName}
                  onChange={(e) =>{ setProductName(e.target.value)}}
                  required
                  placeholder='Product Name ( min 5 letters )'
                />
              </div>
                          
              <div className="form-group">
                <label htmlFor="product-description">Product Description : ( {productDescription.length} / 1000 letters)<sup style={{fontSize:"12px",color:"red"}}> * </sup> minimum 50 letters</label>
                <textarea
                  id="product-description"
                  value={productDescription}
                  onChange={(e) => {setProductDescription(e.target.value)}}
                  required
                  rows={15}
                  placeholder='Product Description ( min 50 letters )'
                ></textarea>
              </div>    
              <div className='button-row'>         
              <button type="button" onClick={handleNext} className={stepforward   ? "disabledbutton" :  ""} disabled={stepforward}>
                Next
              </button>
              </div>
            </div>
          )}

          {currentStep === 2 && (
            <div className="form-step">
              <div className="form-group">
                <label htmlFor="product-Category">Product Category( {productCategory.length} / 50 letters)<sup style={{fontSize:"12px",color:"red"}}> * </sup>minimum 3 letters</label>
                <input
                  type="text"
                  id="product-Category"
                  value={productCategory}
                  onChange={(e) =>{ setProductCategory(e.target.value) }}
                  required
                  placeholder='Product Category'
                />
              </div>
              <div className="form-group">
                <label htmlFor="product-SubCategory">Product Sub Category( {productSubCategory.length} / 50 letters)<sup style={{fontSize:"12px",color:"red"}}> *  </sup>minimum 3 letters</label>
                <input
                  type="text"
                  id="product-SubCategory"
                  value={productSubCategory}
                  onChange={(e) => {setProductSubCategory(e.target.value)}}
                  required
                  placeholder='Product SubCategory'
                />
              </div>
              <div className="form-group">
                <label htmlFor="product-Quantity">Product Quantity<sup style={{fontSize:"12px",color:"red"}}> * </sup></label>
                <input
                  type="text"
                  id="product-Quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  required
                  placeholder='0'
                />
              </div>
              <div className="form-group">
                <label htmlFor="product-Quantity">Product Quantity Type<sup style={{fontSize:"12px",color:"red"}}> * </sup></label>
                <select  id="product-Quantity" value={quantityType}  onChange={(e) => setQuantityType(e.target.value)} required>
                   <option value="">--- Select Quantity Type ---</option>
                   <option value="KG">KG</option>
                   <option value="Piece">Piece</option>
                   <option value="Liter">Liter</option>
                   <option value="Package">Package</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="product-Price">Product Price<sup style={{fontSize:"12px",color:"red"}}> * </sup></label>
                <input
                  type="text"
                  id="product-Price"
                  value={productPrice}
                  onChange={(e) => setProductPrice(e.target.value)}
                  required
                  placeholder='RS.0'
                />
              </div>
              <div className='button-row'>
              
                <button type="button" onClick={handlePrevious}>
                 Previous
                </button>
               
              <button type="button"  onClick={handleNext} className={stepforward   ? "disabledbutton" :  ""} disabled={stepforward}>
                Next
              </button>
              

              </div>
             
            </div>
          )}

          {currentStep === 3 && (
            <div className="form-step">
              <div className="form-group">
                <div>Product Images ({previewImages.length}/5) and minimum 3 images<sup style={{fontSize:"10px",color:"red"}}>*</sup> </div>
                <label htmlFor="product-images" className='upload-images' style={{fontSize:"20px",cursor:"pointer"}}><i class="bi bi-cloud-arrow-up" style={{fontSize:"20px"}}></i>&nbsp;&nbsp;&nbsp;Upload Max 500 kb</label>
                <input
                 type="file"
                 id="product-images"
                 onChange={(e) => handleImageUpload(e)}
                 multiple
                accept="image/*"
                required
              />
        <div className="image-preview">
           {previewImages.length > 0 ? previewImages.map((image, index) => (
           <span key={index} className="preview-item">
           <img
             src={image}
             alt={`Preview ${index + 1}`}
             className="preview-image my-3"
           />
           <i className="bi bi-trash-fill delete-button" 
             onClick={() => handleDeleteImage(index)}></i>
           
         </span>
           
           ))
          :
          <div className='text-center' style={{fontSize:"15px"}}>No Product Images</div>
          }
      </div>
              </div>

              <div className='button-row'>
              <button type="button" onClick={handlePrevious}>
               Previous
              </button>
              <button type="button"  onClick={handleNext} className={stepforward   ? "disabledbutton" :  ""} disabled={stepforward} >
                Next
              </button>
              </div>

            </div>
          )}

          {currentStep === 4 && (
            <div className="form-step">
              <div className="form-group">
                <div>Product Video ( Max 5 MB optional) </div>
              <label htmlFor="product-images" className='upload-images' style={{fontSize:"20px",cursor:"pointer"}}><i class="bi bi-cloud-arrow-up" style={{fontSize:"20px"}}></i>&nbsp;&nbsp;&nbsp;Upload Video</label>
                <input
                 type="file"
                 id="product-images"
                 onChange={(e) => handleVideoUpload(e)}
                 single
                accept="video/*"
                required
              />
              <div className="video-preview text-center my-3">
              {videoPreview ? <video src={videoPreview} className="preview-video mx-auto" autoPlay={true} style={{width:"210px",height:"auto"}} muted loop controls />
              :
              <div className='text-center' style={{fontSize:"15px"}}>No Product Video</div>
              }
             </div>
              </div>
               <div className='button-row'>
              <button type="button" onClick={handlePrevious}>
               Previous
              </button>
              <button type="button"  onClick={handleNext}>
                Next
              </button>
              </div>
            </div>
          )}

          {currentStep === 5 && (
            <div className="form-step" style={{paddingTop:"50px"}}>         
             <h3> Product Details Preview </h3>
    <div style={{ fontFamily: 'Arial, sans-serif',color:'black', fontSize: '16px' }} className='card'>
      <div style={{ marginBottom: '10px' }}>
        <strong>Product Name : </strong> <span> {productName} </span>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <strong>Product Description :  </strong> <span> {productDescription.length > 50 ? productDescription.substring(0, 50) + '...' : productDescription} </span>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <strong>Product Category : </strong> <span> {productCategory} </span>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <strong>Product SubCategory : </strong> <span> {productSubCategory} </span>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <strong>Product Quantity : </strong> <span> {quantity} {quantityType}</span>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <strong>Product Quantity Type : </strong> <span> {quantityType}  </span>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <strong>Product Price : </strong> <span> {productPrice} / {quantityType} </span>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <strong>Product Images : </strong> <span> {previewImages.length} image</span>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <strong>Product Video : </strong>                 
        <span>{videoPreview ? <span> Product Video Available </span> : <span>No Videos</span>}</span>
      </div>
    </div>

              <div className='button-row'>
              <button type="button" onClick={handlePrevious}>
               Previous
              </button>
              {
                updateProduct ? 
                <button type="submit">Save</button>
                :              
                <button type="submit"> Create</button>
              }
              </div>
              
             
            </div>
          )}
          </>

       }
        </form>
      </div>
      </>
        :
        <>
      {
        updateProduct ?  
           <span className='mx-auto my-3' onClick={()=>setCreateStepForm(true)}>
              <i className="bi bi-pencil-square" style={{color:"green",fontWeight:"bolder",fontSize:"18px",cursor:"pointer"}}></i>
          </span>
        :
      
         <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 my-3 py-5' >
          <div className='card py-5' >
          <button className='btn btn-block btn-dark mx-auto my-auto' style={{width:"250px"}} onClick={()=>setCreateStepForm(true)}>
             + &nbsp;Create product
             
         </button>
         </div>
         </div>
      }
      </>
     }
    </Fragment>
    
  )
}
