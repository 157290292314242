import React ,{useState ,Fragment} from 'react'
import { Link } from 'react-router-dom';
import Header from "./Header";
import Footer from "../Footer/Footer";

export default function ForgotPassword() {
     const [changepassword,setChangepassword] = useState(false);

  return (
    <Fragment>
      <Header/>
    <div className="container mt-5 pt-5" style={{minHeight:"86vh"}}>
    <h1 className="text-center my-4" style={{marginTop:"100px"}}>Forgot Password</h1>
    <div className="row">
      <div className="col-md-8 mx-auto">
        <div className="card border-success mb-3">
          <div className="card-header bg-success text-white text-center my-3" style={{fontSize:"18px"}}>Change Password</div>
          <div className="card-body">
            <p className="card-text my-3 py-3" style={{fontSize:"18px"}}>Do you want to change the password ?</p>
          </div>
          <div className='row'>
           <div className='col-6 text-end'><Link to ="/changepassword"><div className="btn btn-block btn-success px-4" style={{padding:"8px"}}>YES</div></Link></div>
           <div className='col-6 text-start'><Link to='/login'><div className="btn btn-block btn-danger px-4" style={{padding:"8px"}}>No</div></Link></div>
        </div>
        </div>
       
      </div>
    </div>
  </div>
  
      <Footer/>
  </Fragment>
  )
}
