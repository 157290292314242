import React from 'react';
import {useParams , useNavigate} from "react-router-dom";
import API from '../APIURL';

export default function ConfirmAccount() {
  let navigate = useNavigate();
         let { id } = useParams();
        //  console.log("id",id);
         const confrimhandler = ()=>{
                       fetch(`${API}/api/auth/confrim/${id}`,{method:"GET",credentials: 'include'})
                       .then(res=>res.json())
                       .then(response=>{
                        // console.log("response",response);
                         if(response.update){
                          navigate('/verify');
                         }
                       })
                       
         }
  return (
    <div>
       <p>Please click the button below to verify your email</p>
       <button onClick={confrimhandler}>Verify</button>
       
    </div>
  )
}
