import React ,{useState , useEffect} from 'react';
import Header from '../../SellerComponents/Header/Header';
import banner from '../../Assets/banner.png';
import Footer from '../Footer/Footer';
import profileimg from "../../Assets/profileimage.jpg";
import CreateBussiness from './CreateBussiness';
import SubHeader from '../Aside/SubHeader';
import "./Bussiness.css";
import {useParams} from "react-router-dom"
import Product from './Product';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode" ;
import API from "../APIURL";
import Loading from ".././Loading/Loading";
import ModalLoading from '../Loading/ModalLoading';
import { Modal } from 'react-bootstrap';
import { CheckCookieAndReload} from '../CookieChecker';
import { encode, decode } from 'base-64';
import  Cropper  from "react-easy-crop";
import getCroppedImg from "../Profilepage/getCroppedImg"; 




export default function Bussiness() {

  // get user data from cookies
  const token = Cookies.get('mycookie');
  let responsePayload ;
  if(token != undefined){
    responsePayload = jwt_decode(token);
  }
  // const { id } = useParams();
   // Replace with the actual encoded ID you received from the route parameters.
  //  const decodedId = decode(id);
  // console.log("id",decodedId);

  const [user,setUser] = useState(responsePayload ||'');
  const [currentuser,setCurrentUser]  = useState('');
  const [loading,setLoading] = useState(false);
  const [modalLoading,setModalLoading] = useState(false);
  const [showModal1,setShowModal1] =  useState(false);
  const [showModal2,setShowModal2] =  useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [states, setStates] = useState([]);
  const [address, setAddress] = useState('');
  const [photo, setPhoto] = useState(null);
  const [pincode,setPincode] = useState('');
  const [mobileNo,setMobileno] = useState('');
  const [CountriesData,setCountriesData] =useState('');
  const [error,setError] = useState('');
  const [IsCroppingModalOpen,setIsCroppingModalOpen] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  

  
  


  // get  Current User Data
  const getuserApiHandler = () =>{
    setLoading(true);
    let output = fetch(`${API}/api/auth/getuser/${user.id}`,{method:"GET",credentials: 'include'});
    output.then(res=>res.json())
           .then(result=>{
            //  console.log("result",result);
            if(result.data){
             setCurrentUser(result.data);
             setPhoto(result.data.Logo);
             setCompanyName(result.data.CompanyName);
             setCompanyDescription(result.data.CompanyDescription);
             setCountry(result.data.Country);
             setState(result.data.State);
             setPincode(result.data.Pincode);
             setMobileno(result.data.MobileNo);
             setLoading(false); 
             getStates(result.data.Country);
            }
            else{
              CheckCookieAndReload();
            }
           })
  }

  // Get States from selected country
  const getStates = (countrydata)=>{
    let output = fetch(`${API}/api/country/GetStates/${countrydata}`,{method:"GET"})
    output.then(res=>res.json())
         .then(result=>{ 
          // console.log(result);
          if (result.data) {
          setStates(result.data[0].states);
        }
        else{
          CheckCookieAndReload();
        }
      })
  }

  // Get All Countries list
  useEffect(()=>{
    let output = fetch(`${API}/api/country/GetAllCountry`,{method:"GET"})
        output.then(res=>res.json())
             .then(result=>{
              if(result.data)
              setCountriesData(result.data);
            else CheckCookieAndReload();
            })
},[])



// create Country list 
  // Create option element 
  let countrylist = CountriesData.length && CountriesData.map(items=><option value={items.country} key={items.id}>{items.country}</option>);


  // Create State list 
  // get States for choosen country
  // This handler execute when choose country
  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    let output = fetch(`${API}/api/country/GetStates/${selectedCountry}`,{method:"GET"})
        output.then(res=>res.json())
              .then(result=>{ 
             // console.log(result);
              if (result.data) {
                    setCountry(selectedCountry);
                    setStates(result.data[0].states);
                 } else {
                    setCountry('');
                    setStates([]);
                    CheckCookieAndReload();
                 }
        })
  };  

  //  Get Current User data from Backend
  useEffect(()=>{
    getuserApiHandler();
   },[])

  //  After Create Company call current user data 
   const getuserupdatedata = ()=>{
    getuserApiHandler();
   }

  //  Update a Comapany Data
   const comapnyupdatehandler = ()=>{

    setModalLoading(true);

      let data = {
                Logo:photo,
                CompanyName:companyName,
                CompanyDescription:companyDescription,
                Country:country,
                State:state,
                Pincode:pincode,
                MobileNo:mobileNo
                 };

      // console.log("Company Updated data" ,data);

      let options = {
                    method:"POST",
                    headers:{"Content-Type":"application/json"},
                    body:JSON.stringify(data),
                    credentials: 'include'
                    };

      let output = fetch(`${API}/api/auth/updatebussiness/${user.id}`,options);
          output.then(res=>res.json())
                .then(response=>{
                  // console.log(response);

                  if(!response.update){
                     setError(response.error);
                     setTimeout(()=>{setError('')},3000);
                  }
                  else{
                    setError('');
                    handleModal2Close()
                    getuserApiHandler();
                    CheckCookieAndReload();
                  }
                  setModalLoading(false);

                })

   }



   //user Photo Edit Modal
  const handleModal2Close = () => {
                                setShowModal1(false);
                                setShowModal2(false);
                                setPincode(currentuser.Pincode);
                                setCompanyName(currentuser.CompanyName);
                                setCompanyDescription(currentuser.CompanyDescription);
                                setCountry(currentuser.Country);
                                setState(currentuser.State);
                                setPincode(currentuser.Pincode);
                                getStates(currentuser.Country);
                                setError('');
                                setPhoto(currentuser.Logo);
                                setModalLoading(false);
                               }; 

   // Photo Onchange Handler
  const handlePhotoChange = (e) => {
                         const file = e.target.files[0];
                        //  const file = acceptedFiles[0];
                         setSelectedPhoto(URL.createObjectURL(file));
                         setIsCroppingModalOpen(true);
                         setShowModal2(false);
                         };
 const onCropComplete = async (_, croppedAreaPixels) => {
                          const croppedImg = await getCroppedImg(selectedPhoto, croppedAreaPixels);
                          // console.log('cropped image',croppedImg);
                          setPhoto(croppedImg);
                        };
                      
                        
 const handleOkClick = async () => {
                          setIsCroppingModalOpen(false);
                         // Reset the file input value 
                          // handler(croppedImage);  
                          setShowModal1(true); 
                        };
                      
 const handleCancelClick = () => {
                          setIsCroppingModalOpen(false);
                          // setCroppedImage(null);
                          setPhoto(currentuser.Logo);
                          setShowModal1(true);
                          // setIsCroppingModalOpen(false); // Close the cropping modal
                        };


  return (
    <>
        <Header/>
        {/* <SubHeader/> */}
        
            

        <main style={{marginTop: '50px',minHeight:"85vh"}}>  
          {
            loading ?
            <Loading/>
            :
             <>
         {

        currentuser.CompanyName ? 
        <>
        {
          IsCroppingModalOpen ?
       <>
        {
          IsCroppingModalOpen &&  (
       <div className="crop-container">
         <Cropper
           image={selectedPhoto}
           crop={crop}
           zoom={zoom}
           aspect={1}
           onCropChange={setCrop}
           onZoomChange={setZoom}
           onCropComplete={onCropComplete}
           style={{ zIndex:"2" }}
          
         />
          <div className="buttons-container">
           <button onClick={handleOkClick} className="ok-button">
             OK
           </button>
           <button onClick={handleCancelClick} className="cancel-button">
             Cancel
           </button>
         </div>

       </div>
       
     )}
     </>
     :
     
          <>
          
        <div  className='row'>
          <div className='col-12'>
          <img src={banner} alt="banner...." className='banner' style={{ maxWidth: '100%'}} />
          </div>      
        </div>

        <div className='row mx-auto'>
        <div className='col-12 text-center'>
            <div style={{height:"110px",width:"110px",borderRadius:"50%",border:"5px solid gray", position: 'relative', display: 'inline-block' }} className=' mx-auto'>
            <img src={currentuser.Logo} alt="profileimg..." style={{height:"100px",width:"100px",borderRadius:"50%"}} />
            <i className="bi bi-pencil-square edit-icon" style={{ position: 'absolute', top: '0px', right: '0px', backgroundColor: 'rgba(0, 0, 0, 0.7)', color: 'white', padding: '5px' }} onClick={()=>{setShowModal1(true)}} ></i>
            </div>
         </div>
        </div>

        <div className='row mx-auto'>
        <div className='col-12 text-center'>
           <p className='  my-3' style={{fontWeight:"bolder",backgroundColor:"white",color:'black'}}>{currentuser.CompanyName} &nbsp;&nbsp;
           <i className="bi bi-pencil-square" style={{fontSize:"15px" , cursor:"pointer"}}  onClick={()=>{setShowModal2(true)}} ></i>            
           </p>
           <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
           {currentuser.CompanyDescription.length > 50 ? currentuser.CompanyDescription.slice(0, 50) + '...' : currentuser.CompanyDescription}
           </p>
          
           <p> {currentuser.Country}  , {currentuser.State} - ({currentuser.Pincode}).</p>
        </div>
        </div>

    {/* Product sections */}
     <Product currentuser={currentuser} />
        

{/* Company Data Edit Modals */}
<Modal show={showModal1} onHide={handleModal2Close} centered>
    <Modal.Header closeButton>
    <Modal.Title>Edit Comapany Logo</Modal.Title>
    </Modal.Header>
    { modalLoading ? <ModalLoading/>
     :
    <>
   <Modal.Body>
     <div className="text-center">
     <div style={{color:'red',fontWeight:"bolder"}} className='my-3'>{error}</div>
     <label htmlFor='edit-logo' style={{cursor:"pointer"}}>
     <input id="edit-logo" type="file" accept="image/*" onChange={handlePhotoChange} style={{display:"none"}} />
     <div >          
        <img
        src={photo} // Replace userPhoto with the URL or source of the uploaded image
        alt="Logo"
        className="rounded-circle border border-dark"
        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
        />
    </div>
    </label>
    </div>
  </Modal.Body>
  <Modal.Footer>
    <button className="btn btn-dark" onClick={()=>{comapnyupdatehandler()}}>Save</button>
  </Modal.Footer>
  </>
  }
</Modal>

{/* Company Data Edit Modal  */}
<Modal show={showModal2} onHide={handleModal2Close} centered>
  <Modal.Header closeButton>
    <Modal.Title>Edit Company Data</Modal.Title>
  </Modal.Header>
  {
    modalLoading ? <ModalLoading/>
    :
  <>
  <Modal.Body>
     <div style={{color:'red',fontWeight:"bolder"}}>{error}</div>

    <div className="form-group">
      <label htmlFor="CompanyName">Company Name</label>
      <input type="text" className="form-control" id="CompanyName" value={companyName} onChange={(e)=>{setCompanyName(e.target.value)}} />
    </div>

    <div className="form-group">
      <label htmlFor="CompanyDescription">Company Description</label>
      <textarea
                id="companyDescription"
                value={companyDescription}
                onChange={(e) => setCompanyDescription(e.target.value)}
                className="form-control"
                placeholder='company description'
              ></textarea>
    </div>

    <div className="form-group">
      <label htmlFor="mobileNo">Mobile No</label>
      <input type="text" className="form-control" id="mobileNo" value={mobileNo} onChange={(e)=>{setMobileno(e.target.value)}} />
    </div>

    <div className="form-group">
      <label htmlFor="Country">Country</label>
          <select
                type="text"
                id="Country"
                className="form-control"
                value={country} 
                onChange={handleCountryChange}
              >
                <option value="" selected >--Select country--</option>
                {
                  countrylist
                }
          </select> 
    </div>

    <div className="form-group">
      <label htmlFor="State">State</label>
      <select
                type="text"
                id="State"
                className="form-control"
                value={state} 
                onChange={(e)=>{setState(e.target.value)}}
              >
                <option value="" selected >--Select country--</option>
                
                  {states && states.length >0 && states.map(state => (
                    <option key={state} value={state}>{state}</option>
                  ))}               
          </select> 
    </div>

    <div className="form-group">
      <label htmlFor="Pincode">Pincode</label>
      <input type="text" className="form-control" id="Pincode" value={pincode} onChange={(e)=>{setPincode(e.target.value)}} />
    </div>

  </Modal.Body>

  <Modal.Footer>
    <button className="btn btn-dark" onClick={()=>{comapnyupdatehandler()}}>Save</button>
  </Modal.Footer>

  </>
}
</Modal>
 </>
}
</>

     : 

<CreateBussiness user ={user} getuserupdatedata={getuserupdatedata} />

}
</>
}

 </main>


<Footer/>
             
 </>

  )
}
