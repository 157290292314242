import React ,{Fragment,useState,useEffect} from 'react';
import API from "../APIURL";

export default function CartModal({items,currentuser,iscartmodalhandler}) {
    const [cartquantity,setCartQuantity] = useState(items.Quantity != undefined ? items.Quantity : 1);
    const [Cartprice,setCartPrice] = useState(items.ProductPrice);
    const [isloading,setisloading] = useState(false);
    const [error,setError] = useState('');
    // console.log("currentuser",currentuser);

    const AddCartHandler = ()=>{
       setisloading(true);
         let data ={
                   productId:items._id,
                   quantity:parseInt(cartquantity)
                  // quantity:cartquantity
                  }
                  // console.log("data",data);

                  let options = {
                    method: "POST", // It seems you mentioned POST here, but you mentioned PUT in the previous conversation. Make sure it's consistent with your backend route.
                    headers: { "Content-Type": "application/json" }, // Corrected Content-Type
                    body: JSON.stringify(data),
                    credentials: 'include'
                  };
                  

          let output = fetch(`${API}/api/auth/updatecart/${currentuser}`,options);
              output.then(res=>res.json())
                    .then(response=>{
                      // console.log(response);
                      if(response.update)
                      iscartmodalhandler(false);
                      setisloading(false);
                    })
                       

    }
    //  console.log("items",items);

    let totalprice = 0 ;
    const validation = ()=>{
        if(cartquantity !== 0 && Cartprice !== 0 && cartquantity <= items.ProductQuantity){
            setError('')
             return true ;
        }
        else{
         setError( "Min select 1 quantity and max available quantity");
         return false;
          
        }
    
  }
  useEffect(()=>{validation()},[cartquantity,Cartprice,])

  return (
      <Fragment>
           <>
        <div className='blur-background'></div>
        <div className="create-product overlay" style={{padding:"20px",top:"10%",bottom:'auto'}}>
           <div className='my-3 p-3'>
              <h1 className='my-3' style={{padding:"10px",backgroundColor:"white",color:'black'}}>Add To Cart</h1>
               <div style={{fontSize:"16px"}} className='my-5 '>Total Quantity : {items.ProductQuantity} </div>
               <div style={{fontSize:"14px"}} className='my-5 '>
                 <span style={{display: "inline-flex"}} className='my-auto'>
                 <button style={{padding: "10px",fontWeight:"bolder",fontSize:"16px",color:'white',backgroundColor:"black"}} className='btn btn-block'
                 onClick={()=>{
                  setCartQuantity((prevQuantity) => Math.max(prevQuantity - 1, 0));
                  if(cartquantity - 1 > 0){
                  totalprice = (cartquantity - 1) * items.ProductPrice ;
                  setCartPrice(totalprice);
                  }
                  else{
                    setCartPrice(0);
                  }
                 }}
                 >-</button>&nbsp;
                 <input type="text" style={{fontSize:"16px",fontWeight:"bolder"}} value={cartquantity} 
                  onChange={(e)=>{setCartQuantity(e.target.value);
                  totalprice = e.target.value * items.ProductPrice ;
                  setCartPrice(totalprice);}}/> &nbsp;&nbsp;&nbsp;&nbsp;
                 <button style={{padding:"10px",fontWeight:"bolder",fontSize:"16px",color:'white',backgroundColor:"black"}} className='btn btn-block'
                 onClick={()=>{
                  setCartQuantity((prevQuantity) => prevQuantity + 1);
                  totalprice = (cartquantity + 1) * items.ProductPrice ;
                  setCartPrice(totalprice);
                 }}
                 >+</button>
                 </span>
               </div>
               <div className='my-5' style={{fontSize:"14px"}}> Total Price : <span style={{fontSize:"16px"}}> &#8377; {Cartprice} </span> </div>
           </div>
           <hr style={{margin:"10px",color:"white",height:"3px"}} />
           <div className='row'>
             <div className='col-6 text-end  px-0'>
             <button className='btn' style={{backgroundColor:'white',color:"gray"}} onClick={()=>{iscartmodalhandler(false)}}>Close</button>
             </div>
             <div className='col-6 text-start px-0'>
              {
                isloading ? 
                <button className='btn' style={{backgroundColor:'white',color:"black"}} disabled={true} >Loading...</button>
                :
                <button className='btn' style={{fontSize:"12px",
                backgroundColor: error ? 'red' : 'white',
                color: error ? 'black' : 'green', // You can change the text color here if needed
              }}
               disabled={error ? true : false}  onClick={()=>{AddCartHandler()}} >ADD</button>
              }
             
             </div>  
         </div>
        </div>
        </>
      </Fragment>
  )
}
