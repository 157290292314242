import React from 'react'

export default function ModalLoading() {
  return (
    <div className="modal-loading-container">
      <div className="loader"></div>
      <h2 className="loading-text">Updating...</h2>
    </div>
  )
}
