import React, { useState } from "react";
import { Link } from "react-router-dom";
import Socialmedia from "./Socialmedia";
import "./Style.css";
import API from "../APIURL";
import Cookies from 'js-cookie';
import LOGO from "../../Assets/LOGO.png";
import Loading from "../Loading/Loading";
import Header from "./Header";
import Footer from "../Footer/Footer"
// import jwt_decode from "jwt-decode" ;

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error,setError] = useState("");
  const [loading,setLoading] = useState(false);

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    let data = {
               Email:email,
               Password:password 
               }
  let options = {
                method:"POST",
                headers:{"Content-Type":"application/json"},
                body:JSON.stringify(data)
               }
  let output = fetch(`${API}/api/auth/login`,options);
      output.then(res=>res.json())
            .then(response=>{
              // console.log("reaponse",response);
              setLoading(false);
              if(response.login){
                
                // authenticate user and generate JWT token
                 const token = response.token;
                const expires = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
                  // const expires = new Date(new Date().getTime() + 10 * 1000);
                // set a cookie with the token and send response
                  Cookies.set('mycookie', token, {expires , sameSite: 'none', secure: true });
                 if(token){
                    setError("");
                    window.location.reload();
                  }
              }
              else{
                setError(response.message);
              }
              
            })
            
        
    // handle login logic here
  };

  

  const socailloginhandler =(value)=>{
    setLoading(true);
    let data = {
               Email:value.Email,
               Password:value.Password,
               Username:value.Username,
               isAdmin:value.isAdmin 
              };
    let options = {
                   method:"POST",
                   headers:{"Content-Type":"application/json"},
                   body:JSON.stringify(data)
                  }
      let output = fetch(`${API}/api/auth/socialauth`,options);
          output.then(res=>res.json())
                .then(response=>{
                  // console.log(response);
                  setLoading(false);
                  if(response.login){
                   
                    // authenticate user and generate JWT token
                     const token = response.token;
                    const expires = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
                      // const expires = new Date(new Date().getTime() + 10 * 1000);
                    // set a cookie with the token and send response
                      Cookies.set('mycookie', token, {expires , sameSite: 'none', secure: true });
                      // const mytoken = Cookies.get('mycookie');
                      // console.log("torken",mytoken);
                     if(token){
                        // const responsePayload = jwt_decode(mytoken)
                        // console.log("responsePayload",responsePayload);
                        setError("");
                        window.location.reload();
                      }
                  }
                  else{
                     setError(response.message);
                  }

                })
  }

  return (
    <>
    <Header/>
    <main style={{marginTop:"0px",paddingTop:"0px",paddingBottom:"0px",minHeight:"80vh"}}>
    {
      loading ? <Loading/>
      :
      <div className="login-container my-auto">
      <form onSubmit={handleSubmit} className="login-form">
        <p><img src={LOGO} alt="logo" style={{height:"20px"}} /></p>
      
        <h1> Login</h1>
        {error === "" ? <span className="error">{error}</span> :<span className="error">{error}</span> }
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          className="input-field"
          required
        />
        <label>Password:</label>
        <input
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          className="input-field"
          required
        />
        <div>
           <Link to="/forgotpassword" > Forgot Password </Link>
        </div>
        <button type="submit" className="login-btn">
          Login
        </button>
        
        <div className="text-center">
            <p>OR</p>
            <Socialmedia socialmedialoginhandler={socailloginhandler} />
        </div>
        <div className="signup-link">
          Don't have an account? <Link to="/signup">Signup here</Link>
        </div>
      </form>
    </div>
    }
    </main>
    <Footer/>
    </>
    
  );
};

export default LoginPage;
