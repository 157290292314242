import React ,{useState ,useEffect} from 'react';
import "./Homepage.css";
import API from "../APIURL";
import StarRating from '../Landingpage/StarRating';
import background from "../../Assets/cover image.png";
import two from "../../Assets/chole.jpg"
import { Link ,useNavigate } from 'react-router-dom';
import LOGO from "../../Assets/LOGO.png";
import home from "../../Assets/home.png";
import about from "../../Assets/about.png";
import product from "../../Assets/product.png";
import contact from "../../Assets/contact.png";
import login from "../../Assets/login.png";
import Loading from '../Loading/Loading';
import Person1 from "../../Assets/person1.png";
import Person2 from "../../Assets/person2.png";
import Person3 from "../../Assets/person3.png";
import Person4 from "../../Assets/person4.png";
import Person5 from "../../Assets/person5.png";
import Person6 from "../../Assets/person6.png";
import Person7 from "../../Assets/person7.png";
import Person8 from "../../Assets/person8.png";
import Person9 from "../../Assets/person9.png";
import ReactLoading from "react-loading";


function Homepage() {
  const [products,SetProducts] = useState([]);
  const [loading,setLoading] = useState(false);

  useEffect(()=>{GetTopRatedProductHandler()},[]);

  const GetTopRatedProductHandler = ()=>{
    //  console.log("products!!!")
    setLoading(true);
     let output = fetch(`${API}/api/product/get-products/homepage`,{method:"GET"})
         output.then(res=>res.json())
               .then(response=>{
                // console.log(response.data);
                if(response.data){
                 SetProducts(response.data);                
                 setLoading(false)
                }
                else{
                  // CheckCookieAndReload();
                }
                //  console.log("response",response.data);
               })
  }
  
  let navigate = useNavigate();

  const ContactHandler = (e)=>{
    e.preventDefault();
  }

  const [isFavorited, setIsFavorited] = useState(false);

  const toggleFavorite = () => {
    setIsFavorited(!isFavorited);
  };

  return (
    <div className="App">
        <header className='row mx-auto my-auto'>
  <div className="logo col-2 col-md-3 col-lg-3">
    <img src={LOGO}  alt="E-Commerce Website...."/>
  </div>
  <div className='col-1 col-md-3 col-lg-6'></div>
  <div className='col-2 col-md-2 col-lg-1'>
  <a href="#homepage-products" style={{textDecoration:"none"}}>
     <img src={product} alt="Products" style={{width:'30px',height:"30px",borderRadius:'50%',border:"3px solid gray",padding:"5px"}}  /></a>
  </div>
  <div className='col-2 col-md-2 col-lg-1'>
 <a href="#homepage-Contact">
    <img src={contact} alt="homepage-Contact" style={{width:'30px',height:"30px",borderRadius:'50%',border:"3px solid gray",padding:"5px"}}  /></a>
  </div>
  <div className='col-2 col-md-2 col-lg-1'>
   <a href="#"><Link to="/login">
    <img src={login} alt="Login" style={{width:'30px',height:"30px",borderRadius:'50%',border:"1px solid gray",padding:"5px"}}  /></Link></a>
  </div>
  {/* <nav> */}
    {/* <ul> */}
      {/* <li title='Home'><a href="#"><img src={home} alt="home" style={{width:'30px',height:"30px"}} /></a></li> */}
      
      {/* <li title='About US'><a href="#"><img src={about} alt="About Us" style={{width:'30px',height:"30px"}}  /></a></li> */}
    
      {/* <li title='Login'><a href="#"><Link to="/login"><img src={login} alt="Login" style={{width:'30px',height:"30px",borderRadius:'50%',border:"1px solid gray",padding:"5px"}}  /></Link></a></li> */}
    {/* </ul> */}
  {/* </nav> */}
</header>
      {/* <header>
  <div className="logo">
    <img src={LOGO}  alt="My Agriculture Website"/>
  </div>
  <nav>
    <ul>
      <li title='Home'><a href="#"><img src={home} alt="home" style={{width:'30px',height:"30px"}} /></a></li>
      <li title='Products' ><a href="#homepage-products" style={{textDecoration:"none"}}> <img src={product} alt="Products" style={{width:'30px',height:"30px",borderRadius:'50%',border:"3px solid gray",padding:"5px"}}  /></a></li>
      <li title='About US'><a href="#"><img src={about} alt="About Us" style={{width:'30px',height:"30px"}}  /></a></li>
      <li title='Contact'><a href="#homepage-Contact"><img src={contact} alt="homepage-Contact" style={{width:'30px',height:"30px",borderRadius:'50%',border:"3px solid gray",padding:"5px"}}  /></a></li>
      <li title='Login'><a href="#"><Link to="/login"><img src={login} alt="Login" style={{width:'30px',height:"30px",borderRadius:'50%',border:"1px solid gray",padding:"5px"}}  /></Link></a></li>
    </ul>
  </nav>
</header> */}
      <div>
      {/* <section className="cover-section">
      <div className="image-card">
        
          <img src={Person1} alt={`Image ..`} className="arranged-image"  />
          <img src={Person2} alt={`Image ..`} className="arranged-image"  />
          <img src={Person3} alt={`Image ..`} className="arranged-image"  />
          <img src={Person4} alt={`Image ..`} className="arranged-image"  />
          <img src={Person5} alt={`Image ..`} className="arranged-image"  />
          <img src={Person6} alt={`Image ..`} className="arranged-image"  />
           <img src={Person7} alt={`Image ..`} className="arranged-image"  /> 
          <img src={Person8} alt={`Image ..`} className="arranged-image"  />
          <img src={Person9} alt={`Image ..`} className="arranged-image"  />
      </div>
    </section> */}
        <section className="hero">
          <img src={background} alt="Agriculture Image" />
          
        </section>
      
        {/* <section className="products" id="homepage-products" style={{position:"relative"}}> */}
        
          <h2 className='text-center my-3' id="homepage-products">Our Products</h2>
          {  loading ?
             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '30vh' }}>
             <ReactLoading type="spokes" color="green" height={40} width={40} />
             </div>
             :
             <ul className="row mx-3" style={{listStyleType: "none"}} >
           {

           products.length > 0 ?products.map(items=> 
            <li className='col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-3' key={items._id}  >
              <Link to={`/productdetailspage/${items._id}`} style={{textDecorationLine:"none"}} >
      <div className="product-card" style={{position:"relative"}}>
      <img src={items.ProductImages} alt={product.ProductName} />

      <div className="product-details">
        <h2 style={{fontWeight:"bolder"}}>{items.ProductName}</h2>
        <h2  >{ items.ProductDescription.length > 40 ? items.ProductDescription.substring(0, 40) + '...' :  items.ProductDescription }</h2>
        <p className="price" >&#8377; &nbsp;{items.ProductPrice}</p>
        <div className="rating">Rating: <StarRating rating={items.ProductRating}/></div>

        <div className={`favorite ${isFavorited ? 'favorited' : ''}`} onClick={toggleFavorite}  >
         { 
         isFavorited ?
          <i className={`bi bi-heart-fill`} style={{fontSize:"18px"}} />
          :
          <i className={`bi bi-heart`} style={{fontSize:"18px"}} />
         }
        </div>

        <button className="add-to-cart" >
          Add to Cart
        </button>
      </div>
    </div>
      </Link>

           </li>
            // <li>
            //   <Link to={`/productdetailspage/${items._id}`} style={{textDecorationLine:"none"}}>
            //   <img src={items.ProductImages[0]} alt="Product 1" />
            //   <h3>{items.ProductName}</h3>
            //   <p>{ items.ProductDescription.length > 30 ? items.ProductDescription.substring(0, 30) + '...' :  items.ProductDescription }</p>
            //   </Link>
            // </li>
            
          
           )
           :
           null
          }
          </ul>
          }
        {/* </section> */}
        {/* <section className='mt-5 pt-3 mb-3 container card' style={{textAlign:'left'}}>
        <p style={{position:"relative",color:'black',fontSize:"16px",fontWeight:"bolder",textAlign:'left',fontFamily:"sans-serif",fontStyle:"oblique"}} className='my-3'>In today's global economy, businesses must have a strong product marketing strategy that can reach customers from all corners of the world.</p>
        <p style={{position:"relative",color:'black',fontSize:"16px",fontWeight:"bolder",textAlign:'left',fontFamily:"sans-serif",fontStyle:"oblique"}} className='my-3'>Successful product marketing on a worldwide scale requires a balance of global consistency and local relevance.</p>
        </section> */}
        <section className="FAQs my-3 mx-auto row" >
           <h2>FAQS</h2>
           <div className="row">
              <div className="col-12 my-2" >
              <p className='card text-start' data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                <a  data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" style={{textDecoration:"none",color:"black",fontWeight:"bolder",fontSize:"18px"}}>
                  What is meant by Angadi ?
                </a>
 
              </p>
              <div className="collapse" id="collapseExample">
               <div className="card card-body" style={{fontSize:"20px"}}>
               Angadi is an e-commerce platform where sellers can list and sell their products. While it may not be a widely recognized term, it likely refers to an
                online marketplace that enables individuals or businesses to showcase and sell their products to a broader audience.
               </div>
              </div>
              </div>
              <div className="col-12 my-2">
              <p className='card text-start' data-bs-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1">
                <a  data-bs-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1" style={{textDecoration:"none",color:"black",fontWeight:"bolder",fontSize:"18px"}}>
                What kind of products can you sell here?
                </a>
 
              </p>
              <div className="collapse" id="collapseExample1">
               <div className="card card-body" style={{fontSize:"20px"}}>
               You can sell a wide range of products, from agricultural goods to software and everything in between. Our platform is designed to accommodate a diverse array of products, allowing sellers to reach a broad audience regardless of their product category. 
               Whether you're offering physical items, digital products, or services, you can list and sell them on our platform.
               </div>
              </div>
              </div>
           </div>
        </section>
        <section className="contact" id="homepage-Contact">
          <h2>Contact Us</h2>
          <form onSubmit={(e)=>ContactHandler(e)}>
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" required />
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" required></textarea>
            <button type="submit">Send</button>
          </form>
        </section>
      </div>
      <footer>
        <p>Copyright © 2023
          <a href="#">Privacy Policy</a> |
          <a href="#">Terms of Use</a>
        </p>
      </footer>
    </div>
  );
}

export default Homepage;
