import React  from 'react'
import API from "../APIURL";

export default function PaymentTransaction({totalPrice,user,messagehandler,transactionhandler}) {
    //    console.log(user);
    const loadscript = (rzscript)=>{
        return new Promise((resolve)=>{
                                      let script =document.createElement('script');
                                      script.src = rzscript;
                                      script.onload = ()=>{
                                                          openrazerpay(); 
                                                          resolve(true)
                                                          }
                                      script.onerror=()=>{
                                                         resolve(false)
                                                         }
                                     document.body.appendChild(script);
                                     });
                                  }

      const openrazerpay = async()=>{

        try{
           let orderData;
           orderData = await fetch(`${API}/api/payment/createorder/${user.id}`,{method:'POST',
           headers :{'Content-Type' : 'application/json'},
           body:JSON.stringify({amount:totalPrice}),
           credentials: 'include' })
           .then(response=>response.json());
        //   console.log("order data",orderData);
        const options={
                      key :"rzp_test_BsGSW4LpkffpL9",
                      amount: orderData.amount,
                      order_id: orderData.id,
                      curreny:orderData.curreny,
                      name : "Sairun ",

                      prefill:{
                              email:"history@gmail.com",
                              contact:"677-875-9456"
                              },
                      handler:function(response){
                        let data = {
                          razorpay_orderid: response.razorpay_order_id,
                          razorpay_paymentid : response.razorpay_payment_id,
                          razorpay_signature: response.razorpay_signature ,
                          razorpay_amount:orderData.amount,                          
                        }
                        let TransactionID = response.razorpay_payment_id ; 
                        // console.log("TransactionID",TransactionID);
                        // setTransactionID(response.razorpay_order_id);
                        // console.log('response : ',response.razorpay_order_id);
                        fetch(`${API}/api/payment/savetransaction/:id`,{method:'POST',
                                                                      headers:{"Content-Type":"application/json"},
                                                                       body:JSON.stringify(data),
                                                                      credentials: 'include'
                                                                    }).then(response=>response.json())
                                                                     .then(data=>{
                                                                        // console.log(data);
                                                                        // console.log("TransactionID",TransactionID);
                                                                        transactionhandler(TransactionID,"online",true);
                                                                        
                                                                    });
                      }        




    //                  
                     }
        const paymentWindow = new window.Razorpay(options);
        paymentWindow.open()
        }
        catch(error){
                    console.log(error);
                    }

      }
  return (
    <div onClick={()=>{loadscript('https://checkout.razorpay.com/v1/checkout.js')}}>Pay Now</div>
  )
}
