import React, { useState } from "react";
import "./LandingPage.css";

function SearchBox({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
    
      
         {/* <div className="search-container">
          <div className="row">
            <div className="col-3">
            <input
        type="text"
        className="search-input"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
        <span className="search-button"  onClick={()=>{handleSearch()}}>
          <i className="bi bi-search my-auto" style={{fontSize:'20px',fontWeight:"bolder",padding:"10px"}}></i>
          </span>
            </div>
           
          </div>
       
         </div>              */}
         <header className="row container-fluid mx-auto" style={{zIndex:"1"}}>
              <div>Filter</div>
         </header>  
      
      

        
       
     
    
  
    </>
  );
}

export default SearchBox;
