import React,{useState} from 'react';
import Avator from "../../Assets/Avator.png";

const statusColors = {
    notProcessed: { backgroundColor: '#0000FF', color: '#FFFFFF' },
    processing: { backgroundColor: '#FFA500', color: '#000000' },
    shipped: { backgroundColor: '#008000', color: '#FFFFFF' },
    delivered: { backgroundColor: '#800080', color: '#FFFFFF' },
    cancelled: { backgroundColor: '#FF0000', color: '#FFFFFF' },
    returned: { backgroundColor: '#FFD700', color: '#000000' },
  };
  
  const statusStyle = {
    display: 'inline-block',
    padding: '5px 10px',
    margin: '5px',
    fontWeight: 'bold',
    borderRadius: '5px',
  };

function Table({myorders,handleUpdateModal}) {
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [returnedOrders, setReturnedOrders] = useState([]);

  const handleCancelOrder = (orderId) => {
    // Logic to cancel the order and update the state
    setCancelledOrders([...cancelledOrders, orderId]);
  };

  const handleReturnOrder = (orderId) => {
    // Logic to initiate a return for the order and update the state
    setReturnedOrders([...returnedOrders, orderId]);
  };
  
  // console.log("myorders",myorders);

  return (
    <div className="orders-table-container container card ">
      <table className="orders-table" >
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Amount</th>
            <th>Quantity</th>
            <th>Transaction ID</th>
            <th>Payment</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <>
        {myorders && myorders.length > 0 ? (
  myorders.map((order,idx) =>(  
    <tbody className='mx-auto my-3 tbody-3d-card' key={`inenenen${idx}`}>
      
    <tr key={order.id} style={{ fontSize: "25px", fontWeight: "bolder" }} title="Product ordered User" className='mt-3' >
             <td className="mx-auto text-danger text-start " colSpan="8">
              <div className='row'>
                 <div className="col-2 col-md-2 col-lg-1 col-xl-1 my-auto">
                 { order.buyerDetails.Photo === "Avator" ?
                  <img src={Avator} alt="photo.." style={{width:"20px",height:"20px",borderRadius:"50%"}} />
                 :
                  <img src={order.buyerDetails.Photo} alt="photo.." style={{width:"20px",height:"20px",borderRadius:"50%"}} />
                  }
                  </div>
                
                 <div className="col-10 col-md-10 col-lg-11 col-xl-11 my-auto">{order.buyerDetails.Username} </div>
              </div>
                
             </td>
            </tr>
            <tr  style={{ fontSize: "25px", fontWeight: "bolder" }}>
            <td className="mx-auto text-success text-start " colSpan="8">
               <div style={{ fontSize: "12px", fontWeight: "bolder" }}><i className="bi bi-geo-alt-fill" style={{color:"red",fontSize:"15px"}}></i> &nbsp; &nbsp; {order.buyerDeliveryDetails.address} &nbsp; &nbsp; <i className="bi bi-telephone-fill" style={{color:"red",fontSize:"15px"}}></i>  <span  className='text-primary' style={{fontSize:"12px"}}>{order.buyerDeliveryDetails.phoneNo}</span> </div>
            </td>
            </tr>
    {
    order.products.map((items,index) =>
    <tr key={`fkvnfvnf${index}`} style={{ fontSize: "25px", fontWeight: "bolder" }}>
          {items.productDetails ? (
            <>
              <td className="order-image">
                <img src={items.productDetails.ProductImages[0]} alt={items.productDetails.ProductName} />
              </td>
              <td>{items.productDetails.ProductName}</td>
              <td style={{ color: "green" }}>&#8377; {items.productTotalPrice}</td>
              <td>{items.quantity}</td>
              <td>{order.paymentDetails.TransactionID}</td>
              <td>
                {order.paymentDetails.isPaid ? <div style={{ color: "green" }}>Paid</div> : <div style={{ color: "red" }}>Not Paid</div>}
              </td>
              <td>
                {items.Status === "Not processed" && (
                  <div style={{ ...statusStyle, ...statusColors.notProcessed }}>Not Processed</div>
                )}
                {items.Status === "Processing" && (
                  <div style={{ ...statusStyle, ...statusColors.processing }}>Processing</div>
                )}
                {items.Status === "Shipped" && (
                  <div style={{ ...statusStyle, ...statusColors.shipped }}>Shipped</div>
                )}
                {items.Status === "Delivered" && (
                  <div style={{ ...statusStyle, ...statusColors.delivered }}>Delivered</div>
                )}
                {items.Status === "Cancelled" && (
                  <div style={{ ...statusStyle, ...statusColors.cancelled }}>Cancelled</div>
                )}
                {items.Status === "Returned" && (
                  <div style={{ ...statusStyle, ...statusColors.returned }}>Returned</div>
                )}
              </td>
              <td>
                <div className="action-buttons">
                <div className='' >
                  {
                    items.Status === "Delivered" ?<i className="bi bi-check-all" style={{cursor:"pointer",color:"green",fontSize:"18px",fontWeight:"bolder"}} ></i>:<>{items.Status === "Cancelled" ?<i className="bi bi-x" style={{cursor:"pointer",color:"red",fontSize:"18px",fontWeight:"bolder"}} ></i>:<>{items.Status === "Returned" ?<i className="bi bi-arrow-return-left" style={{cursor:"pointer",color:"green",fontSize:"18px",fontWeight:"bolder"}} ></i> : <i className="bi bi-pen-fill" style={{cursor:"pointer"}} onClick={()=>{handleUpdateModal(items,true)}}></i>}</> }</> 
                  }
                   
              </div>
                </div>
              </td>
            </>
          ) : (
          
            // <tr key={items.id} style={{ fontSize: "25px", fontWeight: "bolder" }}>
            <td className="mx-auto text-danger text-center " colSpan="8">
             Seller Delete this product from Angadi
              
            </td>
          // </tr>
          )}
        </tr>
      )
      }
      
      </tbody>
  ))
    )
 : (
  <tr>
    <td colSpan="8" className="mx-auto  text-center" style={{ fontSize: "25px", fontWeight: "bolder",color:"red" }}>
      No Orders
    </td>
  </tr>
)}


        </>
      </table>
      </div>
  );
}

export default Table;
