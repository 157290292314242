import React, { useEffect, useState ,Fragment } from "react";
import Header from "../Header/Header";
import SubHeader from "../Aside/SubHeader";
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode" ;
import Footer from "../Footer/Footer";
import ProductCard from "./ProductCard";
import ProductDetailsShown from "./ProductDetailsShown";
import API from "../APIURL";
import Loading from "../Loading/Loading";
import {CheckCookieAndReload} from "../CookieChecker";

export default function Wishlist() {
    // get user data from cookies
  const token = Cookies.get('mycookie');
  let responsePayload ;
  if(token != undefined){
    responsePayload = jwt_decode(token);
  }

 const [user,setUser] = useState(responsePayload ||'');
 const [isproductdisplay,setIsproductdisplay] = useState(false);
 const [viewdata,setViewdata] = useState("");
 const [loading,setLoading] = useState(false);
 const [wishList,setWishlist] = useState([]);

 const popuphandler= () =>{
    setIsproductdisplay(!isproductdisplay);
}
const datahandler =(data)=>{
setViewdata(data);
}



useEffect(()=>{
  GetWishlistHandler();              
},[]);

const GetWishlistHandler = ()=>{
    // console.log("userid",user.id);
    setLoading(true);
  let output = fetch(`${API}/api/product/GetWishList/${user.id}`,{method:"GET",credentials: 'include'})
              .then(res=>res.json())
      output.then(response=>{
        console.log("response",response);
        if(response.data){
                   setWishlist(response.data);
                   setLoading(false);
      }
      else{
        CheckCookieAndReload();
      }
                                 
      })
}

  return (
    <Fragment>
        <Header/>
        <SubHeader/>
        <main>
          {
             loading ? <Loading/>
             :
             <>
             {

             
            isproductdisplay ? <ProductDetailsShown popuphandler={popuphandler} isbtndisplay={true} items={viewdata} currentuser={user.id}  />
            :
            <>
             <h1 className="mx-auto my-5">My Wishlist</h1>
             <ul className="row mx-3" style={{listStyleType: "none"}}>
       
             {
              wishList.length > 0 ? wishList.map((items,index)=>(
            
                    <ProductCard items = {items} popuphandler={popuphandler} datahandler={datahandler} currentuser={user.id}  />
              ))
               : 
              <div style={{color:"red",fontWeight:"bolder",fontSize:'15px'}} className="col-12 text-center">No Data Found</div>
            }
      
           </ul>
           </>
            }
           </>
          }
        
        </main>
        <Footer/>
    </Fragment>
  )
}
