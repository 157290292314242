import React,{Fragment, useState} from 'react';
import Returnicon from "../../Assets/Return.png";
import API from "../APIURL";
import ReactLoading from "react-loading";
import Avator from "../../Assets/Avator.png";

const statusColors = {
    notProcessed: { backgroundColor: '#0000FF', color: '#FFFFFF' },
    processing: { backgroundColor: '#FFA500', color: '#000000' },
    shipped: { backgroundColor: '#008000', color: '#FFFFFF' },
    delivered: { backgroundColor: '#800080', color: '#FFFFFF' },
    cancelled: { backgroundColor: '#FF0000', color: '#FFFFFF' },
    returned: { backgroundColor: '#FFD700', color: '#000000' },
  };
  
  const statusStyle = {
    display: 'inline-block',
    padding: '5px 10px',
    margin: '5px',
    fontWeight: 'bold',
    borderRadius: '5px',
  };

function Table({ myorders , userID ,GetOrdersForCurrentuser }) {
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [returnedOrders, setReturnedOrders] = useState([]);
  const [isReturnModel,setIsReturnModal] = useState(false);
  const [reasonForReturnOrder,setReasonForReturnOrder] = useState('');
  const [isbtnreturnloading,setIsbtnReturnloading]  =useState(false);
  const [isCancelModel,setIsCancelModal] = useState(false);
  const [isbtnCancelloading,setIsbtnCancelloading]  =useState(false);
  const [actiondata,setActionData] = useState('');
  const [orderDetails,setOrderdetails] = useState('');

  const handleCancelOrder = (e) => {
    e.preventDefault();
    
    setIsbtnReturnloading(true);

    let data = {
      SellerID:orderDetails.sellerDetails._id,
      ProductID:actiondata.productDetails._id,
      SubOrderID:orderDetails.subOrderId,
     };

console.log("data",data);

let Options = {
          method:"POST",
          headers:{"Content-Type":"application/json"},
          body:JSON.stringify(data),
          credentials:"include"
         };

fetch(`${API}/api/order/cancelorder/${userID}`,Options)
.then(res=>res.json())
.then(response=>{
// console.log("response",response);
 setIsCancelModal(false);
 GetOrdersForCurrentuser();
 setIsbtnReturnloading(false);
})

  };

  const handleReturnOrder = (e) => {
        e.preventDefault();
        setIsbtnReturnloading(true);
       // console.log("actiondata",actiondata);
       // console.log("orderdetails",orderDetails);
       let data = {
                 Reason:reasonForReturnOrder,
                 SellerID:orderDetails.sellerDetails._id,
                 ProductID:actiondata.productDetails._id,
                 SubOrderID:orderDetails.subOrderId,
                };

      // console.log("data",data);

      let Options = {
                     method:"POST",
                     headers:{"Content-Type":"application/json"},
                     body:JSON.stringify(data),
                     credentials:"include"
                    };

      fetch(`${API}/api/returnrequest/createrequest/${userID}`,Options)
      .then(res=>res.json())
      .then(response=>{
          // console.log("response",response);
          if(response.request){
            setIsReturnModal(false);
            GetOrdersForCurrentuser();
          }
          else{

          }
          setIsbtnReturnloading(false);
      })
    // Logic to initiate a return for the order and update the state
    // setReturnedOrders([...returnedOrders, orderId]);
  };

  return (
    <Fragment>
      {
         isReturnModel ?
        <Fragment>
        <div className='blur-background'></div>
        <div className="overlay mx-auto my-auto" style={{width:"320px",height:"350px",backgroundColor:"white",borderRadius:"7px"}}>
        <div className='row container my-3 p-0' >
          <div className='col-12 text-end' style={{fontWeight:"bolder",fontSize:"15px",cursor:'pointer'}} onClick={()=>setIsReturnModal(false)}>X</div>
        </div >
            <h1 > Return Orders  </h1>
            <form className='row container  mx-auto' onSubmit={(e)=>handleReturnOrder(e)}>

            
           <p >Why you Return this order ?</p>
           <textarea name="" id="" cols="30" rows="10" required placeholder='Give a Reason for Return Order' onChange={(e)=>{setReasonForReturnOrder(e.target.value);}}></textarea>
           
           <button type="submit" className='btn btn-success btn-block my-3' style={{backgroundColor:"green",color:"white"}}>
            {isbtnreturnloading ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ReactLoading type="spin" color="white" height={30} width={30} />
            </div>
            :
            "Send Request"
             }
            </button>
           </form>
        </div>
        </Fragment>
        :
        null
}
{
         isCancelModel ?
        <Fragment>
        <div className='blur-background'></div>
        <div className="overlay mx-auto my-auto" style={{width:"320px",height:"250px",backgroundColor:"white",borderRadius:"7px"}}>
        <div className='row container my-3 p-0' >
          <div className='col-12 text-end' style={{fontWeight:"bolder",fontSize:"15px",cursor:'pointer'}} onClick={()=>setIsCancelModal(false)}>X</div>
        </div >
            <h1 > cancel Orders  </h1>
            <form className='row container  mx-auto' onSubmit={(e)=>handleCancelOrder(e)}>

            <div className='col-12'>
           <p >Do you want to Cancel the Order ? </p>
           </div>
           {/* <textarea name="" id="" cols="30" rows="10" required placeholder='Give a Reason for Cancel Order'></textarea> */}
           <div className='col-12'>
            <div className="row">
           <input type="checkbox" name="" id="" style={{width:"30px",height:"30px"}} className='col-4 text-start my-3' required /> <span className='my-auto col-8' style={{fontWeight:"bolder",fontSize:"14px"}}>Select Box</span>
           </div>
           </div>
            
           <button type="submit" className='btn btn-success btn-block my-3' style={{backgroundColor:"red",color:"white"}}>
            {
            isbtnreturnloading ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <ReactLoading type="spin" color="white" height={30} width={30} />
            </div>
            :
            "Cancel Order" 
            }
            </button>
           </form>
        </div>
        </Fragment>
        :
        null
}
    <div className="orders-table-container container card ">
      <table className="orders-table" >
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Amount</th>
            <th>Quantity</th>
            <th>Transaction ID</th>
            <th>Payment</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <>
        {myorders && myorders.length > 0 ? (
  myorders.map((order,idx) =>(  
    <tbody className='mx-auto my-3 tbody-3d-card' key={`fivivn${idx}`}>
      
    <tr key={order.id} style={{ fontSize: "25px", fontWeight: "bolder" }} className='mt-3' >
             <td className="mx-auto text-danger text-start " colSpan="8">
              <div className='row'>
                 <div className="col-2 col-md-2 col-lg-1 col-xl-1 my-auto">
                 { order.sellerDetails.Logo === "Avator" ?
                  <img src={Avator} alt="photo.." style={{width:"20px",height:"20px",borderRadius:"50%"}} />
                 :
                  <img src={order.sellerDetails.Logo} alt="photo.." style={{width:"20px",height:"20px",borderRadius:"50%"}} />
                  }
                  </div>
                 <div className="col-10 col-md-10 col-lg-11 col-xl-11 my-auto">{order.sellerDetails.CompanyName}</div>
              </div>
                
             </td>
            </tr>
    {
    order.products.map((items,index) =>(
      <tr key={`fkvnfvnf${index}`} style={{ fontSize: "25px", fontWeight: "bolder" }}>
          {items.productDetails ? (
            
            <>
              <td className="order-image">
                <img src={items.productDetails.ProductImages[0]} alt={items.productDetails.ProductName} />
              </td>
              <td>{items.productDetails.ProductName}</td>
              <td style={{ color: "green" }}>&#8377; {items.productTotalPrice}</td>
              <td>{items.quantity}</td>
              <td>{order.paymentDetails.TransactionID}</td>
              <td>
                {order.paymentDetails.isPaid ? <div style={{ color: "green" }}>Paid</div> : <div style={{ color: "red" }}>Not Paid</div>}
              </td>
              <td>
                {items.Status === "Not processed" && (
                  <div style={{ ...statusStyle, ...statusColors.notProcessed }}>Not Processed</div>
                )}
                {items.Status === "Processing" && (
                  <div style={{ ...statusStyle, ...statusColors.processing }}>Processing</div>
                )}
                {items.Status === "Shipped" && (
                  <div style={{ ...statusStyle, ...statusColors.shipped }}>Shipped</div>
                )}
                {items.Status === "Delivered" && (
                  <div style={{ ...statusStyle, ...statusColors.delivered }}>Delivered</div>
                )}
                {items.Status === "Cancelled" && (
                  <div style={{ ...statusStyle, ...statusColors.cancelled }}>Cancelled</div>
                )}
                {items.Status === "Returned" && (
                  <div style={{ ...statusStyle, ...statusColors.returned }}>Returned</div>
                )}
              </td>
              <td>
                <div className="action-buttons">
                  {items.Status === "Not processed" ||
                  items.Status === "Processing" ||
                  items.Status === "Shipped" ||
                  items.Status === "Cancelled" ? (
                    <>
                      {items.Status === "Cancelled" ? (
                        <div className="text-danger"  title="Cancel Order">
                           Cancel Order
                        </div>
                      ) : (
                        <button className="btn btn-dark btn-block "  onClick={() => {setIsCancelModal(true);setActionData(items);setOrderdetails(order)}} title="Cancel Order">
                          X
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                    {items.returnRequestStatus ? (
                     <>
                      {items.returnRequestStatus === "Under Review" ? <div className='text-warning'>Under Review</div> : <>{items.returnRequestStatus === "Accepted"? <div className='text-success'>Accepted</div>:<div className='text-danger'>Rejected</div> }</>}
                     </>
                    ) : (
                      <button className="btn btn-dark btn-block" onClick={() => {setIsReturnModal(true);setActionData(items); setOrderdetails(order);}} title="Return Order">
                           <img src={Returnicon} alt="return icon..." style={{width:'25px',height:"25px"}} />
                      </button>
                    )}
                  </>

                    
                  )}
                </div>
              </td>
            </>
          ) : (
            
            // <tr key={items.id} style={{ fontSize: "25px", fontWeight: "bolder" }}>
              <td className="mx-auto text-danger text-center " colSpan="8">
              Seller Delete this product from Angadi
                
              </td>
            // </tr>
          )}
        </tr>
    )
      )
      }
      
      </tbody>
  ))
    )
 : (
  <tr>
    <td colSpan="8" className="mx-auto  text-center" style={{ fontSize: "25px", fontWeight: "bolder",color:"red" }}>
      No Orders
    </td>
  </tr>
)}


        </>
      </table>
      </div>

    </Fragment>
  );
}

export default Table;
