import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode" ;
import "./LandingPage.css";
import Footer from "../Footer/Footer";
import SearchBox from "./SearchPage";
import ProductCard from "./ProductCard";
import ProductDetailsShown from "./ProductDetailsShown";
import SubHeader from "../Aside/SubHeader";
import API from "../APIURL";
import Loading from "../Loading/Loading";
import { CheckCookieAndReload } from "../CookieChecker";
import ReactLoading from "react-loading";
import Productcard from "../Design/productdesgin";

 

// main part all css style contain LandingPage.css 
function LandingPage() {

   // get user data from cookies
  const token = Cookies.get('mycookie');
  let responsePayload ;
  if(token != undefined){
    responsePayload = jwt_decode(token);
  }

 const [user,setUser] = useState(responsePayload ||'');
 const [isproductdisplay,setIsproductdisplay] = useState(false);
 const [viewdata,setViewdata] = useState("");
 const [products , SetProducts] = useState([]);
 const [loading,setLoading] = useState(false);
 const [filteredProducts,setFilteredProducts] = useState('');


useEffect(()=>{
      CheckCookieAndReload();
      GetAllProductHandler();
},[])

const GetAllProductHandler = ()=>{
  //  console.log("products!!!")
  setLoading(true);
   let output = fetch(`${API}/api/product/Get-All-Products/${user.id}`,{method:"GET",credentials: 'include'})
       output.then(res=>res.json())
             .then(response=>{
              if(response.data){
               SetProducts(response.data);
               setFilteredProducts(response.data);
               setLoading(false)
              }
              else{
                
                CheckCookieAndReload();
              }
              //  console.log("response",response.data);
             })
}

// filter products by search keywords
   function searchProducts(query) {
      const results = [];
      for (let i = 0; i < products.length; i++) {
        const product = products[i];
        if (product.ProductName.toLowerCase().includes(query.toLowerCase()) ||
            product.ProductDescription.toLowerCase().includes(query.toLowerCase()) ||
            product.State.toLowerCase().includes(query.toLowerCase()) ||
            product.Country.toLowerCase().includes(query.toLowerCase())) {
            results.push(product);
        }
      }
   //  console.log("results",results);
    setFilteredProducts(results);
    }

    const [searchValue, setSearchValue] = useState('');

    function handleSearchChange(event) {
      setSearchValue(event.target.value);
    }

    const popuphandler= () =>{
            setIsproductdisplay(!isproductdisplay);
    }
    const datahandler =(data)=>{
      setViewdata(data);
    }

  return (
    <>
   <Header/>
   <SubHeader/>
   <main>
      {
         isproductdisplay ? <ProductDetailsShown popuphandler={popuphandler} isbtndisplay={true} items={viewdata} currentuser={user.id}  /> :
          
 loading ?
     <Loading/>
      :
     <>
     <div className="my-3">
         <SearchBox value={searchValue} onChange={handleSearchChange} onSearch={searchProducts} />
     </div>

     {/* <ul className="row mx-3" style={{listStyleType: "none"}}>
       
        {
         filteredProducts.length > 0 ? filteredProducts.map((items,index)=>(
         <li className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 my-3 shop-card' key={items._id}>
               <ProductCard items = {items} popuphandler={popuphandler} datahandler={datahandler} currentuser={user.id} />
         </li> ))
          : 
         <div style={{color:"red",fontWeight:"bolder",fontSize:'15px'}} className="col-12 text-center">No Data Found</div>
       }

      </ul> */}
       
       {/* <section className="products" id="homepage-products" style={{position:"relative"}}> */}
         
       <ul className="row mx-3" style={{listStyleType: "none"}}>
       
        {
         filteredProducts.length > 0 ? filteredProducts.map((items,index)=>(
          
          
        
               <ProductCard items = {items} popuphandler={popuphandler} datahandler={datahandler} currentuser={user.id} key={items._id}/>
               
          ))
          : 
         <div style={{color:"red",fontWeight:"bolder",fontSize:'15px'}} className="col-12 text-center">No Data Found</div>
       }

</ul>
          
          {/* </section> */}
      
     </>
 
      }
     
   </main>
      <Footer/>
   </>
  );
}

export default LandingPage;
