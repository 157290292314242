import React from 'react';
import {Link} from "react-router-dom"

export default function Verify() {
  return (
    <div className="container">
    <h1 className="text-center my-4">Account Confirmation</h1>
    <div className="row">
      <div className="col-md-8 mx-auto">
        <div className="card border-success mb-3">
          <div className="card-header bg-success text-white">Your Account is Verified Successfully</div>
          <div className="card-body">
            <p className="card-text">Your account is verified Successfully Please Continue with Login</p>
          </div>
        </div>
        <div className="text-center">
          <Link to="/login" className="btn btn-primary mt-3">Back to Login</Link>
        </div>
      </div>
    </div>
  </div>
  )
}
