import React ,{useState ,useEffect, Fragment ,useRef} from 'react';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode" ;
import API from '../../Components/APIURL';
import { CheckCookieAndReload} from "../../Components/CookieChecker";
import Header from "../Header/Header";
import Footer from "../../Components/Footer/Footer";
import photo from "../../Assets/Bussiness.png";
import Table from './Table';
import Loading from '../../Components/Loading/Loading';
import ReactLoading from "react-loading";
import "./style.css";

const statusColors = {
  notProcessed: { backgroundColor: '#0000FF', color: '#FFFFFF' },
  processing: { backgroundColor: '#FFA500', color: '#000000' },
  shipped: { backgroundColor: '#008000', color: '#FFFFFF' },
  delivered: { backgroundColor: '#800080', color: '#FFFFFF' },
  cancelled: { backgroundColor: '#FF0000', color: '#FFFFFF' },
  returned: { backgroundColor: '#FFD700', color: '#000000' },
};

const statusStyle = {
  display: 'inline-block',
  padding: '5px 10px',
  margin: '5px',
  fontWeight: 'bold',
  borderRadius: '5px',
};

const tabs = [
  'Not Processed',
  'Processing',
  'Shipped',
  'Delivered',
  'Cancelled',
  'Returned',
];

export default function Orders() {
     // get user data from cookies
  const token = Cookies.get('mycookie');
  let responsePayload ;
  if(token != undefined){
    responsePayload = jwt_decode(token);
  }

 const [user,setUser] = useState(responsePayload ||'');
 const [orders,setOrders] = useState([]);
 const [cancelledOrders, setCancelledOrders] = useState([]);
 const [returnedOrders, setReturnedOrders] = useState([]);
 const [isOrderUpdate,setIsOrderUpdate] = useState(false);
 const [updatedata,setUpdatedata] = useState('');
 const [isloading,setIsloading] = useState(false);
 const [editStatus,setEditStatus] = useState('');
 const [subOrderId,setSuborderid] = useState('');
 const [btnloading,setBtnloading] = useState(false);
 const [activeTab, setActiveTab] = useState('Not Processed');
//  const [otp, setOTP] = useState(['', '', '', '', '', '']); // Initialize state for all six digits
 const [otp,setOTP] = useState(0);
 const [disabledverifybtn,setDisabledVerifybtn] = useState(true);
 const [isVerified,setIsVerified] = useState(true);
 const [verifiedbtnloading,setverifyBtnloading] = useState(false);
 const [otperror,setOtpError] = useState(null);
  const inputRefs = useRef([]);


  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

    useEffect(()=>{
      GetOrderHandler();
   },[])

   const GetOrderHandler = ()=>{
    setIsloading(true);
    fetch(`${API}/api/order/getordersbysellerid/${user.id}`,{method:"GET",credentials:"include"})
     .then(res=>res.json())
     .then(response=>{
       if(response.subOrders){
        //  console.log("response data",response.subOrders);
         setOrders(response.subOrders);
       }
       else{
         CheckCookieAndReload();
       }
       setIsloading(false)
       
   })
   }

   

  // Event handler to update the OTP state when a digit is entered or deleted
  const handleDigitChange = ( value) => {
    setOtpError(null);
    // const newOTP = [...otp]; // Create a copy of the current OTP state
    // newOTP[index] = value; // Update the digit at the specified index
    setOTP(value); // Update the OTP state
    // console.log("length",otp.length);
    // Check if the OTP has 6 digits and trigger the submit function
  
  
    // if (value.length < 6 ) {
      // Move to the next input box when a digit is entered
      // inputRefs.current[index + 1].focus();
    // } else if (!value && index > 0) {
      // Move to the previous input box when a digit is deleted
      // inputRefs.current[index - 1].focus();
    // }

    if (value.length === 6) {
      // handleSubmitOTP();
      setDisabledVerifybtn(false);
    }
    else{
      setDisabledVerifybtn(true);
    }
  };


  useEffect(()=>{
    if(editStatus === "Delivered"){
         setDisabledVerifybtn(true);
    }
    else{
      setDisabledVerifybtn(false);
    }
  },[editStatus])
  

  // Event handler to handle OTP submission
  const handleSubmitOTP = () => {
    // const enteredOTP = otp.join('');
    // console.log('Submitted OTP:', enteredOTP);
    setverifyBtnloading(true);
    let data ={
              otp:otp,
              subOrderId:subOrderId,
             
    }
    let options = {
               method:"POST",
               headers:{"Content-Type":"application/json"},
               body:JSON.stringify(data),
               credentials:"include"
    };
    //  console.log("Status",data);
    fetch(`${API}/api/order/verify/delivery-order/${user.id}`,options)
    .then(res=>res.json())
    .then(response=>{
      // console.log("response",response);
      if(response.isVerifed){
        GetOrderHandler();
        setIsOrderUpdate(false);
        setIsVerified(response.isVerifed);
        SaveHandler();  
        setOTP(0); 
        setOtpError(null);     
      }
      else{
        setOtpError('Mismatch OTP , Please Provide correct one');
      }
      setverifyBtnloading(false);
    });


    // You can perform further actions with the entered OTP here
  };
   const handleCancelOrder = (orderId) => {
    // Logic to cancel the order and update the state
    setCancelledOrders([...cancelledOrders, orderId]);
  };

  const handleReturnOrder = (orderId) => {
    // Logic to initiate a return for the order and update the state
    setReturnedOrders([...returnedOrders, orderId]);
  };

  const SaveHandler = ()=>{
    //  console.log("Status",updatedata);
     setBtnloading(true);
     let data ={
               status:editStatus,
               subOrderId:subOrderId,
               productId:updatedata.productDetails._id
     }
     let options = {
                method:"POST",
                headers:{"Content-Type":"application/json"},
                body:JSON.stringify(data),
                credentials:"include"
     };

    //  console.log("Status",data);
      fetch(`${API}/api/order/sub-orders/${user.id}/products`,options)
      .then(res=>res.json())
      .then(response=>{
        GetOrderHandler();
        setBtnloading(false);
        setIsOrderUpdate(false);
      });
    
  }
  // console.log("EDIT data",updatedata);
  return (
    <Fragment>
    <Header/>
    <main style={{minHeight:"85vh",marginTop: '70px'}}>
    <h1>MyOrders</h1>
    
    {/* <div className="tabs-container">
      <div className="tabs-row">
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`tab ${activeTab === tab ? 'active-tab' : ''}`}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </div>
        ))}
      </div>
      <div className="tab-content">
        
        {activeTab === 'Not Processed' && <div>Content for Not Processed Tab</div>}
        {activeTab === 'Processing' && <div>Content for Processing Tab</div>}
        {activeTab === 'Shipped' && <div>Content for Shipped Tab</div>}
        {activeTab === 'Delivered' && <div>Content for Delivered Tab</div>}
        {activeTab === 'Cancelled' && <div>Content for Cancelled Tab</div>}
        {activeTab === 'Returned' && <div>Content for Returned Tab</div>}
      </div>
    </div> */}

    {isloading ? 
    <Loading/>
    :

    <>
    <Table myorders={orders} handleUpdateModal={(data,isorderupdate)=>{setUpdatedata(data);setIsOrderUpdate(isorderupdate);setEditStatus(data.Status);setSuborderid(data.subOrderId)}}/>
    
    
  

{
  isOrderUpdate ?
  <>
  <div className='blur-background'></div>
        <div className="create-product overlay ">
           <h1>Update Order</h1>
           <div className='card'>
            <div className="row text-dark container">
              <div className="col-12 my-2"><i className="bi bi-person-fill"></i>  &nbsp; <span> Jeevanantham S </span></div>
              <div className="col-12 my-2"><i className="bi bi-pin-fill"> </i>&nbsp; <span>1/ 67, periyapallipalyam , trichy - 621207</span> </div>
              <div className="col-12 my-2"><i className="bi bi-phone-fill"></i>&nbsp; <span>6385229170</span></div>
            </div>
           </div>
           <div className="card">
                <div className="row ">
                    <div className='col-4 text-center my-auto'>
                    {
                    updatedata.productDetails.ProductImages && updatedata.productDetails.ProductImages.length > 0 && <img src={updatedata.productDetails.ProductImages[0]} alt="product img..." style={{width:"100%",height:"auto",borderRadius:"7px"}} />
                    }
                    </div>
                    <div className='col-8 text-dark'>
                       <div  style={{color:"black",fontWeight:"bolder",fontSize:"14px"}} >{updatedata.productDetails.ProductName}</div>
                       <div  style={{color:"black",fontWeight:"bolder",fontSize:"14px"}} >{updatedata.productDetails.ProductDescription.slice(0,50)} ...</div>
                       <div  style={{color:"black",fontWeight:"bolder",fontSize:"14px"}} >
                  Quantity : <span style={{color:"green",fontWeight:"bolder",fontSize:"14px"}}>&nbsp;  3 </span>
               </div>
               <div  style={{color:"black",fontWeight:"bolder",fontSize:"14px"}} >
                  Amount : <span style={{color:"green",fontWeight:"bolder",fontSize:"14px"}}>&nbsp;  $ 100</span>
               </div>
                       <div  style={{color:"black",fontWeight:"bolder",fontSize:"14px"}} >
                  Payment : <span style={{color:"green",fontWeight:"bolder",fontSize:"14px"}}>&nbsp;  COD</span>
               </div>
                
                    </div>
                </div>
           </div>
            
            
          
           <div className='dashboard'>
           <label htmlFor="status" className='my-3' style={{fontSize:"14px"}}>&nbsp; Status</label>
           <select id="status" style={{cursor:"pointer"}} value={editStatus}  onChange={(e)=>setEditStatus(e.target.value)}>

                <option style={{ ...statusStyle, ...statusColors.notProcessed }} className='card' value="Not Processed">Not Processed</option>
              
             
                <option style={{ ...statusStyle, ...statusColors.processing }} className='card' value="Processing">Processing</option>
              
            
                <option style={{ ...statusStyle, ...statusColors.shipped }} className='card' value="Shipped">Shipped</option>
              
             
                <option style={{ ...statusStyle, ...statusColors.delivered }} className='card' value="Delivered">Delivered</option>
              
             
                {/* <option style={{ ...statusStyle, ...statusColors.cancelled }} className='card' value="Cancelled">Cancelled</option> */}
              
             
                {/* <option style={{ ...statusStyle, ...statusColors.returned }} className='card' value="Returned">Returned</option> */}
              
           </select>
          

           </div>
           {
            isVerified ?
           <>
           {
            editStatus === "Delivered" 
            ?
           <div className='row my-3 py-3'>
           <div className='col-12'>
      <label htmlFor='otp'>Verify OTP</label>
        {otperror ? <div className='text-danger my-3' style={{fontSize:"15px",fontWeight:"bolder"}}>{otperror}</div> : null } 
      <div className="row">
        <div className="col-8">
        <input
         
         type='text'
       
         maxLength='6'
         // value={digit}
         onChange={(e) => {
           handleDigitChange(e.target.value);                   
         }}
        
       />
        </div>
        <div className="col-4">
        &nbsp;&nbsp;<button style={{padding:"5px",fontSize:"12px",height:"30px"}} disabled={disabledverifybtn} className='my-auto btn btn-primary' onClick={handleSubmitOTP}>{ verifiedbtnloading?<ReactLoading type="spin" color="white" height={20} width={20} />:"Verify" }</button>
      
        </div>
      </div>
      {/* <div >
        
          <input
         
          type='text'
        
          maxLength='6'
          // value={digit}
          onChange={(e) => {
            handleDigitChange(e.target.value);                   
          }}
         
        />
        
        
        
        
        &nbsp;&nbsp;<button style={{padding:"5px",fontSize:"12px",height:"30px"}} disabled={disabledverifybtn} className='my-auto btn btn-primary' onClick={handleSubmitOTP}>{ verifiedbtnloading?<ReactLoading type="spin" color="white" height={20} width={20} />:"Verify" }</button>
      </div> */}
     
    </div>

           </div>
           :
           null
}
</>
:
<div className='text-success btn '> Verified Successfully </div>
}
          
           <div style={{position:"absolute",top:'90%',left : '30%',alignItems:"center"}}>
            <div className="row text-center" >
              <div className="col-6 text-end">
                <button className='btn btn-dark' onClick={()=>{setIsOrderUpdate(false);setOTP(['', '', '', '', '', ''])}}  disabled={btnloading || verifiedbtnloading} style={{cursor:btnloading ?"not-allowed" :'pointer'}} > Close </button>
              </div>
              <div className="col-6 text-start">
              <button className='btn btn-success'onClick={()=>SaveHandler()} disabled={btnloading || disabledverifybtn || verifiedbtnloading} style={{cursor:btnloading ?"not-allowed" :'pointer'}} >{ btnloading ? <ReactLoading type="spin" color="white" height={30} width={30} /> :"Save"} </button>
              </div>
            </div>
            </div>
        </div>
        </>
        :
        ""
}
</>
}

    </main> 
    <Footer/>
    </Fragment>
  )
}
