import React ,{useState} from 'react';
import "./Subheading.css";
import { Link ,NavLink } from "react-router-dom";
import close from "../../Assets/close.png";
import logout from "../../Assets/logout.png";
import home from "../../Assets/home.png";
import profileimg from "../../Assets/profileimage.jpg";
import LOGO from "../../Assets/LOGO.png";
import settings from "../../Assets/settings.png";
import Landing from "../../Assets/Landingicon.png";
import Bussiness from '../../Assets/Bussiness.png';
import ShopImage from "../../Assets/Shop.png";
import MyorderImage from "../../Assets/MyOrders.png";

export default function SubHeader() {
      
  const navButtons = [
    // { id: 0, label: 'Home', path:"/",icon:home },
    { id: 1, label: 'Shop', path:"/landingpage" ,icon:ShopImage },
    { id: 2, label: 'Account', path:"/profile" ,icon:profileimg },
    // { id: 3, label: 'Bussiness', path:"/bussiness" ,icon:Bussiness },
    { id: 4, label: 'MyOrders', path:"/myorders" ,icon:MyorderImage },
    { id: 5, label: 'Settings', path:"/settings" ,icon:settings },
   

    
    
  ];

      
  return (
    <> 
    <div className="sub-header" style={{height:"60px"}}>
     
     <nav  style={{height:"20px"}}>
      <ul>
        {
           navButtons.map(button=><li style={{height:"20px"}} key={button.id}>
            <NavLink exact activeClassName='active' to={button.path} >
                    <div className='nav-link'>
                      <div className='nav-link-icon'>
                        <img
                          src={button.icon}
                          alt={button.label}
                          style={{
                            cursor: 'pointer',
                            borderRadius: '50%',
                          }}
                        />
                      </div>
                      <div className='nav-link-text'>{button.label}</div>
                    </div>
            </NavLink>
              
            </li>)
        }
               
      </ul>
    </nav>
    
  
   </div>
     </>
  )
}