import React ,{useState , useEffect ,useRef} from 'react';
import './Header.css';
import { Link, NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import Modal from "react-modal";
import {  CheckCookieAndReload} from "../CookieChecker";
import home from "../../Assets/home.png";
import LOGO from "../../Assets/LOGO.png";
// import LOGO from "../../Assets/Predicter11.png"
import settings from "../../Assets/settings.png";
import menu from "../../Assets/menu.png";
import notification from "../../Assets/notification.png";
import profileimg from "../../Assets/profileimage.jpg";
import Backbutton from "../../Assets/Backbutton.png";
import logout from "../../Assets/logout.png";
import close from "../../Assets/close.png";
import Aside from '../Aside/Aside';
import SubHeader from '../Aside/SubHeader';
import jwt_decode from "jwt-decode" ;
import API from "../APIURL";
import Avator from "../../Assets/Avator.png";
import MyOrders from "../../Assets/MyOrders.png";
import business from "../../Assets/Bussiness.png";
import { useMyCookie } from "../Context/Cookie";
import { encode, decode } from 'base-64';

// import { useUser } from '../Context/UserContext';



const customStyle ={
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
    zIndex: '4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  
  content: {
    top: '9%',
    left: 'auto',
    right: '2%',
    bottom: 'auto',
    width: '250px',
    position: 'fixed',
    backgroundColor: 'white', // You can set the desired background color here
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#root');


function Header(props) {
  
  const { token } = useMyCookie();
    const [ myaccountmodal ,setMyaccountmodal ] = useState(false);
    const [asidetoggle , setAsidetoggle] = useState(false);
    const [cartcount,setCartCount] = useState(0);
    // const token = Cookies.get('mycookie');
    let responsePayload ;
    if(token != undefined){
    responsePayload = jwt_decode(token);
    // console.log("tokens",responsePayload);
    }
   const [user,setUser] = useState(responsePayload ||'');
   const [currentUser , setCurrentUser] = useState('')

  //  const { userData } = useUser();
  //  setCurrentUser(userData);
  // console.log("user data" ,userData);
  // console.log("Current user",currentUser);
 
   
    useEffect(()=>{
      //  if(userData !== null){
      //   setCurrentUser(userData)
      //  }
      
      let output = fetch(`${API}/api/auth/getuser/${user.id}`,{method:"GET",credentials: 'include'});
          output.then(res=>res.json())
                 .then(result=>{
                  //  console.log("result",result);
                    if(result.data){
                      setCurrentUser(result.data);
                    }
                    else{
                      CheckCookieAndReload();
                    }
                             
                 })
                 GetCartProductHandler();
     },[])

     const GetCartProductHandler = ()=>{
      
       let output = fetch(`${API}/api/product/get-cart-data/${user.id}`,{method:"GET",credentials: 'include'});
           output.then(res=>res.json())
                 .then(response=>{
                   if(response.cart)
                      setCartCount(response.cart.length);
                    else  
                      CheckCookieAndReload();
                 })
    }
  


  const handleLogout = () => {
    // remove cookie
   Cookies.remove('mycookie');
   window.location.reload();
};


const AsideOnOff = ()=>{
     setAsidetoggle(!asidetoggle)
}

 
const encodedId = encode(user.id);
// Now, you can pass `encodedId` in your route parameters.


  return (
    <>
    <header className='row header container-fluid mx-auto py-0 pt-3' style={{height:"60px"}}>
      <div className='col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 text-end px-0 ' >
        <img style={{cursor:"pointer"}}  src={menu} className='my-auto'  alt="menu"  title='menu' onClick={()=>AsideOnOff()}/>
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 40 40"
          title='menu' 
          onClick={()=>AsideOnOff()}
          style={{cursor:"pointer"}}
        >
        <rect width="40" height="4" rx="2" fill="#333" />
        <rect y="18" width="40" height="4" rx="2" fill="#333" />
        <rect y="36" width="40" height="4" rx="2" fill="#333" />
       </svg> */}
      </div>
      <div className='col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 text-start px-0 ' style={{height:"30px"}}><Link to="/"><img src={LOGO} style={{height:"30px",cursor:"pointer"}}   alt="Sairun"  /></Link></div>
      <div className='col-3 col-sm-3 col-md-2 col-lg-4 col-xl-4'></div>
      <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' style={{height:"30px"}}><Link to="/wishlist"><i className="bi bi-heart-fill"style={{fontSize:"25px",cursor:"pointer",height:"30px",color:'#e91e63'}}></i></Link></div>
      <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' style={{height:"30px"}}><Link to="/mycart" style={{textDecoration:"none"}}><i className="bi bi-cart-check-fill" style={{fontSize:"25px",cursor:"pointer",height:"30px",color:'black',position:"relative"}}>
     
     {
       cartcount ?
      <sup style={{
      position: "absolute",
      top: "-1px", // Adjust this value to position the <sup> element
      right: "-20px", // Adjust this value to position the <sup> element
      fontSize: "12px",
      backgroundColor: "red", // Add a background color for the <sup> element
      color: "white", // Add a text color for the <sup> element
      borderRadius: "50%", // To make the <sup> element circular
      padding: "6px 6px",
      fontWeight:"bolder" // Add padding to make it visually appealing
       }} >{ cartcount > 10 ? <>10+</> : cartcount }</sup>
       :
       ""
      }</i></Link></div>
      <div className='col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1' style={{height:"30px"}}><i className="bi bi-bell-fill" style={{fontSize:"25px",cursor:"pointer",height:"30px",color:'gold',position:"relative"}} title='Notification'>
      {
       true ?
      <sup style={{
      position: "absolute",
      top: "-1px", // Adjust this value to position the <sup> element
      right: "-20px", // Adjust this value to position the <sup> element
      fontSize: "12px",
      backgroundColor: "red", // Add a background color for the <sup> element
      color: "white", // Add a text color for the <sup> element
      borderRadius: "50%", // To make the <sup> element circular
      padding: "6px 6px", // Add padding to make it visually appealing
      fontWeight:"bolder",
       
       }} >10 +</sup>
       :
       ""
      }
        </i>
        </div>
      <div className='col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1 px-0' style={{height:"30px",width:'30px'}}>{currentUser && currentUser.Photo === "Avator" ? <img src={Avator} className='image' style={{cursor: 'pointer',height:"30px",width:'30px',borderRadius:"50%"}}  alt="account" title="Myaccount" onClick={()=>setMyaccountmodal(true)} />: <img src={currentUser.Photo} className='image' style={{cursor: 'pointer',height:"30px",width:'30px',borderRadius:"50%"}}  alt="account" title="Myaccount" onClick={()=>setMyaccountmodal(true)} />}</div>
      <div className='col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1'></div>
    </header>
        
    {asidetoggle ? <Aside asidetoggle={asidetoggle} AsideOnOff={AsideOnOff} key={asidetoggle}  /> : "" }
    {/* <SubHeader/> */}
   
     {/* My account Modal */}
     <Modal isOpen={myaccountmodal} style={customStyle} >
       <div className='container-fluid mx-auto sidemodal'>
          <img src={Backbutton} style={{width:"30px",heigth:"30px",padding:"0px",cursor:"pointer"}} alt='backbutton'  onClick={()=>setMyaccountmodal(false)} />
          <br/>
           <div className='row my-3 menu'>
                 <div className='col-4 text-end  my-auto'><Link to="/profile">{currentUser.Photo === "Avator" ?<img src={Avator} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer',borderRadius:"50%"}}   alt="account"/> : <img src={currentUser.Photo} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer',borderRadius:"50%"}}   alt="account"/>} </Link></div>
                 <div className='col-8 text-start  my-auto' style={{cursor:"pointer"}}><NavLink activeClassName='active' to="/profile" style={{textDecoration:"none",fontWeight:"bolder",padding:'5px'}}> My Profile </NavLink> </div>
           </div>
           <hr style={{color:"gray",height:"2px"}} />
           <div className='row my-3 menu'>
                 <div className='col-4 text-end my-auto'><Link to={`/Mybusiness`} style={{textDecoration:"none",color:"black",fontWeight:"bolder"}} ><img src={business} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer'}}   alt="My Business" /></Link></div>
                 <div className='col-8 text-start  my-auto'  style={{cursor:"pointer"}}> <NavLink activeClassName='active' to="/Mybusiness" style={{textDecoration:"none",fontWeight:"bolder",padding:'5px'}} > My Business </NavLink> </div>
           </div>
           <div className='row my-3 menu'>
                 <div className='col-4 text-end my-auto'><Link to="/dashboard" style={{textDecoration:"none",color:"black",fontWeight:"bolder"}} ><i className="fa fa-dashboard" style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer',fontSize:"25px",color:"brown"}}    /> </Link></div>
                 <div className='col-8 text-start  my-auto'  style={{cursor:"pointer"}}> <NavLink activeClassName='active' to="/dashboard" style={{textDecoration:"none",fontWeight:"bolder",padding:'5px'}} > Dashborad </NavLink> </div>
           </div>
           <div className='row my-3 menu'>
                 <div className='col-4 text-end  my-auto'><Link to="/wishlist" style={{textDecoration:"none",color:"black",fontWeight:"bolder"}} ><i className="fa fa-heart" style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer',fontSize:"25px",color:"red"}}    /></Link> </div>
                 <div className='col-8 text-start  my-auto'  style={{cursor:"pointer"}}> <NavLink activeClassName='active' to="/wishlist" style={{textDecoration:"none",fontWeight:"bolder",padding:'5px'}}>Wishlist</NavLink>  </div>
           </div>  
           <div className='row my-3 menu'>
                 <div className='col-4 text-end  my-auto'><Link to="/mycart" style={{textDecoration:"none",color:"black",fontWeight:"bolder"}} ><i className="bi bi-cart-check-fill" style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer',fontSize:"25px",color:"gold"}}   alt="My Cart" /></Link> </div>
                 <div className='col-8 text-start  my-auto'  style={{cursor:"pointer"}}> <NavLink activeClassName='active' to="/mycart" style={{textDecoration:"none",fontWeight:"bolder",padding:'5px'}}> My Cart </NavLink> </div>
           </div>
           <div className='row my-3 menu'>
                 <div className='col-4 text-end  my-auto'><Link to="/myorders" style={{textDecoration:"none",color:"black",fontWeight:"bolder"}} ><img src={MyOrders} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer'}}   alt="My Orders" /></Link> </div>
                 <div className='col-8 text-start  my-auto'  style={{cursor:"pointer"}}> <NavLink activeClassName='active' to="/myorders" style={{textDecoration:"none",fontWeight:"bolder",padding:'5px'}}>My Orders</NavLink>  </div>
           </div>           
           <div className='row my-3 menu'>
                 <div className='col-4 text-end  my-auto'><img src={logout} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer'}}   alt="lock account"   onClick={()=>{handleLogout()}}/> </div>
                 <div className='col-8 text-start  my-auto' onClick={()=>{handleLogout()}} style={{cursor:"pointer",color:"black",fontWeight:"bolder"}}> Lock Account </div>
           </div>
           
       </div>
    </Modal>
       
    </>
   
  );
}

export default Header;
