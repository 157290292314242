import React ,{Fragment , useState} from 'react';
import UpdateProduct from './CreateProduct';
import API from '../APIURL';
import { CheckCookieAndReload} from "../CookieChecker";
import {Link} from "react-router-dom"
// let img = [productimg1,productimg2,productimg3]

export default function Productcard(props) {
  
  const [IsdeleteModel,setIsdeleteModal]  = useState(false); 
  const [isdeletebtnloading,setIsdeletebtnloading] = useState(false);
  const [deleteproducterror,setDeleteProductError]  = useState('');

  const DeleteProductHandler = (e) =>{
    e.preventDefault();
    setIsdeletebtnloading(true);
     fetch(`${API}/api/product/Delete-Product/${props.items._id}/${props.currentuser._id}`,{method:"POST",credentials: 'include'})
     .then(res=>res.json())
     .then(result=>{
            // console.log("result",result);
          if(result.delete){
            props.triggergetproducthandler();
            setIsdeletebtnloading(true);
          }
          else{
            CheckCookieAndReload();
            setDeleteProductError(result.message);
          
          }
     })
  }
  return (
   <Fragment>
    {
      IsdeleteModel ? 
    <Fragment>
        <div className='blur-background'></div>
        <div className="overlay mx-auto my-auto" style={{width:"320px",height:"300px",backgroundColor:"white",borderRadius:"7px"}}>
        <div className='row container my-3 p-0' >
          <div className='col-12 text-end' style={{fontWeight:"bolder",fontSize:"15px",cursor:'pointer'}} onClick={()=>setIsdeleteModal(false)}>X</div>
        </div>
            <h1 className='row container my-3'>
               Delete Product
            </h1>
            <div className="row mx-auto">
              <div style={{color:"red",fontWeight:"bolder",}}>{deleteproducterror}</div>
            </div>
            <div className='row container my-3' >
               Delete Product , once you delete the product its affect you orders display data .
            </div>
            <form onSubmit={(e)=>{DeleteProductHandler(e)}}>
            <div className='container'>
               <input type="checkbox" required style={{width:"20px",height:"20px"}}/>
            </div>
            <div className='row my-3 container' style={{position:"absolute",bottom:"0px",left:"35%"}}>
              <div className="col-12">
                <button type="submit" style={{backgroundColor:"red"}} className="btn btn-danger btn-block"> Delete </button>
              </div>
            </div>
            </form>
        </div> 
    </Fragment>
        :
        null
        }
   
    <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 my-3' >
        <div className="card ">
        <div className='row'>

            <div className='col-5 col-md-4 col-lg-4 col-xl-4 text-start my-auto'>
                 <img src={props.items.ProductImages[0]} alt="productimg" style={{width:"100px",height:'100px',borderRadius:"7px"}} className='my-auto' />
           </div>

           <div className='col-7 col-md-8 col-lg-8 col-xl-8 '>
              <p className='text-start my-2' style={{fontWeight:"bolder",fontSize:"10px"}}>{props.items.ProductName}</p>
              <p className='text-start my-2' style={{fontWeight:"bolder",fontSize:"10px"}}>
              {
               props.items.ProductDescription.length <= 50 ?
                                                           props.items.ProductDescription
                                                           :     
                                                        `${props.items.ProductDescription.slice(0, 50)}...`
              }
              </p>
              <p className='text-start my-2' style={{fontWeight:"bolder",fontSize:"10px"}}>Qauntity :<span style={{fontWeight:"bolder",fontSize:"12px",color:"green"}}> &nbsp; {props.items.ProductQuantity} {props.items.ProductQuantityType} </span> </p>
              <p className='text-start my-2' style={{fontWeight:"bolder",fontSize:"10px"}}>Price per pieces :<span style={{fontWeight:"bolder",fontSize:"12px",color:"green"}}> &#8377; {props.items.ProductPrice}  </span> </p>              
              <p className='text-start my-2' style={{fontWeight:"bolder",fontSize:"10px"}}> <i className="bi bi-geo-alt-fill" style={{color:"red"}} ></i> {props.items.State} , {props.items.Country} - {props.items.Pincode} </p>
           </div>

           <div className='row my-3'>
            <div className='col-4 text-center' title='View Product' ><Link to={`/productdetailspage/${props.items._id}`}><i className='bi bi-eye-fill' style={{color:"Gold",fontWeight:"bolder",fontSize:"18px"}}></i></Link></div>
            <div className='col-4 text-center' title='Edit Product'> <UpdateProduct currentUser={props.currentuser} updateproduct={true} items={props.items} triggergetproducthandler={props.triggergetproducthandler} /></div>
            <div className='col-4 text-center' title='Delete Product'><div onClick={()=>{setIsdeleteModal(true)}}><i className="bi bi-trash3-fill" style={{color:"red",fontWeight:"bolder",fontSize:"18px",cursor:"pointer"}}></i></div></div>         
           </div>
           
        </div>  
        </div>
      
                  
    </div>
    

     
   </Fragment>
  )
}
