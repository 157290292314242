import React from 'react'

export default function StarRating({ rating }) {

const filledStars = Math.floor(rating); // Number of filled stars
const hasHalfStar = rating % 1 !== 0; // Check if there is a half-filled star

const renderStars = () => {
  const stars = [];

  // Render filled stars
  for (let i = 0; i < filledStars; i++) {
    stars.push(<i key={i} className="bi bi-star-fill star "></i>);
  }

  // Render half-filled star if applicable
  if (hasHalfStar) {
    stars.push(<i key="half" className="bi bi-star-half star" ></i>);
  }

  // Render empty stars
  for (let i = filledStars + (hasHalfStar ? 1 : 0); i < 5; i++) {
    stars.push(<i key={i} className="bi bi-star star " ></i>);
  }

  return stars;
};

return <div>{renderStars()}</div>;

}
