import React ,{useState , useEffect ,useRef} from 'react';
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import Modal from "react-modal";
import {  CheckCookieAndReload } from "../../Components/CookieChecker";
import LOGO from "../../Assets/LOGO.png";
import menu from "../../Assets/menu.png";
import Backbutton from "../../Assets/Backbutton.png";
import logout from "../../Assets/logout.png";
import jwt_decode from "jwt-decode" ;
import API from "../../Components/APIURL";
import Avator from "../../Assets/Avator.png";
import Shop from "../../Assets/Shop.png";
import Aside from '../Aside/Aside';


// import { useUser } from '../Context/UserContext';



const customStyle ={
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
    zIndex: '4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  
  content: {
    top: '9%',
    left: 'auto',
    right: '2%',
    bottom: 'auto',
    width: '250px',
    position: 'fixed',
    backgroundColor: 'white', // You can set the desired background color here
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');


function Header(props) {

    const [ myaccountmodal ,setMyaccountmodal ] = useState(false);
    const [asidetoggle , setAsidetoggle] = useState(false);
    const [cartcount,setCartCount] = useState(0);
    const token = Cookies.get('mycookie');
    let responsePayload ;
    if(token != undefined){
    responsePayload = jwt_decode(token);
    // console.log("tokens",responsePayload);
    }
   const [user,setUser] = useState(responsePayload ||'');
   const [currentUser , setCurrentUser] = useState('')

  //  const { userData } = useUser();
  //  setCurrentUser(userData);
  // console.log("user data" ,userData);
  // console.log("Current user",currentUser);
 
   
    useEffect(()=>{
      //  if(userData !== null){
      //   setCurrentUser(userData)
      //  }
      
      let output = fetch(`${API}/api/auth/getuser/${user.id}`,{method:"GET",credentials: 'include'});
          output.then(res=>res.json())
                 .then(result=>{
                  //  console.log("result",result);
                    if(result.data){
                      setCurrentUser(result.data);
                    }
                    else{
                      CheckCookieAndReload();
                    }
                             
                 })
                 GetCartProductHandler();
     },[])

     const GetCartProductHandler = ()=>{
      
       let output = fetch(`${API}/api/product/get-cart-data/${user.id}`,{method:"GET",credentials: 'include'});
           output.then(res=>res.json())
                 .then(response=>{
                   if(response.cart)
                      setCartCount(response.cart.length);
                    else  
                      CheckCookieAndReload();
                 })
    }
  


  const handleLogout = () => {
    // remove cookie
   Cookies.remove('mycookie');
   window.location.reload();
};


const AsideOnOff = ()=>{
     setAsidetoggle(!asidetoggle)
}


  return (
    <>
    <header className='row header container-fluid mx-auto py-0' style={{height:"60px"}}>
      <div className='col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 text-end px-0 ' >
        {/* <img style={{cursor:"pointer"}}  src={menu} className='my-auto'  alt="menu"  title='menu' onClick={()=>AsideOnOff()}/> */}
        <svg
         xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
        viewBox="0 0 40 40"
        title='menu' 
        onClick={()=>AsideOnOff()}
        style={{cursor:"pointer"}}
        >
      <rect width="40" height="4" rx="2" fill="#333" />
      <rect y="18" width="40" height="4" rx="2" fill="#333" />
      <rect y="36" width="40" height="4" rx="2" fill="#333" />
       </svg>

        </div>
      <div className='col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 text-start px-0 ' style={{height:"30px"}}><Link to="/"><img src={LOGO} style={{height:"30px",cursor:"pointer"}}   alt="Sairun"  /></Link></div>
      <div className='col-3 col-sm-3 col-md-2 col-lg-4 col-xl-4'></div>
      <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' style={{height:"30px"}}>
        {/* <Link to="/wishlist"><i className="bi bi-heart-fill"style={{fontSize:"25px",cursor:"pointer",height:"30px",color:'gold'}}></i></Link> */}
      </div>
      <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' style={{height:"30px"}}>
        {/* <Link to="/mycart" style={{textDecoration:"none"}}><i className="bi bi-cart-check-fill" style={{fontSize:"25px",cursor:"pointer",height:"30px",color:'black',position:"relative"}}>
     
     {
       cartcount ?
      <sup style={{
      position: "absolute",
      top: "-1px", // Adjust this value to position the <sup> element
      right: "-20px", // Adjust this value to position the <sup> element
      fontSize: "12px",
      backgroundColor: "red", // Add a background color for the <sup> element
      color: "white", // Add a text color for the <sup> element
      borderRadius: "50%", // To make the <sup> element circular
      padding: "6px 6px",
      fontWeight:"bolder" // Add padding to make it visually appealing
       }} >{ cartcount > 10 ? <>10+</> : cartcount }</sup>
       :
       ""
      }</i></Link> */}
      </div>
      <div className='col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1' style={{height:"30px"}}>
        <i className="bi bi-bell-fill" style={{fontSize:"25px",cursor:"pointer",height:"30px",color:'gold',position:"relative"}} title='Notification'>
      {
       true ?
      <sup style={{
      position: "absolute",
      top: "-1px", // Adjust this value to position the <sup> element
      right: "-20px", // Adjust this value to position the <sup> element
      fontSize: "12px",
      backgroundColor: "red", // Add a background color for the <sup> element
      color: "white", // Add a text color for the <sup> element
      borderRadius: "50%", // To make the <sup> element circular
      padding: "6px 6px", // Add padding to make it visually appealing
      fontWeight:"bolder",
       
       }} >10 +</sup>
       :
       ""
      }
        </i>
        </div>
      <div className='col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1 px-0' style={{height:"30px",width:'30px'}}>{currentUser && currentUser.Logo === "Logo" ? <img src={Avator} className='image' style={{cursor: 'pointer',height:"30px",width:'30px',borderRadius:"50%"}}  alt="account" title="Myaccount" onClick={()=>setMyaccountmodal(true)} />: <img src={currentUser.Logo} className='image' style={{cursor: 'pointer',height:"30px",width:'30px',borderRadius:"50%"}}  alt="account" title="Myaccount" onClick={()=>setMyaccountmodal(true)} />}</div>
      <div className='col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1'></div>
    </header>
        
    {asidetoggle ? <Aside asidetoggle={asidetoggle} AsideOnOff={AsideOnOff} key={asidetoggle}  Logo={currentUser.Logo} /> : "" }
    {/* <SubHeader/> */}
   
     {/* My account Modal */}
     <Modal isOpen={myaccountmodal} style={customStyle}>
       <div className='container-fluid mx-auto'>
          <img src={Backbutton} style={{width:"30px",heigth:"30px",padding:"0px",cursor:"pointer"}} alt='backbutton'  onClick={()=>setMyaccountmodal(false)} />
          <br/>
           <div className='row my-3'>
                 <div className='col-4 text-end'><Link to="/Mybusiness">{currentUser.Logo === "Logo" ?<img src={Avator} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer',borderRadius:"50%"}}   alt="account"/> : <img src={currentUser.Logo} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer',borderRadius:"50%"}}   alt="account"/>} </Link></div>
                 <div className='col-8 text-start my-3' style={{cursor:"pointer"}}><Link to="/Mybusiness" style={{textDecoration:"none"}}> My Business </Link> </div>
           </div>
           <hr style={{color:"gray",height:"2px"}} />
           <div className='row my-3'>
                 <div className='col-4 text-end'> <Link to="/landingpage" style={{textDecoration:"none",color:"black"}}><img src={Shop} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer'}}   alt="My Shop" /></Link></div>
                 <div className='col-8 text-start my-3'  style={{cursor:"pointer"}}> <Link to="/landingpage" style={{textDecoration:"none",color:"black"}}> My Shop </Link> </div>
           </div>
           {/* <div className='row my-3'>
                 <div className='col-4 text-end'><img src={MyOrders} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer'}}   alt="My Orders" /> </div>
                 <div className='col-8 text-start my-3'  style={{cursor:"pointer"}}> <Link to="/myorders" style={{textDecoration:"none",color:"black"}}>My Orders</Link>  </div>
           </div> */}
           <div className='row my-3'>
                 <div className='col-4 text-end'> <Link to="/landingpage" style={{textDecoration:"none",color:"black"}}><img src={logout} style={{width:"30px",heigth:"30px",padding:"0px",cursor: 'pointer'}}   alt="lock account"   onClick={()=>{handleLogout()}}/> </Link> </div>
                 <div className='col-8 text-start my-3' onClick={()=>{handleLogout()}} style={{cursor:"pointer"}}> Lock Account </div>
           </div>
           
       </div>
    </Modal>
       
    </>
   
  );
}

export default Header;
