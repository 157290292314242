import React, { useState } from 'react';
import jwt_decode from "jwt-decode" ;
import {GoogleOAuthProvider,GoogleLogin} from '@react-oauth/google';

export default function Socialmedia(props) {
      const [email,setEmail] = useState('');
      const [password,setPassword] = useState('');
      const [username,setUsername] = useState('');
      

    //google response login
const responseGoogle = (response) => {
    // console.log(response);
    const responsePayload = jwt_decode(response.credential);
    const {email,name,sub,email_verified }=responsePayload;
    // console.log("response",responsePayload);
    const req = {Email:email,
                 Username:name,
                 Password:sub,
                 isAdmin:email_verified            
                }
    props.socialmedialoginhandler(req);    
    } 

 
  return (

        <GoogleOAuthProvider clientId="356946187029-nadsvaicg1nu0jrrotfb5ntqkckdbpj9.apps.googleusercontent.com">
        <GoogleLogin
        onSuccess={responseGoogle}
        onError={() => {
                       console.log('Login Failed');
                      }}             
        />
      </GoogleOAuthProvider>
      
  )
}
