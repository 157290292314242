import React, { Fragment , useEffect, useId, useState } from 'react';
import StarRating from "./StarRating";
import Loading from "../Loading/Loading";
import img1 from "../../Assets/profileimage.jpg";
import API from "../APIURL";
import OrderNowModal from './OrderNowModal';
import CartModal from './CartModal';
import {CheckCookieAndReload} from "../CookieChecker";
import ReactLoading from "react-loading";
import { Link } from 'react-router-dom';
import Avator from "../../Assets/Avator.png";

// Star Rating
function CustomStarRating({ rating, onRatingChange }) {
  const stars = [1, 2, 3, 4, 5];

  return (
    <div className="star-rating">
      {stars.map((star) => (
        <span
          key={star}
          className={`star ${star <= rating ? 'selected' : ''}`}
          onClick={() => onRatingChange(star)}
        >
         <span style={{fontSize:"35px",cursor:"pointer"}}>{star <= rating ? '★' : '☆'}</span>
        </span>
      ))}
    </div>
  );
}


export default function Popup({popuphandler,items,currentuser,isbtndisplay}) {
  const [currentImage, setCurrentImage] = useState(0);
  const [displaymodal,setDisplayModal] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [companyDetails,setComapanyDetails] = useState("");
  const [isLoading,setIsLoading] =useState(false);
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wishList,setWishlist] = useState([]);
  const [isopencartmodal,setISOpencartmodal] = useState(false);
  const [isOpenOrderNowModal,setIsOpenOrderNowModal] = useState(false);
  const [ isbuyproduct,setIsBuyProduct] = useState(false);
  const [productReviews,setProductReviews] = useState([]);
  const [isReviewloading,setIsReviewLoading] = useState(false);
  const [isSubmitloading,setIsSubmitLoading] = useState(false);
  const [error,setError] = useState('');
   

  useEffect(()=>{
    GetWishlistHandler(); 
    CheckUserBuyaProduct();
    GetProductReviews();             
  },[]);

  const GetWishlistHandler = ()=>{
    let output = fetch(`${API}/api/auth/getwishlist/${currentuser}`,{method:"GET",credentials: 'include'})
                .then(res=>res.json())
        output.then(response=>{
          // console.log("response",response);
                     setWishlist(response.WishList);
        })
  }
  const CheckUserBuyaProduct=()=>{
       fetch(`${API}/api/order/checkuserbuyproduct/${currentuser}/${items._id}`,{method:"GET",credentials: 'include'})
           .then(res=>res.json())
           .then(response=>{
              //  console.log("response",response);
               setIsBuyProduct(response.IsBuy)
           });
  }
  const GetProductReviews=()=>{
    setIsReviewLoading(true);
    fetch(`${API}/api/productReview/product-reviews/${items._id}/${currentuser}`,{method:"GET",credentials: 'include'})
        .then(res=>res.json())
        .then(response=>{
            // console.log("response",response);
            setProductReviews(response.data);
            setIsReviewLoading(false);
        });
}
  
  // let images = [];
  let images = [...items.ProductImages];
  // console.log("video",items.ProductVideo);
  if (items.ProductVideo)
     images = images.concat(items.ProductVideo);
  // console.log("images",images);

  const goToNextImage = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const goToPreviousImage = () => {
    setCurrentImage((prevImage) => (prevImage - 1 + images.length) % images.length);
  };
  // console.log("Images" , images);

  const handleThumbnailClick = (index) => {
    setCurrentImage(index);
  };
  const CloseHandler = ()=>{
    setDisplayModal(false);
  }

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const getDescriptionToShow = () => {
    if (showFullDescription) {
      return items.ProductDescription;
    } else {
      return items.ProductDescription.slice(0, 200);
    }
  };

  const showMoreButton = items.ProductDescription.length > 200 && !showFullDescription && (
    <span className='show-more-btn' onClick={toggleDescription}>Show More </span>
  );

  const showLessButton = items.ProductDescription.length > 200 && showFullDescription && (
    <span className='show-less-btn' onClick={toggleDescription}>Show Less </span>
  );

  

  

  // Make API calls
  // get comapany logo and name
  useEffect(()=>{
    setIsLoading(true);
    let data={  id:items.CompanyID  };
    let options = {
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify(data),
      credentials: 'include'
      };
     
    let output = fetch(`${API}/api/auth/companylogoname/${currentuser}`,options);
        output.then(res=>res.json())
              .then(response=>{
                //  console.log("response",response);
                if(response.data){
                 setComapanyDetails(response.data[0]);
                 setIsLoading(false);
                }
                else{
                  CheckCookieAndReload();
                }
              })
  },[])

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setComment('');
    setRating(0);
    setError('');
  };

  // Review section
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleRatingChange = (value) => {
    setRating(Number(value));
  };

  const AddReviewhandler = (event) => {
    event.preventDefault();
    setIsSubmitLoading(true);
    let data ={
               ProductID:items._id,
               Comment:comment,
               Rating:rating      
              };
              // console.log(currentuser);
    let options ={
                method:"POST",
                headers:{"Content-Type":"application/json"},
                body:JSON.stringify(data),
                credentials: 'include'
                 };
    let output = fetch(`${API}/api/productReview/AddReview/${currentuser}`,options);
        output.then(res=>res.json())
              .then(result=>{
                // console.log("result",result);
                if(result.error){
                   setError(result.error);
                }
                else{
                  setError('');
                  GetProductReviews();
                  setIsModalOpen(false);
                      // Clear the form
                  setComment('');
                  setRating(0);
                }
                setIsSubmitLoading(false);
              })

    // Perform any necessary actions with the comment and rating data
    // console.log('Comment:', comment);
    // console.log('Rating:', rating);


  };

  const AddWishListhandler = ()=>{
        let data = {
            user_id : currentuser,
            product_id : items._id
        }
        let options ={
          method:"POST",
          headers:{"Content-Type":"application/json"},
          body:JSON.stringify(data),
          credentials: 'include'
           };
          //  console.log(data);
           let output = fetch(`${API}/api/auth/AddWishList/${currentuser}`,options);
               output.then(res=>res.json())
                     .then(result=>{
                          // console.log("result",result);
                          GetWishlistHandler();
                       })
  }

  const RemoveWishlisthandler = ()=>{
    let data = {
        user_id : currentuser,
        product_id : items._id
    }
    let options ={
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify(data),
      credentials: 'include'
       };
       let output = fetch(`${API}/api/auth/RemoveWishList/${currentuser}`,options);
           output.then(res=>res.json())
                 .then(result=>{
                      // console.log("result",result);
                      GetWishlistHandler();
                   })
}

// const AddProductReview = ()=>{
//   setIsSubmitLoading(true);
//   let data = {
//     ProductID:items._id, 
//     Comment:comment, 
//     Rating:rating
// }
// let options ={
//   method:"POST",
//   headers:{"Content-Type":"application/json"},
//   body:JSON.stringify(data),
//   credentials: 'include'
//    };
//   let output = fetch(`${API}/api/productReview/AddReview/${currentuser}`,options);
//       output.then(res=>res.json())
//             .then(result=>{
//        console.log("result",result);
//        setIsSubmitLoading(false);
//        GetProductReviews();
//        setIsModalOpen(false);
//       //  GetWishlistHandler();
//     })
// }






  return (
    <Fragment>
      
       {/* <button className="btn btn-block btn-dark mx-5 my-3" onClick={()=>popuphandler()}>BACK</button> */}
        {/* Produ t images and Video Full Screen Display start */}
       {
        displaymodal ?
        <>
        <div className='blur-background'></div>
        <div className="create-product overlay" style={{padding:"0px"}}>
        <div className="display-container-modal " >
          <div className='modal-img-vid-back'>
          <i className="bi bi-arrow-left" onClick={()=>{CloseHandler()}} ></i>
          </div>
     
      {currentImage === images.length - 1 ? (
        items.ProductVideo ? (
       <div className='my-auto'>
         <video controls autoPlay loop muted >
            <source src={items.ProductVideo} type="video/mp4" />
             Your browser does not support the video tag.
         </video>
      </div>
    ) : (
      <img src={images[currentImage]} alt={`Product ${currentImage + 1}`} className='my-auto'/>
    )
    ) : (
     <img src={images[currentImage]} alt={`Product ${currentImage + 1}`} className='my-auto' />
    )}

       <div className="navigation-button left" onClick={goToPreviousImage}>
         &lt;
       </div>
       <div className="navigation-button right" onClick={goToNextImage}>
        &gt;
       </div>
      

      </div>
        </div>
        </>
        :
        ""
       }
       {/* Produ t images and Video Full Screen Display end */}

       <>
       {
      isLoading ? <Loading/> :
      <>
      {/* Company Details start */}
       <div className='row' >
       
       <div className="logo-container py-2">
        {/* <button className="btn btn-block btn-dark mx-5 my-3" onClick={()=>popuphandler()}>BACK</button> */}
       {true && <Link to="/landingpage" style={{cursor:"pointer",marginLeft:"20px"}}><i className="bi bi-arrow-left-square-fill"  style={{fontWeight:"bolder",fontSize:"30px",marginRight:"10px",textDecoration:"none"}}></i></Link> }

       <img src={companyDetails.Logo} alt="Company Logo" className="logo" />
       <span className="company-name my-3" >{companyDetails.CompanyName}</span>
       </div>

       </div>
       {/* Company Details end */}
       
       {/* Product image And Video Display part */}
      <div className='row'>     
      {/* image video display  Part  start*/}
      <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'>  
      <div className="Prodcut-img-vid-container mx-auto">
      {/* <h1>Product Showcase</h1> */}
      <div className="display-container ">
        
      {currentImage === images.length - 1 ? (
        items.ProductVideo ? (
       <div>
         <video controls autoPlay  muted>
            <source src={items.ProductVideo} type="video/mp4" />
             Your browser does not support the video tag.
         </video>
      </div>
    ) : (
      <img src={images[currentImage]} alt={`Product ${currentImage + 1}`} />
    )
    ) : (
     <img src={images[currentImage]} alt={`Product ${currentImage + 1}`} />
    )}

       <div className="navigation-button left" onClick={goToPreviousImage}>
         &lt;
       </div>
       <div className="navigation-button right" onClick={goToNextImage}>
        &gt;
       </div>
       <div className="zoom-icon">
       <i className="bi bi-arrows-fullscreen" onClick={()=>setDisplayModal(true)}></i>
       </div>

      </div>

      {/* Mini Images and video display part start */}
      <div className='my-3 text-center mini-display' >
       {
  images &&
  images.length > 0 &&
  images.map((item, key) => {
    if (item.startsWith("data:video/mp4")) {
      return (
        
        <video key={key} 
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          cursor: 'pointer',
          margin: '2px',
          border: '2px solid #ccc',
        }}
        
        className="mx-2"
        src={items.ProductVideo}
        onClick={() => handleThumbnailClick(key)}
        />
         
      );
    } else {
      return (
        <img
          src={item}
          key={key}
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            cursor: 'pointer',
            margin: '2px',
            border: '2px solid #ccc',
          }}
          
          className="mx-2"
          alt={`Image ${key + 1}`}
          onClick={() => handleThumbnailClick(key)}
        />
      );
    }
  })
}

       </div>
       {/* Mini Images and video display part End */}
      
      </div>    
      </div>
       {/* image video display  Part  end*/}

       {/* Product Details Display part  start*/}
        <div className='col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8'>
            <div className='my-4 mx-3'>
            <div className="product-container">
              
             
            
            <div className='row mx-auto container-fluid px-0'>
              <div className='col-10 text-start px-0'>
              <span className="product-name">{items.ProductName}</span>
              </div>
              <div className='col-2 text-end'>

              {wishList.includes(items._id) ? (
              <i
               className="bi bi-heart-fill"
               style={{ fontSize: "25px", cursor: "pointer", height: "30px",color:'#e91e63' }}
               onClick={() => { RemoveWishlisthandler() }}
              ></i>
             ) : (
       
             <i
              className="bi bi-heart"
              style={{ fontSize: "25px", cursor: "pointer", height: "30px", color: 'black' }}
              onClick={() => { AddWishListhandler() }}
             ></i>
             )}
           
              </div>
              
             
            </div>
            <div>
      <div>
        {getDescriptionToShow()}&nbsp;&nbsp;&nbsp;
        {showMoreButton}
        {showLessButton}
      </div>
      
    </div>
           <div>
             Quantity:  {items.ProductQuantity > 0 ? <span className="quantity">{items.ProductQuantity} {items.ProductQuantityType}</span> : <div style={{color:"red"}}> Not Available</div>}
          </div>
         <div className="price">
            Price: <span className="span-highlight" style={{color:"green"}}> &#8377; {items.ProductPrice}  <span style={{color:"green"}}>/ {items.ProductQuantityType}</span></span>
        </div>
        <div className="star-rating">
          <StarRating rating={items.ProductRating} />
         </div>
      <div>
        {
          items.ProductQuantity > 0 ? <div style={{display:"flex",direction:"ltr"}}>
          <button onClick={()=>{setIsOpenOrderNowModal(true);}} >Order Now</button>
              &nbsp;&nbsp;<span className='my-auto' style={{fontWeight:"bolder"}}>OR</span>&nbsp;&nbsp;
          <button onClick={()=>{setISOpencartmodal(true);}} >ADD TO CART</button>
          </div>
          :
          <div style={{color:"red"}}>Stock Not Available</div>
        }
        
      
    </div>
   </div>
 </div>
     </div>
        {/* Product Details Display part end */}
      </div>
    {/* Add To cart Model Start */}

    {
        isopencartmodal ?
         <CartModal currentuser={currentuser} items={items} iscartmodalhandler={(data)=>{setISOpencartmodal(data)}}  />
        
        :
        ""
       }
          
    {/* Add To cart Model End */}

    {/* Order NOw Modal part Start */}
    {
      isOpenOrderNowModal ?
      <OrderNowModal items={items} currentuser = {currentuser} isopenhandler={(data)=>{setIsOpenOrderNowModal(data)}}  />
      :
      ""
    }
    {/* Order NOw Modal part End */}

    {/* Product Reviews Start */}
      <div className='row mt-5'>
      <div className="product-review-container">
      <h2>Product Reviews</h2>

    <div className='mx-5'>
      {
        isbuyproduct ? <input type='text' style={{fontSize:'20px'}}  placeholder='Add A Review' onClick={openModal}/>
         :
         <div
         style={{
           width: '100%',
           margin: '0 auto',
           padding: '10px',
           border: '1px solid red',
           borderRadius: '5px',
           backgroundColor: 'white',
         }}
       >
         <div
           style={{
             fontSize: '16px',
             fontWeight: 'bold',
             textAlign: 'left',
            //  marginBottom: '20px',
            //  padding: '10px',
            //  backgroundColor: '#eaeaea',
             borderRadius: '5px',
           }}
         >
          <span style={{color:'red',fontSize: '16px'}} className='my-auto'>&#9888;</span>
          &nbsp;&nbsp; 
           Buy a Product , Give a Review
         </div>
       </div>
      }     
      {/* <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="rating">Rating:</label>
          <CustomStarRating rating={rating} onRatingChange={handleRatingChange} />
        </div>
        <div>
          <label htmlFor="comment">Comment:</label>
          <textarea id="comment" value={comment} onChange={handleCommentChange} />
        </div>

        <button type="submit">Submit Review</button>
      </form> */}
    </div>
    {/* Reivew Modal Start */}
    {isModalOpen && (
      <div className='AddReviewModal'>
        <div className="modal">
          <div className="modal-content">
            <div className='row'>
              <div className='col-9'>
               <h1>Your Review</h1>
              </div>
               <span type='button' className='col-3 text-end' onClick={closeModal} style={{float:"right"}} disabled={isSubmitloading} ><i className="bi bi-x-lg" style={{fontSize:"20px"}}></i></span>
            </div>
           <div style={{color:"red",fontWeight:"bolder",fontSize:"14px"}}>{error}</div>
          <form onSubmit={AddReviewhandler}>
        <div>
          <label htmlFor="rating">Rating:</label>
          <CustomStarRating rating={rating} onRatingChange={handleRatingChange} />
        </div>
        <div>
          <label htmlFor="comment">Comment:</label>
          <textarea id="comment" value={comment} onChange={handleCommentChange} style={{fontSize:"15px"}} rows={10}/>
        </div>

        <button type="submit" style={{backgroundColor:"yellow",color:"black",fontWeight:"bolder"}} disabled={isSubmitloading} >{isSubmitloading ?  <ReactLoading type="spin" color="green" height={40} width={40} /> :'Submit Review'}</button>
      </form>
           
          </div>
        </div>
        </div>
      )}
        {/* Reivew Modal End */}
      
      {/* Review List */}
       {
        isReviewloading ? <ReactLoading type="spokes" color="green" height={40} width={40} />
        :
       
      <div className="reviews-list">
        {
           productReviews.length > 0 ?
        
        <>
        {productReviews.map((review) => (
          <div className="review" key={review._id}>
            <div className="review-header">
              <div className="profile-pic">
                {
                  review.userId.Photo === "Avator" ?
                  <img src={Avator} alt={review.userId._id} />
                  :
                  <img src={review.userId.Photo} alt={review.userId._id} />
                }
              </div>
              <div className="reviewer-details">
                <span className="reviewer-name">{review.userId.Username}</span>
                <p className="rating">
                <StarRating rating={review.rating} />
                </p>
              </div>
            </div>
            <p className="review-comment">{review.comment}</p>
          </div>
        ))}
        </>
        :
        <h1 style={{color:"red",fontWeight:"bolder",}}>No Review</h1>
        }
      </div>
      }

    </div>

      </div>
      {/* Product Reviews END */}
      </>
       }
      </>
       
    </Fragment>
  )
}
