import React ,{Fragment}from 'react';
import Header from '../Header/Header';
import Footer from '../../Components/Footer/Footer';
import Orders from '../Orders/Orders';
import "./style.css";

export default function Dashboard() {
  return (
    <Fragment>
           <Header/>
          <main style={{marginTop: '50px',minHeight:"85vh"}}>
              <div className='row container mx-auto'>
                <div className='card my-3'>
                     Note: Here you can See Total Orders , New Orders , Complete Orders 
                </div>
                <h1 className='mt-3'>Orders And Earnings Details</h1>
              </div>
              <div className='row container mx-auto dashboard'>
                   <div className="col-6 col-sm-6 col-md-6 col-lg-4 my-3">
                    <div className='card py-5' style={{cursor:"pointer"}}>
                       <h1> Total orders  </h1>
                      <p style={{color:"black",fontWeight:"bolder",fontSize:'20px'}}>200</p>
                    </div>
                   </div>
                   <div className="col-6 col-sm-6 col-md-6 col-lg-4 my-3">
                   <div className='card py-5' style={{cursor:"pointer"}} >
                   <h1> New orders  </h1>
                      <p style={{color:"black",fontWeight:"bolder",fontSize:'20px'}}>30</p>
                   </div>
                   </div>
                   <div className="col-6 col-sm-6 col-md-6 col-lg-4 my-3">
                   <div className='card py-5' style={{cursor:"pointer"}}>
                   <h1> Completed orders  </h1>
                      <p style={{color:"black",fontWeight:"bolder",fontSize:'20px'}}>150</p>
                   </div>
                   </div>
                   <div className="col-6 col-sm-6 col-md-6 col-lg-4 my-3">
                   <div className='card py-5' style={{cursor:"pointer"}}>
                   <h1> Estimated Earnings  </h1>
                      <p style={{color:"black",fontWeight:"bolder",fontSize:'20px'}}>$ 150</p>
                   </div>
                   </div>
                   <div className="col-6 col-sm-6 col-md-6 col-lg-4 my-3">
                   <div className='card py-5' style={{cursor:"pointer"}}>
                   <h1> Wallet  </h1>
                      <p style={{color:"black",fontWeight:"bolder",fontSize:'20px'}}>$ 250</p>
                   </div>
                   </div>
                   <div className="col-6 col-sm-6 col-md-6 col-lg-4 my-3">
                   <div className='card py-5' style={{cursor:"pointer"}}>
                   <h1> Online Earnings  </h1>
                      <p style={{color:"black",fontWeight:"bolder",fontSize:'20px'}}>$ 150</p>
                   </div>
                   </div>
                   <div className="col-6 col-sm-6 col-md-6 col-lg-4 my-3">
                   <div className='card py-5' style={{cursor:"pointer"}}>
                   <h1> COD Earnings  </h1>
                      <p style={{color:"black",fontWeight:"bolder",fontSize:'20px'}}>$ 150</p>
                   </div>
                   </div>
                   <div className="col-6 col-sm-6 col-md-6 col-lg-4 my-3">
                   <div className='card py-5' style={{cursor:"pointer"}}>
                   <h1> Return Money  </h1>
                      <p style={{color:"black",fontWeight:"bolder",fontSize:'20px'}}>$ 150</p>
                   </div>
                   </div>
                   <div className="col-6 col-sm-6 col-md-6 col-lg-4 my-3">
                   <div className='card py-5' style={{cursor:"pointer"}}>
                   <h1> Cancelled Money  </h1>
                      <p style={{color:"black",fontWeight:"bolder",fontSize:'20px'}}>$ 150</p>
                   </div>
                   </div>
              </div>
          </main>
          <Footer/>
    </Fragment>
  
  )
}
