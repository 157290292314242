import React from 'react';
import Header from "../Header/Header";
import Footer from '../Footer/Footer';
import SubHeader from '../Aside/SubHeader';

export default function Setting() {
  return (
    <>
      <Header/>
      <SubHeader/>
      <main>
        Settings page
      </main>
      <Footer/>
    </>
  )
}
