import React ,{ useState , useEffect } from 'react';
// import CountriesData from "../DATA/Country.json";
import apiUrl from "../APIURL";
import ModalLoading from '../Loading/ModalLoading';
import { Modal } from 'react-bootstrap';


export default function CreateBussiness(props) {
    const [ismodalopen,setIsmodalopen] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [companyDescription, setCompanyDescription] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [states, setStates] = useState([]);
    const [address, setAddress] = useState('');
    const [photo, setPhoto] = useState(null);
    const [pincode,setPincode] = useState('');
    const [mobileNo,setMobileno] = useState('');
    const [CountriesData,setCountriesData] =useState('');
    const [error,setError] = useState('');
    const [modalLoading,setModalLoading] = useState(false);


    // Get All Countries Name
    useEffect(()=>{
             let output = fetch(`${apiUrl}/api/country/GetAllCountry`,{method:"GET"})
                 output.then(res=>res.json())
                      .then(result=>{
                        setCountriesData(result.data);
                        
                      })
    },[])

    // Photo handler convert photo into base64 
  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

// Create Company Modal Close Handler
  const ondataclearHandler = ()=>{
    setIsmodalopen(false);
    setAddress('');
    setCompanyDescription('');
    setCompanyName('');
    setCountry('');
    setPhoto('');
    setState('');
    setStates('');
    setPincode('');
    setMobileno('');
    
  }
  

  // create Country list 
  // Create option element 
  let countrylist = CountriesData.length && CountriesData.map(items=><option value={items.country} key={items.id}>{items.country}</option>);

  // Create State list 
  // get States for choosen country
  // This handler execute when choose country
  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    let output = fetch(`${apiUrl}/api/country/GetStates/${selectedCountry}`,{method:"GET"})
    output.then(res=>res.json())
         .then(result=>{ 
          // console.log(result);
          if (result.data) {
          setCountry(selectedCountry);
          setStates(result.data[0].states);
        } else {
          setCountry('');
          setStates([]);
        }
       })
  };  
  // console.log("props",props);
  
  // Create Company Handler
  // Call API to create company handler
  const handleCreateCompany =(e)=>{
       e.preventDefault();
       setModalLoading(true);
       let data = {
        Logo:photo,
        CompanyName:companyName,
        CompanyDescription:companyDescription,
        Address:address,
        Pincode:pincode,
        MobileNo:mobileNo,
        State:state,
        Country:country
      };
      let options = {
         method:"POST",
         headers:{"Content-Type":"application/json"},
         body:JSON.stringify(data),
         credentials: 'include'
      }
      let output = fetch(`${apiUrl}/api/auth/createbussiness/${props.user.id}`,options);
          output.then(res=>res.json())
                .then(response=>{
                      //  console.log("response",response);
                       if(response.create){
                          setError('');
                          setModalLoading(false);
                          setIsmodalopen(false);
                          props.getuserupdatedata();
                       }
                       else{
                        setError(response.error);
                        setTimeout(()=>{setError('')},3000);
                        setModalLoading(false);
                       }
                       
                })
      // console.log(data);


  }


  return (<>
         {
            ismodalopen ?
            <>
               
                <div className='blur-background'></div>
                <div className='createcompany overlay row mx-auto mt-4 py-3'  >
                  <div className='text-center'>
                     <div className='container mt-3  card' style={{fontSize:"16px",fontWeight:"bolder",color:"green"}}> Create your Company</div>
                  </div>
                  
                  {
                    modalLoading ? <ModalLoading/>
                  :
                <form className="form-container   mx-auto px-3" onSubmit={handleCreateCompany}>
                  <div style={{color:"red",fontWeight:"bolder"}}>{error}</div>
                  <div className='container'>
            <div className="form-group ">
              <label htmlFor="companyName">Company Name: &nbsp;(max 50 letters)</label>
              <input
                type="text"
                id="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder='Company Name '
                required
              />
            </div>
    
            <div className="form-group">
              <label htmlFor="companyDescription">Company Description : &nbsp;(max 1000 letters)</label>
              <textarea
                id="companyDescription"
                value={companyDescription}
                onChange={(e) => setCompanyDescription(e.target.value)}
                required
                placeholder='company description'
              ></textarea>
            </div>
    
            <div className="form-group">
              <label htmlFor="country">Country:</label>
              <select
                type="text"
                id="country"
                className="country-select"
                onChange={handleCountryChange}
                required
              >
                <option value="" selected >--Select country--</option>
                {
                  countrylist
                }
             </select> 
            </div>
    
            <div className="form-group">
              <label htmlFor="state">State:</label>
              <select
                type="text"
                id="state"
                value={state}
                className="country-select"
                onChange={(e) => setState(e.target.value)}
                required
              >
                  <option value="" selected >--Select State--</option>
                
                         {states && states.length >0 && states.map(state => (
                          <option key={state} value={state}>{state}</option>
                        ))}
                
                
                </select>
            </div>

            <div className="form-group">
              <label htmlFor="Pincode">Pincode:</label>
              <input
                type="text"
                id="Pincode"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                required
                placeholder='Pincode'
              />
            </div>

            <div className="form-group">
              <label htmlFor="Mobileno">Mobile No:</label>
              <input
                type="text"
                id="Mobileno"
                value={mobileNo}
                onChange={(e) => setMobileno(e.target.value)}
                placeholder=' Mobileno (optional) '
              />
            </div>
    
            <div className="form-group">
              <label htmlFor="address">Address:</label>
              <input
                type="text"
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
                placeholder='address'
              />
            </div>
    
            <div className="form-group">
              <label htmlFor="photo">Logo (max 1MB) &nbsp;&nbsp;: &nbsp;&nbsp;</label>
              <input type="file" id="photo" accept="image/*" onChange={handlePhotoUpload} required />
            </div>
    
            {photo && (
              <div className="photo-preview">
                <img src={photo} alt="Uploaded" />
              </div>
            )}
            <div className='my-3'>           
            <button  type='button' className='btn btn-danger ' style={{width:"auto",borderRadius:'5px',fontSize:"14px",backgroundColor:"red",color:'white'}} onClick={()=>ondataclearHandler()} >Cancel</button>
            <button type="submit"  className='btn btn-success ' style={{width:"auto",backgroundColor:"green"}} >Create</button>    
            </div>
            </div>
          </form>
}
                </div>
                </> :<>
         
         <div className='row mt-4'>
          <button className='btn  btn-dark mx-auto '  style={{width:"auto"}} onClick={()=>{setIsmodalopen(true)}}>+ &nbsp; Create Bussiness</button>
         </div> 
         </>
         
         }
         
         
        
         
       
         </>
  )
}
