import React ,{Fragment,useEffect,useState} from 'react';
import Productcard from './Productcard';
import CreateProduct from './CreateProduct';
import API from '../APIURL';
import ModalLoading from '../Loading/ModalLoading';
import { CheckCookieAndReload} from "../CookieChecker";


export default function Product({currentuser}) {
  const [products , setProducts] = useState([]);
  const [modalLoading,setModalLoading] = useState(false);


  useEffect(()=>{
       GetProductHandler()
  },[])

  // Get Product handler
  const GetProductHandler = ()=>{
     setModalLoading(true);
    let output = fetch(`${API}/api/product/Get-Products-Company-People/${currentuser._id}`,{method:"GET", credentials: 'include'})
        output.then(res=>res.json())
              .then(response=>{
                // console.log("response",response);
                             if(response.data){
                             setProducts(response.data);
                             }
                             else{
                              CheckCookieAndReload();
                             }
                             setModalLoading(false);
               })

  }
  
// console.log("currentuser",currentuser);

const triggergetproducthandler =()=>{
  GetProductHandler();
}

  return (
    <Fragment>
        

        <div className='row mx-auto container-fluid my-3'>
          <h2 style={{fontWeight:"bolder",color:'gray'}}>Your Products   <span> ( {products && products.length} / 3 ) </span></h2>
        
          
            {
              modalLoading ? <ModalLoading/>
              :  
            
            products.length > 0  ?
            <Fragment>
                   {/* // if one product available this execute  */}
                   {
                      products.map(items=><Productcard items={items} key={items._id} currentuser={currentuser}  triggergetproducthandler={triggergetproducthandler} />)
                   }
                 
                 {

                  products.length >=3 ? ""
                  :

                 <CreateProduct currentUser={currentuser} triggergetproducthandler={triggergetproducthandler} />
                
                 }
          
                                
            </Fragment>            
            :
          // if product is 0 this part execute
            <CreateProduct currentUser={currentuser} triggergetproducthandler={triggergetproducthandler} />
          }
          
          
        </div>
        

    </Fragment>
     
  )
}
