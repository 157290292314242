import React, { createContext, useContext, useState, useEffect } from 'react';
import API from "../APIURL";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

const MyCookies = createContext();

export function CookieProvider({ children }) {
  const token = Cookies.get('mycookie');


  return (
    <MyCookies.Provider value={{ token }}>
      {children}
    </MyCookies.Provider>
  );
}

export function useMyCookie() {
  return useContext(MyCookies);
}

