import React,{Fragment,useState,useEffect} from 'react';
import Header from '../Header/Header';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode" ;
import API from '../APIURL';
import Loading from '../Loading/Loading';
import SubHeader from '../Aside/SubHeader';
import { Link } from 'react-router-dom';
import CartModal from '../Landingpage/CartModal';
import { CheckCookieAndReload} from "../CookieChecker";
import ReactLoading from "react-loading";

export default function Cart() {
    // get user data from cookies
  const token = Cookies.get('mycookie');
  let responsePayload ;
  if(token != undefined){
    responsePayload = jwt_decode(token);
  }

 const [user,setUser] = useState(responsePayload ||'');
 const [datum,setDatum] = useState('');
 const [products , SetProducts] = useState([]);
 const [isloading,setIsLoading] = useState(false);
 const [totalPrice,setTotalprice] = useState(0);
 const [isOpenCartModal,setISOpencartmodal] = useState(false);

 const deleteprodutfromcart = (quantity,productId)=>{  
      let data ={
                productId:productId,
                quantity:quantity
               }
               // console.log("data",data);

               let options = {
                 method: "POST", // It seems you mentioned POST here, but you mentioned PUT in the previous conversation. Make sure it's consistent with your backend route.
                 headers: { "Content-Type": "application/json" }, // Corrected Content-Type
                 body: JSON.stringify(data),
                 credentials: 'include'
               };
               

       let output = fetch(`${API}/api/auth/updatecart/${user.id}`,options);
           output.then(res=>res.json())
                 .then(response=>{
                  if(response.update){
                    GetCartProductHandler();
                  }   
                  else
                  CheckCookieAndReload();                
                 })                      
 }
    
    useEffect(()=>{
        GetCartProductHandler();
  },[])
  
  const GetCartProductHandler = ()=>{
    //  console.log("products!!!")
      setIsLoading(true);
     let output = fetch(`${API}/api/product/get-cart-data/${user.id}`,{method:"GET",credentials: 'include'});
         output.then(res=>res.json())
               .then(response=>{
                if(response.cart){
                 SetProducts(response.cart);
                 setIsLoading(false);                
                //  console.log("response",response);
                 setTotalprice(response.totalPrice);
                }
                else{
                  CheckCookieAndReload();
                }
               })
  }

  const setdatahandler =(value)=>{
              let data = {
                  _id: value.productDetails._id,
                  ProductPrice: value.productDetails.ProductPrice,
                  ProductQuantity: value.productDetails.ProductQuantity,
                  Quantity: value.quantity
              }
              // console.log(value);
              setDatum(data);
              setISOpencartmodal(true);
  }  






  return (
    <Fragment>
           <Header/>
          <SubHeader/>
          {
            isOpenCartModal ? <CartModal currentuser={user.id} items={datum} iscartmodalhandler={(data)=>{setISOpencartmodal(data);GetCartProductHandler();}}  /> 
            :
            ""
          }
           <main>
              <h1 className='my-3 py-3'>My cart</h1>
             
                 {
                  
                  isloading ? 
                  <Loading/>
                                  :
                <Fragment>
                  {
                products.length > 0 ? 
              <>
                {
                 products  && products.length > 0 && products.map(items=><div className='row'>
                  {
                    items.productDetails ?
                    <div className='col-12'>
                    <div className='card container'>
                     <div className='row'>
                      <div className='col-3'>            
                      <Link to={`/productdetailspage/${items.productId}`} style={{textDecoration:"none",color:"black"}}> <img src={items.productDetails.ProductImages[0]} alt="Product image..." style={{width:"40px",height:"40px"}} /></Link>
                         &nbsp;&nbsp; <Link to={`/productdetailspage/${items.productId}`}><i className='bi bi-eye-fill' style={{cursor:"pointer",fontWeight:"bolder",textDecoration:"none",color:"gold"}} ></i></Link>
                      </div>
                      <div className='col-3 my-auto'><Link to={`/productdetailspage/${items.productId}`} style={{textDecoration:"none",color:"black"}} >{items.productDetails.ProductName}</Link></div>
                      <div className='col-3 my-auto' onClick={()=>setdatahandler(items)}> <span className='px-1' style={{fontWeight:"bolder",fontSize:"10px",cursor:'pointer',backgroundColor:"black",color:"white",borderRadius:"5px"}}>-</span> &nbsp; {items.quantity} &nbsp; <span className='px-1' style={{fontWeight:"bolder",fontSize:"10px",cursor:'pointer',backgroundColor:"black",color:"white",borderRadius:"5px"}}>+</span></div>
                      <div className='col-3 my-auto'> <span style={{color:"green",fontWeight:"bolder"}}>$  {items.quantity * items.productDetails.ProductPrice} </span> &nbsp;&nbsp; <i className='bi bi-trash-fill' style={{cursor:"pointer",fontWeight:"bolder"}} onClick={()=>{deleteprodutfromcart(0,items.productId)}}></i></div>
                      </div>
                    </div>
                 </div>
                 :
                 <div className='row'>
                 <div className='col-9 text-center text-danger p-3 card' >Product Not Found 404 <i className='bi bi-trash-fill' style={{cursor:"pointer",fontWeight:"bolder"}} onClick={()=>{deleteprodutfromcart(0,items.productId)}}></i></div>
                 </div>
                  }
                    
                  </div>)
                }
               <div className=' card container'>
                <div className='row my-3 py-3'>
                         <div className='col-12 col-md-2 col-lg-2'> </div>
                         <div className='col-12 col-md-2 col-lg-2'></div>
                         <div className='col-6 my-auto col-md-4 col-lg-4 text-end '><span style={{fontWeight:"bolder",fontSize:"16px"}}>Total Price : <span className='text-success' style={{fontWeight:"bolder",fontSize:"16px"}}>&#8377; {totalPrice}</span></span></div>
                         <div className='col-6 col-md-4 col-lg-4 '>| &nbsp;&nbsp;<Link to={{
                               pathname: `/checkoutpage/${"C8a2r5t6P9"}/${"cart"}`,
                               state: { message: 'Hello from Home' } 
                       }} style={{textDecoration:"none",}}><button className='btn btn-success my-auto' style={{backgroundColor:"green",color:"white",borderRadius:"7px",fontSize:"14px"}}>Pay Now</button></Link></div>
              </div>
              </div>
              </>             
              :
              <div className='text-center' style={{color:"red",fontWeight:"bolder",fontSize:"16px"}}>No data Found</div>

               }

              </Fragment>            
             
            }
                     
           </main>
    </Fragment>
   
  )
}
