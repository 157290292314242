import {Route,Routes ,Navigate,useLocation} from "react-router-dom";
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode" ;

// Import Components
import Homepage from "./Components/HomePage/Homepage";
import Home from  "./Components/Homepage2/Home";
import LoginPage from "./Components/Authenication/Login";
import SignupPage from "./Components/Authenication/Signup";
import ConfirmAccount from "./Components/Authenication/ConfirmAccount";
import SignupSuccess from "./Components/Authenication/SignupSuccess";
import Verify from "./Components/Authenication/Verify";
import Landingpage from "./Components/Landingpage/Landingpage";
import Header from "./Components/Header/Header";
import { useEffect, useState } from "react";
import Setting from "./Components/Settingpage/Setting";
import Profilepage from "./Components/Profilepage/Profilepage.jsx";
import ForgotPassword from "./Components/Authenication/ForgotPassword";
import Changepassword from "./Components/Authenication/Changepassword";
import Bussiness from "./Components/Bussiness/Bussiness";
import Wishlist from "./Components/Landingpage/Wishlist";
import Cart from "./Components/MyCart/Cart";
import MyOrders from "./Components/My Orders/MyOrders";
import PaymentCheckoutPage from "./Components/Checkoutpage/Checkoutpage";
import Productpage from "./Components/product page/Productpage";
import Message from "./Components/Checkoutpage/Message";
// Seller Components import
import Dashboard from "./SellerComponents/Dashboard/Dashboard";
import Orders from "./SellerComponents/Orders/Orders";
import Products from "./SellerComponents/Products/Products";
import EditProduct from "./SellerComponents/EditProducts/EditProduct";
import ProductDesign from "./Components/Design/productdesgin";
import ReturnorderRequest from "./SellerComponents/ReturnOrdersRequest/ReturnOrdersRequest";


let routes;
let responsePayload  ;

function App() {
  
  const token = Cookies.get('mycookie');
  // console.log("mycookies",token);
  if(token != undefined){
    responsePayload = jwt_decode(token);
    // console.log("tokens",responsePayload);
    responsePayload = responsePayload.isloggedin;
    // console.log("tokens",responsePayload);
    
  }
  
  const [isloggedon,setIsloggedon] = useState(responsePayload || false)
  
  if(isloggedon) {
     routes = (<>
                 <Route path= "/" element={<Homepage/>} />
                 <Route path= "/settings" element={<Setting/>} />
                 <Route path='/landingpage' element={<Landingpage/>}/>
                 <Route path='*' element={<Navigate to ='/landingpage'/>}/>
                 <Route path="/verify" element={<Verify/>} />
                 <Route path="/header" element={<Header/>} />
                 <Route path="/profile" element={<Profilepage/>} />
                 <Route path="/Mybusiness" element={<Bussiness/>} />
                 <Route path="/wishlist" element={<Wishlist/>} />
                 <Route path="/mycart" element={<Cart/>} />
                 <Route path="/myorders" element={<MyOrders/>} />
                 <Route path="/checkoutpage/:productid/:pathid" element={<PaymentCheckoutPage/>} />
                 <Route path="/productdetailspage/:id" element={<Productpage/>} />
                 <Route path="/dashboard" element={<Dashboard/>} />
                 <Route path="/business/orders" element={<Orders/>} />
                 <Route path="/Products" element={<Products/>} />
                 <Route path="/Product/:id/edit" element={<EditProduct/>} />
                 <Route path="/message" element={<Message/>} />
                 <Route path ='/carddesign' element={<ProductDesign/>} />
                 <Route path="/returnordersrequest" element={<ReturnorderRequest/>} />
                 
              </>) 
  }
  else{
     routes = (<>
               <Route path= "/" element={<Homepage/>} />
               <Route path='*' element={<Navigate to ='/login'/>}/>
               <Route path= "/2" element={<Home/>} />
               <Route path="/login" element={<LoginPage/>} />
               <Route path="/signup" element={<SignupPage/>} />
               <Route path="/verify" element={<Verify/>} />
               <Route path="/signupsuccess" element={<SignupSuccess/>} />
               <Route path="/forgotpassword" element={<ForgotPassword/>} />
               <Route path="/changepassword" element={<Changepassword/>} />
               <Route path="/confirm/:id" element={<ConfirmAccount/>} />
              </>
)
  }    
  
  return (
    <Routes>
      {routes}
    </Routes>
  ); 
}

export default App;
