import React ,{useEffect,useState} from 'react';
import StarRating from './StarRating';
import API from '../APIURL';
import CartModal from './CartModal';
import {Link} from "react-router-dom";
import { CheckCookieAndReload } from '../CookieChecker';

export default function ProductCard({items,popuphandler,datahandler,currentuser}) {
   const [wishList,setWishlist] = useState([]);
   const [isopencartmodal , setISOpencartmodal] = useState(false);

  useEffect(()=>{
    GetWishlistHandler();              
  },[]);

//   Get Wishlist ID Handler
  const GetWishlistHandler = ()=>{
    let output = fetch(`${API}/api/auth/getwishlist/${currentuser}`,{method:"GET",credentials: 'include'})
                .then(res=>res.json())
        output.then(response=>{
         //  console.log("response",response);
         if(response.WishList){
          setWishlist(response.WishList);
         }
         else{
          CheckCookieAndReload();
         }
                     
        })
  }

//   Add WishList Handler
  const AddWishListhandler = ()=>{
   let data = {
       user_id : currentuser,
       product_id : items._id
   }
   let options ={
     method:"POST",
     headers:{"Content-Type":"application/json"},
     body:JSON.stringify(data),
     credentials: 'include'
      };
      // console.log(data);
      let output = fetch(`${API}/api/auth/AddWishList/${currentuser}`,options);
          output.then(res=>res.json())
                .then(result=>{
                     // console.log("result",result);
                     GetWishlistHandler();
                  })
}

// Remove Wishlist Handler
const RemoveWishlisthandler = ()=>{
let data = {
   user_id : currentuser,
   product_id : items._id
}
let options ={
 method:"POST",
 headers:{"Content-Type":"application/json"},
 body:JSON.stringify(data),
 credentials: 'include'
  };
  let output = fetch(`${API}/api/auth/RemoveWishList/${currentuser}`,options);
      output.then(res=>res.json())
            .then(result=>{
               //   console.log("result",result);
                 GetWishlistHandler();
              })
}

// Product details open handler
 const popupdisplayhandler =(data)=>{
        popuphandler();
        datahandler(data);
}

// console.log("items",items);

  return (
    <>

{
        isopencartmodal ?
         <CartModal currentuser={currentuser} items={items} iscartmodalhandler={(data)=>{setISOpencartmodal(data)}}  />
        
        :
        ""
       }
     
         
          
     
     <li className='col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-3' key={items._id} >
             <div className="product-card" style={{position:"relative"}}  >
              <Link to={`/productdetailspage/${items._id}`} style={{cursor:"pointer"}}>
                 <img src={items.ProductImages} alt={items.ProductName} /> 
              </Link>
      

      <div className="product-details">
      <Link to={`/productdetailspage/${items._id}`} style={{cursor:"pointer",textDecoration:"none"}}>
        <div  >
        <h2 className='my-3' style={{fontWeight:"bolder"}}>{items.ProductName}</h2>
        <h2  >{ items.ProductDescription.length > 50 ? items.ProductDescription.substring(0, 50) + '...' :  items.ProductDescription }</h2>
        <p className="price" >&#8377; &nbsp;{items.ProductPrice} / {items.ProductQuantityType}</p>
        <div className="rating">Rating: <StarRating rating={items.ProductRating}/></div>
        </div>
      </Link>
       

        <div className={`favorite ${wishList.includes(items._id) ? 'favorited' : ''}`}  >
         { 
         wishList.includes(items._id) ?
          <i className={`bi bi-heart-fill`} style={{fontSize:"25px"}} onClick={RemoveWishlisthandler} />
          :
          <i className={`bi bi-heart`} style={{fontSize:"25px"}} onClick={AddWishListhandler} />
         }
        </div>

        <button className="add-to-cart" onClick={() =>{setISOpencartmodal(true)}}>
          Add to Cart
        </button>
      </div>
    </div>

           </li>
           
            
          
           
           
         
           
          
        
    {/* <div className="card ">
    <div className='row'>
    <div className='col-5 col-md-4 col-lg-4 col-xl-4 text-start my-auto' onClick={()=>popupdisplayhandler(items)} style={{cursor:"pointer"}}>
         <img src={items.ProductImages[0]} alt="image..." style={{width:"100px",height:'100px',borderRadius:"7px"}} className='my-auto' />
   </div>
   <div className='col-7 col-md-8 col-lg-8 col-xl-8 ' >
      <p className='text-start my-2' style={{fontWeight:"bolder",fontSize:"12px"}}>
         <div className='mx-auto row container-fluid px-0 py-0'>
            <div className='col-12 px-0 py-0'>{items.ProductName}</div>
           
         </div>
      </p>
      <p className='text-start my-2' style={{ fontWeight: 'bolder', fontSize: '10px' }}>
           { items.ProductDescription.length > 30 ? items.ProductDescription.substring(0, 30) + '...' :  items.ProductDescription }
      </p>
      <p className='text-start my-2' style={{fontWeight:"bolder",fontSize:"10px"}}>Qauntity :<span style={{fontWeight:"bolder",fontSize:"12px",color:"green"}}> &nbsp; {items.ProductQuantity} {items.ProductQuantityType}</span> </p>
      <p className='text-start my-2' style={{fontWeight:"bolder",fontSize:"10px"}}>Price per {items.ProductQuantityType} :<span style={{fontWeight:"bolder",fontSize:"12px",color:"green"}}> &#8377; {items.ProductPrice}  </span> </p>  
      <p className='text-start my-2'>{ 1 ? <StarRating rating={items.ProductRating}/> : "" }</p>            
      <p className='text-start my-2' style={{fontWeight:"bolder",fontSize:"10px"}}><i className="bi bi-geo-alt-fill" style={{color:"red"}}></i> &nbsp;{items.State} , {items.Country}</p>
   </div>
    </div>
    <div className="row">
    <div className='col-6 text-center'><i className='bi bi-eye-fill' style={{color:"green",fontWeight:"bolder",fontSize:"18px",cursor:"pointer"}} onClick={()=>popupdisplayhandler(items)}></i></div>
    <div className='col-6 text-center'>
    <div className=' ' style={{color:"Gold",fontWeight:"bolder",fontSize:"18px",cursor:"pointer"}}>
            {wishList.includes(items._id) ? (
         <i
         className="bi bi-heart-fill"
         style={{fontSize:"18px",cursor:"pointer",height:"30px",color:'gold'}}
         onClick={() => { RemoveWishlisthandler() }}
       ></i>
      ) : (
       
        <i
        className="bi bi-heart"
        style={{ fontSize: "18px", cursor: "pointer", height: "30px", color: 'black' }}
        onClick={() => { AddWishListhandler() }}
      ></i>
      )}
             
      </div>
      
    </div>  
     
    </div>
   
</div> */}

</>
  )
}
