import React, { useState } from 'react';
import API from "../APIURL";
import ReactLoading from 'react-loading';


const OTPVerification = ({emailpara,isOpenPaymentChoosehandler,otpverified}) => {
  const [email, setEmail] = useState(emailpara);
  const [otpInput, setOtpInput] = useState('');
  const [otpToVerify, setOtpToVerify] = useState('');
  const [isOTPVerified, setIsOTPVerified] = useState(otpverified === undefined ? false :otpverified);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  // Generate a random 6-digit OTP
  const generateOTP = () => {
    const digits = '0123456789';
    let otp = '';
    for (let i = 0; i < 6; i++) {
      otp += digits[Math.floor(Math.random() * 10)];
    }
    return otp;
  };

  // Send the OTP to the user (you can implement this function as needed)
  const sendOTP = () => {
          setLoading(true);
       let data ={Email:email};
       let options = {
                      method:"POST",
                      headers:{"Content-Type":"application/json"},
                      body:JSON.stringify(data)       
                     }
        fetch(`${API}/api/auth/SendOTP`,options)
             .then(res=>res.json())
             .then(response=>{
              //  console.log(response);
               if(response.verify){
                   setIsOTPSent(true);
               }
               setLoading(false);
              
             })


    // const generatedOtp = generateOTP();
    // setOtpToVerify(generatedOtp);
    // Implement your code here to send the OTP to the user via SMS, email, etc.
    // For demonstration purposes, we'll log the OTP to the console.
    // console.log('OTP Sent to ' + email + ': ' + generatedOtp);
    // setIsOTPSent(true)
  };

  // Verify the OTP entered by the user
  const verifyOTP = () => {
    setIsLoading(true);
    let data ={Email:email, VerificationCode:otpInput};
    let options = {
                   method:"POST",
                   headers:{"Content-Type":"application/json"},
                   body:JSON.stringify(data)       
                  }
     fetch(`${API}/api/auth/verifyOTP`,options)
          .then(res=>res.json())
          .then(response=>{
            // console.log(response);
            if(response.verify){
              setIsOTPVerified(true);
              isOpenPaymentChoosehandler(true);
             
              // alert('OTP Verified Successfully!');
            } else {
              // alert('Invalid OTP. Please try again.');
            }
           setIsLoading(false);
           setOtpInput('');
          })
    
  };

  return (
    <div>
      {/* <h2>OTP Verification</h2> */}
     {isOTPVerified ? <p>Email Verified Successfully</p> : <p>Email to receive the OTP:</p> }
      <div className='row mx-auto'>
        <div className='col-8 col-md-8 col-lg-8'>
        <input
        type="text"
        value={email}
        readOnly={true}
        placeholder="Enter Email"
      />
        </div>
        <div className='col-4 col-md-4 col-lg-4'>
            {isOTPVerified ?             
                <div className='btn btn-sm ' style={{fontSize:"12px",padding:"5px",height:"35px",backgroundColor:"green",color:'white'}}> Verified  <span
                className={`tick ${true ? 'tick-animated' : ''}`}
              >
                &#x2714;
              </span> </div>
                :
                <>
                {  loading ?<button className='btn btn-block' style={{backgroundColor:'greenyellow',height:"30px"}}><ReactLoading type="spin" color="#000" height={25} width={25} /></button> 
                :
                <>
                  { isOTPSent ?
                   <button onClick={sendOTP} className='btn btn-sm btn-success' style={{fontSize:"12px",padding:"5px",height:"35px"}}>ReSend </button>
                   :
                   <button onClick={sendOTP} className='btn btn-sm btn-success' style={{fontSize:"12px",padding:"5px",height:"35px"}}>Send OTP </button>
                  }
                   </>
                 }

                </>             
             
            }

        </div>
      </div>
      
    {
      isOTPSent && isOTPVerified !== true ?
    <>
      <p>Enter the OTP received:</p>
      <div className='row mx-auto'>
        <div className='col-8 col-md-8 col-lg-8'>
        <input
        type="text"
        value={otpInput}
        onChange={(e) => setOtpInput(e.target.value)}
        placeholder="Enter OTP"
      />
        </div>
        <div className=' tex-start col-4 col-md-4 col-lg-4'>
          {
              isloading ?<button className='btn btn-block ' style={{backgroundColor:'green',height:"30px"}}><ReactLoading type="spin" color="#000" height={25} width={25} /></button> 
          :
        <button className='btn btn-block btn-dark' onClick={verifyOTP} style={{fontSize:"12px",padding:"5px",height:"35px"}}>Verify OTP</button>
          }
        </div>
        </div>
    </>
    :
    ""
}
     
    </div>
  );
};

export default OTPVerification;
