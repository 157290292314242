import React ,{Fragment,useState,useEffect} from 'react';
import OTPVerification from './OTPVerified';
import { Link } from 'react-router-dom';
import API from "../APIURL";

export default function OrderNowModal({items,currentuser,isopenhandler }) {
    const [cartquantity,setCartQuantity] = useState(1);
    const [Cartprice,setCartPrice] = useState(items.ProductPrice);
    const [CurrentStep,setCurrentStep] = useState(0);
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [address, setAddress] = useState("");
    const [pincode,setPincode] = useState("");
    const [city,setCity] = useState('');
    const [isbuttonloading,setIsButtonLoading] = useState(false);
    const [ispaymentselect,setIsPaymentSelect] = useState('');
    const [error,setError]= useState({step1err:"",step2err:"",step3err:""});
    const [isOtpVerified , setIsOtpVerified] = useState(false);

    const AddCartHandler = ()=>{
      // setisloading(true);
        let data ={
                  productId:items._id,
                  quantity:parseInt(cartquantity)
                 // quantity:cartquantity
                 }
                 // console.log("data",data);

                 let options = {
                   method: "POST", // It seems you mentioned POST here, but you mentioned PUT in the previous conversation. Make sure it's consistent with your backend route.
                   headers: { "Content-Type": "application/json" }, // Corrected Content-Type
                   body: JSON.stringify(data),
                   credentials: 'include'
                 };
                 

         let output = fetch(`${API}/api/auth/updatecart/${currentuser}`,options);
             output.then(res=>res.json())
                   .then(response=>{
                    //  console.log(response);
                     
                   })
                      

   }
  
  
    let totalprice = 0 ;
    const currentStepForward = ()=>{
        if(validation()){
              setCurrentStep(CurrentStep + 1 );
            }  
        
        }
        
        const validation = ()=>{
          if(CurrentStep === 0){
            if(cartquantity !== 0 && Cartprice !== 0 && cartquantity <= items.ProductQuantity){
                 setError((prevError) => ({
                   ...prevError,
                   step1err: "",
                 }));
                 return true ;
            }
            else{
             setError((prevError) => ({
               ...prevError,
               step1err: "Min select 1 quantity and max available quantity", 
             }));
             return false;
              
            }
        }
        
        if(CurrentStep === 1){
        function isValidMobileNumber(mobileNumber) {
         // Regular expression to validate a mobile number (assuming it should start with 7, 8, or 9 and have exactly 10 digits)
         const mobileNumberRegex = /^\d{10}$/;
         return mobileNumberRegex.test(mobileNumber);
        }
        
        function isValidEmail(email) {
         // Regular expression to validate an email address
         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
         return emailRegex.test(email);
        }
        
        function isValidPincode(pincode) {
         // Regular expression to validate a pin code (assuming it should be 6 digits long)
         const pincodeRegex = /^\d{6}$/;
         return pincodeRegex.test(pincode);
        }
        
        function isValidAddress(address) {
         // You can add your custom validation for the address here
         // For example, check if the address is not empty or has a minimum length, etc.
         return address.trim().length > 0;
        }
        
        function isValidCity(city) {
         // You can add your custom validation for the city here
         // For example, check if the city is not empty or has a minimum length, etc.
         return city.trim().length > 0;
        }
        
        const isMobileValid = isValidMobileNumber(mobile);
        const isEmailValid = isValidEmail(email);
        const isPincodeValid = isValidPincode(pincode);
        const isAddressValid = isValidAddress(address);
        const isCityValid = isValidCity(city);
        
        if(isMobileValid && isEmailValid && isPincodeValid && isAddressValid && isCityValid){
         setError((prevError) => ({
           ...prevError,
           step2err: "",
         }));
         return true ;
        }
        else{
         setError((prevError) => ({
           ...prevError,
           step2err: "Please fill all required fields correctly !!!",
         }));
         return false ;
        }
        
        }
        }
        useEffect(()=>{validation()},[email,pincode,mobile,address,city,cartquantity,Cartprice,])
        
        // Order Now modal close handler 
        const OrderNowCloseHandler =()=>{
            isopenhandler(false);
            setCartPrice(0);
            setCartQuantity(0);
            setAddress('');
            setCity('');
            setEmail('');
            setMobile('');
            setError((prevError) => ({
              ...prevError,
              step1err: "",
              step2err:"",
              step3err:""
            }));
           setPincode('');
           setCurrentStep(0);
           setIsPaymentSelect('');
        }
        
  return (
   <Fragment>
     <>
      <div className='blur-background'></div>
      <div className="create-product overlay" style={{padding:"20px",top:"5%",bottom:'auto'}}>
         <h1>Order Now</h1>
         <hr style={{margin:"10px",color:"white",height:"1px"}} />
        {
          CurrentStep === 0 ?
          <>
          <div className='my-3 p-3'>
             <div className={`vibrate ${error.step1err ? 'error' : ''} my-3`}>{error.step1err}</div>
             <div style={{fontSize:"16px"}} className='my-5 '>Total Quantity : {items.ProductQuantity} </div>
             <div style={{fontSize:"14px"}} className='my-5 '>
               <span style={{display: "inline-flex"}} className='my-auto'>
               <button style={{padding: "10px",fontWeight:"bolder",fontSize:"16px",color:'white',backgroundColor:"black"}} className='btn btn-block'
               onClick={()=>{
                setCartQuantity((prevQuantity) => Math.max(prevQuantity - 1, 0));
                if(cartquantity - 1 > 0){
                totalprice = (cartquantity - 1) * items.ProductPrice ;
                setCartPrice(totalprice);
                }
                else{
                  setCartPrice(0);
                }
               }}
               >-</button>&nbsp;
               <input type="text" style={{fontSize:"16px",fontWeight:"bolder"}} value={cartquantity} 
                onChange={(e)=>{setCartQuantity(e.target.value);
                totalprice = e.target.value * items.ProductPrice ;
                setCartPrice(totalprice);}}/> &nbsp;&nbsp;&nbsp;&nbsp;
               <button style={{padding:"10px",fontWeight:"bolder",fontSize:"16px",color:'white',backgroundColor:"black"}} className='btn btn-block'
               onClick={()=>{
                setCartQuantity((prevQuantity) => prevQuantity + 1);
                totalprice = (cartquantity + 1) * items.ProductPrice ;
                setCartPrice(totalprice);
               }}
               >+</button>
               </span>
             </div>
             <div className='my-5' style={{fontSize:"14px"}}> Total Price : <span style={{fontSize:"16px"}}> &#8377; {Cartprice} </span> </div>
         </div>

         <hr style={{margin:"10px",color:"white",height:"3px"}} />
         <div className='row'>
           <div className='col-6 text-end  px-0'>
           <button className='btn' style={{backgroundColor:'white',color:"gray"}} onClick={()=>{isopenhandler(false)}}>Close</button>
           </div>
           <div className='col-6 text-start px-0'>
            <Link to={`/checkoutpage/${items._id}/${"ordernow"}`}>
           <button className="btn" style={{fontSize:"12px",
        backgroundColor: error.step1err ? 'red' : 'white',
        color: error.step1err ? 'black' : 'green', // You can change the text color here if needed
      }}
       disabled={error.step1err ? true : false} onClick={()=>{AddCartHandler()}} >Checkout Now</button>
       </Link>
           </div>  
       </div>
          </> 
          :
          ""
        }
        {
          CurrentStep === 1 ?
          <>
          <div className='my-3 p-3'>
           <h2 style={{color:"white"}}>Add Delivery Address</h2>
           <div className={`vibrate ${error.step2err ? 'error' : ''} my-3`}>{error.step2err}</div>
           <label>Email <sup>*</sup></label>
            <input type="text" value={email} onChange={e=>{setEmail(e.target.value);}} />
          
           <label>Mobile Number <sup>*</sup></label>
            <input type="text" value={mobile} onChange={e=>{setMobile(e.target.value);}} />
           
            <label>Address <sup>*</sup></label>
            <input type='text' value={address} onChange={e=>{setAddress(e.target.value);}} />

            <label>Pincode <sup>*</sup></label>
            <input type='text' value={pincode} onChange={e=>{setPincode(e.target.value);}} />

            <label>City <sup>*</sup></label>
            <input type='text' value={city} onChange={e=>{setCity(e.target.value);}} />
            
           
          </div>
          <hr style={{margin:"10px",color:"white",height:"3px"}} />
         <div className='row mx-3'>
           <div className='col-4 text-start  px-0'>
           <button className='btn' style={{backgroundColor:'white',color:"gray"}} onClick={()=>{isopenhandler(false)}}>Close</button>
           </div>
           <div className='col-4 text-end  px-0'>
           <button className='btn' style={{backgroundColor:'white',color:"gray"}} onClick={()=>{setCurrentStep(CurrentStep - 1)}}>Back</button>
           </div>
           <div className='col-4 text-start px-0'>
           <button className='btn' style={{ backgroundColor: error.step2err ? 'red' : 'white', color: error.step2err ? 'black' : 'black', }} onClick={()=>currentStepForward()} >Next</button>
           </div>  
       </div>
          </>
          :
          ""
        }
        {
          CurrentStep === 2 ?
          <>
          <div style={{ overflow: 'auto', height:"80vh" }}>
          <div className='my-3 p-3'  >
           <h2 style={{color:"white"}}>Preview Billing Details</h2>
           <div className={`vibrate ${error.step3err ? 'error' : ''} my-3`}>{error.step3err}</div>
            <div className='my-3'>
               <img src={items.ProductImages[0]} style={{width:'30px',height:"30px"}}/><span  style={{fontSize:"14px",padding:"20px"}}>{items.ProductName}</span>
            </div>
            <div className='my-3' style={{fontSize:"14px"}}>
              Quantity :  &nbsp;&nbsp;&nbsp;&nbsp;  <span style={{fontSize:"12px",border:"1px solid gray",backgroundColor:'white',color:"black",padding:"5px",borderRadius:"7px",fontWeight:"bolder"}}>{cartquantity}</span> 
            </div>
            <div className='my-3 pt-3' style={{fontSize:"14px"}}>
              Total Price : &nbsp;  <span style={{fontSize:"14px",border:"1px solid gray",backgroundColor:'white',color:"green",borderRadius:"5px",fontWeight:"bolder",padding:"5px"}}> &#x20B9; {Cartprice}</span> 
            </div>
            <hr style={{color:"white",height:"1px"}} className='my-3' />
            <div>
             <h3 className='my-3 '>Billing Address</h3>
              <div className='my-3 ' style={{fontSize:"14px"}}>Address : <span style={{fontSize:"14px",border:"1px solid gray",backgroundColor:'white',color:"black",borderRadius:"7px"}} className='px-3'> {address} ,{city} - {pincode} </span> </div>
            </div>
          </div>
          <hr style={{color:"white",height:"1px"}} className='my-3' />
          <OTPVerification emailpara={email} isOpenPaymentChoosehandler={(data)=>{setIsOtpVerified(data)}}/>
          <hr style={{color:"white",height:"1px"}} className='my-3' />
          {
            isOtpVerified ?<>
          
          <h2 style={{color:"white"}}>Choose Payment Method</h2>
          <div className='mx-5 my-3' >
            <input type="radio" name="pay" checked={ispaymentselect ==="cash on delivery"} onChange={(e)=>{setIsPaymentSelect("cash on delivery")}}  /> <span style={{fontSize:"14px"}}>Cash On Delivery</span> 
            </div>
            <div className='mx-5 my-3' >
            <input type="radio"  name="pay" checked={ispaymentselect === "pay  Now"} onChange={(e)=>{setIsPaymentSelect("pay  Now")}}  /> <span style={{fontSize:"14px"}}>Pay Online Now</span> 
            </div>
            <div className='text-center px-5'>
            
            {ispaymentselect === "cash on delivery" || ispaymentselect === 'pay  Now' ? <>
            {
              ispaymentselect === 'pay  Now' ?  
              <button
              className="btn"
              style={{
                backgroundColor: 'green',
                color: 'white',
                cursor: isbuttonloading ? 'progress' : 'pointer',
                opacity: isbuttonloading ? 0.6 : 1,
              }}
              onClick={!isbuttonloading ? ()=>{ setIsButtonLoading(true);
                setTimeout(() => {
                  setIsButtonLoading(false);
                }, 2000);} : undefined}
            >
              {isbuttonloading ? 'Loading...' : 'Pay Now'}
            </button>
              :
              <button
              className="btn"
              style={{
                backgroundColor: 'green',
                color: 'white',
                cursor: isbuttonloading ? 'progress' : 'pointer',
                opacity: isbuttonloading ? 0.6 : 1,
              }}
              onClick={!isbuttonloading ? ()=>{ setIsButtonLoading(true);
                setTimeout(() => {
                  setIsButtonLoading(false);
                }, 2000);} : undefined}
            >
              {isbuttonloading ? 'Loading...' : 'Order Now'}
            </button>
              // <button className='btn' style={{backgroundColor:'green',color:"white"}}  ></button>
            }
            </>
            :
            ""
}
           </div> 
           </>
           :
           ""
          }

          <hr style={{margin:"10px",color:"white",height:"3px"}} />
         <div className='row mx-3'>
           <div className='col-12 text-start  px-0'>
           <button className='btn' style={{backgroundColor:'white',color:"gray"}} onClick={()=>{setCurrentStep(CurrentStep - 1)}}>Back</button>
           </div>
          
         
       </div>
       </div>
          </>
          :
          ""
        }
       
         
      </div>
      </>
        
   </Fragment>
  )
}
