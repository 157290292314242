// import "./Home.css";
import React, { Fragment } from 'react'

export default function Home() {
  return (
    <Fragment>
    <header>
      <div className="logo">
        <img src="logo.png" alt="My Agriculture Website" />
      </div>
      <nav>
        <ul>
          <li><a href="#">Home</a></li>
          <li><a href="#">Products</a></li>
          <li><a href="#">About Us</a></li>
          <li><a href="#">Contact</a></li>
          <li><a href="#">Login</a></li>
        </ul>
      </nav>
    </header>
    

    </Fragment>
  )
}
